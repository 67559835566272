<template>
	<div class="container-fluid">
		<aq-list-navigation-control
			:list="items"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				:data="items"
				fit
				cell-class-name="text-left"
				empty-text="There is no data to display "
				data-qa="condtitions_table_report"
				:key="currentColumnPage"
			>
				<paged-columns
					:current-column-page="currentColumnPage"
					:columns-per-page="columnsPerPage"
				>
					<el-table-column
						type="index"
						:index="getIndex"
						width="65"
					/>
					<el-table-column
						prop="brandName"
						label="Brand"
						width="140"
					/>
					<el-table-column label="User Name">
						<template #default="scope">
							{{ `${scope.row.createdByUser.firstName} ${scope.row.createdByUser.lastName}` }}
						</template>
					</el-table-column>
					<el-table-column label="Policy Ref">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.policyReference" />
						</template>
					</el-table-column>
					<el-table-column label="Pet Name">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.petName" />
						</template>
					</el-table-column>
					<el-table-column label="Condition Name">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.ailment" />
						</template>
					</el-table-column>
					<el-table-column label="Body Part">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.bodyPartDescription" />
						</template>
					</el-table-column>
					<el-table-column
						label="Exclusion/Eligible Type"
						width="150"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.diagnosisType" />
						</template>
					</el-table-column>
					<el-table-column
						label="Eligible"
						width="80"
					>
						<template #default="scope">
							<boolean-cell :value="scope.row.diagnosisConditionType === 'Eligible'" />
						</template>
					</el-table-column>
					<el-table-column
						label="Archived"
						width="80"
					>
						<template #default="scope">
							<boolean-cell :value="scope.row.archived" />
						</template>
					</el-table-column>
					<el-table-column
						label="Date Created"
						width="120"
					>
						<template #default="scope">
							{{ scope.row.createdDateTime | shortDate }}
						</template>
					</el-table-column>
				</paged-columns>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';
import taskRowActionsHandlerMixin from '@mixins/taskRowActionsHandlerMixin';

export default {
	mixins: [rowHighlightMixin, taskRowActionsHandlerMixin],
	props: {
		items: {
			type: Array,
			required: true,
		},
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
	},
	data () {
		return {
			selectedData: null,
		};
	},
	methods: {
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
	},
};
</script>
