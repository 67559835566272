<template>
	<div class="section-content mr-25 px-20">
		<div
			v-for="(table, key) in groupedRows"
			:key="key"
			class="pb-15"
		>
			<div class="pl-14 py-15 group-header_container mb-1">
				{{ getGroupName(table) }}
			</div>
			<el-table
				ref="claimItemTable"
				:data="table"
				style="width: 100%;"
				class="no-spacing"
				:key="key"
				cell-class-name="first-chilp-p-14"
				header-row-class-name="table-header-row"
				:row-class-name="rowClassName"
				@row-click="onSelect"
				data-qa="claimItemView_table_items"
			>
				<el-table-column
					label="Treatment Period"
					data-qa="claimItemView_column_treatmentPeriod"
					width="200"
				>
					<template #default="{ row }">
						<div class="d-flex align-items-center">
							<div :class="['circle-16 mr-6', periodColor(row.treatmentPeriod.treatmentPeriodType)]" />
							<div class="text-truncate">
								{{ row.treatmentPeriod.start | shortDate }} - {{ row.treatmentPeriod.end | shortDate }}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					v-if="multicondition"
					label="Policy Section"
					data-qa="claimItemView_column_policy_section"
				>
					<template #default="{ row }">
						<truncated-text-cell :value="row.policySectionName" />
					</template>
				</el-table-column>
				<el-table-column
					label="Amount"
					width="130"
					data-qa="claimItemView_column_amount"
				>
					<template #default="{ row }">
						<div>{{ row.amount | currency(locale) }}</div>
					</template>
				</el-table-column>
				<el-table-column
					label="User Deductions"
					data-qa="claimItemView_column_userDeductions"
				>
					<template #default="{ row }">
						<div class="d-flex align-items-center">
							<div class="text-danger">
								-{{ row.userDeductions.reduce((acc, current) => acc + +current.amount, 0) | currency(locale) }}
							</div>
							<VDropdown
								theme="info-dropdown"
								:distance="10"
								class="ml-10"
								v-if="row.userDeductions.length"
							>
								<div class="circle-13 bg-primary text-center text-white">
									{{ row.userDeductions.length }}
								</div>
								<template #popper>
									<div>
										<div
											class="item d-flex justify-content-between"
											v-for="(item, index) in row.userDeductions"
											:key="index"
										>
											<div><strong>{{ item.description }}</strong></div>
											<div class="ml-20">
												{{ item.amount | currency(locale) }}
											</div>
										</div>
									</div>
								</template>
							</VDropdown>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="System Deductions"
					data-qa="claimItemView_column_systemDeductions"
				>
					<template #default="{ row }">
						<div class="d-flex align-items-center">
							<div class="text-danger">
								{{ getSystemDeductionsTotal(row) }}
							</div>
							<VDropdown
								theme="info-dropdown"
								:distance="10"
								class="ml-10"
								v-if="row.systemDeductions.filter((deduction) => deduction.value !== 0).length"
							>
								<div class="circle-13 bg-primary text-center text-white">
									{{ row.systemDeductions.filter((deduction) => deduction.value !== 0).length }}
								</div>
								<template #popper>
									<div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('PartialCoverageDeduction') }} {{ getDeductionOriginalLimitDescription(row.deductions, 'PartialCoverageDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'PartialCoverageDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('FixedExcessDeduction', locale) }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'FixedExcessDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('CoInsuranceDeduction') }} {{ getDeductionOriginalLimitDescription(row.deductions, 'CoInsuranceDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'CoInsuranceDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('ConditionLimitDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'ConditionLimitDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('SectionLimitDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'SectionLimitDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('NoCoverDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'NoCoverDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('NonFinancialLimitDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'NonFinancialLimitDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('RejectedDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'RejectedDeduction') }}
											</div>
										</div>
										<div class="item d-flex justify-content-between">
											<div><strong>{{ getDeductionDescription('TaxDeduction') }}</strong></div>
											<div class="ml-20">
												{{ getSystemDeduction(row.systemDeductions, 'TaxDeduction') }}
											</div>
										</div>
									</div>
								</template>
							</VDropdown>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Number of Days/Sessions"
					data-qa="claimItemView_column_number"
					width="160"
				>
					<template #default="{ row }">
						<div>{{ row.nonFinancialNumber }}</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Total"
					data-qa="claimItemView_column_total"
				>
					<template #default="{ row }">
						<div>{{ row.totalAmount | currency(locale) }}</div>
					</template>
				</el-table-column>
				<el-table-column
					label=""
					width="120"
					data-qa="claimItemView_column_buttons"
				>
					<template #default="{ row }">
						<div>
							<button
								v-if="isEditAvailable(row)"
								@click.stop="onEdit(row)"
								:disabled="editingIndex > -1"
								type="button"
								:class="['btn small mr-10', row.isEditing ? 'btn-warning' : 'btn-row' ]"
								data-qa="claimItemView_button_edit"
							>
								<i class="fas fa-pencil-alt" />
							</button>
							<button
								v-else-if="!isReadOnlyMode && row.fileId"
								@click.stop="onScanEdit(row.fileId)"
								:disabled="editingIndex > -1"
								type="button"
								:class="['btn small mr-10', row.isEditing ? 'btn-warning' : 'btn-row' ]"
								data-qa="claimItemView_button_scanEdit"
							>
								<i class="fas fa-file-alt" />
							</button>
							<button
								v-if="isDeleteAvailable(row)"
								@click.stop="onDelete(row)"
								:disabled="editingIndex > -1"
								type="button"
								class="btn small btn-row"
								data-qa="claimItemView_button_delete"
							>
								<i class="fas fa-times" />
							</button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<aq-confirmation-modal
			ref="deleteModal"
			name="confirm-delete"
			title="Confirm Delete"
			description="Are you sure you want to delete this claim Item?"
			data-qa="claimItemView_modal_confirmDelete"
		/>
	</div>
</template>

<script>

import eventBus from '@commonServices/eventBus';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { currency } from '@commonServices/utils/filters';
import { getDeductionDescription, getDeductionOriginalLimitDescription } from '@commonServices/models/DeductionTypeEnum';
import { getSystemDeductionsTotal } from '@commonServices/utils/converter';

export default {
	data () {
		return {
			selectedIndex: -1,
			editingIndex: -1,
		};
	},
	props: {
		allRows: {
			type: Array,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		conditions: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	computed: {
		groupByField: function () {
			return this.multicondition ? 'claimConditionId' : 'policySectionId'; ;
		},
		groupedRows: function () {
			return this.allRows.reduce((acc, x, index) => {
				(acc[x[this.groupByField]] = acc[x[this.groupByField]] || []).push(x);
				x.isEditing = index === this.editingIndex;
				x.isSelected = index === this.selectedIndex;
				return acc;
			}, {});
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	methods: {
		getGroupName (group) {
			if (this.multicondition) {
				return (this.conditions.find(c => c.id === group[0].claimConditionId) || {}).description || 'No Ailment';
			}
			return group[0].policySectionName;
		},
		periodColor (typeId) {
			if (typeId === 1) return 'bg-primary';
			if (typeId === 2) return 'bg-danger';
			return 'bg-warning';
		},
		getTreatmentPeriodClass (periodType) {
			switch (periodType) {
			case 1: return 'period-sc';
			case 2: return 'period-nc';
			case 3: return 'period-ci';
			default: return '';
			}
		},
		rowClassName ({ row }) {
			let bg = row.isValid ? 'list-item--bg' : 'bg-danger-darken';
			if (row.rebalanceExcess) {
				bg = `${bg} bg-excluded-item`;
			}
			const treatmentType = this.getTreatmentPeriodClass(row.treatmentPeriod.treatmentPeriodType);

			if (this.editingIndex !== -1
				&& !row.isEditing) return `${bg} opacity-50`;
			if (this.selectedIndex !== -1
				&& row.isSelected) return `${bg} aq-selected-row ${treatmentType}`;
			return bg;
		},
		findIndexbyRow (row) {
			return this.allRows.findIndex((x) => x === row);
		},
		onEdit (row) {
			if (this.editingIndex === -1) {
				this.selectedIndex = -1;
				this.editingIndex = this.findIndexbyRow(row);
				this.$emit('edit', this.editingIndex);
			}
		},
		isEditAvailable (row) {
			return this.isDeleteAvailable(row) && !row.fileId;
		},
		isDeleteAvailable (row) {
			return !(this.isReadOnlyMode || (row.rebalanceExcess && !this.$can.RebalanceExcess));
		},
		onSelect (row) {
			if (this.editingIndex === -1) {
				this.selectedIndex = this.findIndexbyRow(row);
				this.$emit('select', this.selectedIndex);
			}
		},
		onCancelDelete () {
			this.deleteItem = undefined;
		},
		onDelete (row) {
			this.deleteItem = row;
			this.clearRowClasses();

			this.$refs.deleteModal.show()
				.then(data => {
					if (data === 'yes') this.onConfirmDelete();
					else this.onCancelDelete();
				});
		},
		onConfirmDelete () {
			const deleteIndex = this.findIndexbyRow(this.deleteItem);
			if (this.editingIndex === -1 || deleteIndex === this.editingIndex) {
				this.$emit('delete', deleteIndex);
			}
			this.deleteItem = undefined;
		},
		clearRowClasses () {
			this.selectedIndex = -1;
			this.editingIndex = -1;
		},
		getSystemDeduction (systemDeductions, name) {
			const deduction = systemDeductions.find(deduction => deduction.key === name);
			return currency(deduction ? deduction.value : 0, this.locale);
		},
		getSystemDeductionsTotal (row) {
			const systemDeductions = row.systemDeductions.map(item => ({
				description: item.key,
				amount: item.value,
			}));

			return getSystemDeductionsTotal(systemDeductions, row.rebalanceExcess, this.locale);
		},
		getDeductionOriginalLimitDescription (claimItemDeductions, name) {
			return getDeductionOriginalLimitDescription(claimItemDeductions, name);
		},
		getDeductionDescription (deductionType, locale = null) {
			return getDeductionDescription(deductionType, locale);
		},
		onScanEdit (fileId) {
			eventBus.$emit('document-panel_open-scan-file', { fileId });
		},
	},
};
</script>

<style lang="scss" scoped>
.group-header_container {
  background-color: var(--tableGroupBg);
}

.circle-16 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

::v-deep .el-table .el-table__body {
  border-spacing: 0 2px;
}

::v-deep .el-table__header {
  background-color: var(--tableGroupBg);
}

::v-deep td:first-child .cell,
::v-deep th:first-child .cell {
  padding-left: 14px !important;
}

::v-deep .opacity-50 {
  opacity: 0.5;
}

::v-deep .el-table__row.aq-selected-row td {
  &:first-child {
    border-left: 2px solid;
  }

  &:last-child {
    border-right: 2px solid;
  }

  border-top: 2px solid;
  border-bottom: 2px solid;
}

::v-deep .el-table__row.aq-selected-row.period-sc td {
  border-color: rgba($primary, 0.7);
}

::v-deep .el-table__row.aq-selected-row.period-nc td {
  border-color: rgba($danger, 0.7);
}

::v-deep .el-table__row.aq-selected-row.period-ci td {
  border-color: rgba($warning, 0.7);
}

::v-deep .el-table__row.bg-excluded-item {
  background-color: var(--listItemsExcludedBg);
}

.circle-13 {
  height: 13px;
  width: 13px;
  line-height: 13px;
  border-radius: 50%;
  font-size: 11px;
  cursor: pointer;
}
</style>
