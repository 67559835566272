<template>
	<div>
		<aq-sandwich
			ref="sandwich"
			header="Ex-Gratia Payments"
			:active-class="''"
			:header-class="'pl-25'"
			class="mb-50 mr-25"
			is-active-on-start
		>
			<template #content>
				<div>
					<div class="section-content ml-25">
						<div
							class="container-fluid"
							v-if="exGratiaPayments.length > 0"
						>
							<el-table
								ref="paymentHistory"
								empty-text="The table is empty"
								:data="exGratiaPayments"
								data-qa="paymentExGratia_table_paymentExGratia"
							>
								<el-table-column
									label="Recipient"
									data-qa="paymentHistory_column_recipient"
								>
									<template #default="scope">
										<div class="font-weight-bold">
											<div>{{ toRecipientLabel(scope.row.recipient.recipientType) }}</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payee"
									data-qa="paymentExGratia_column_payee"
								>
									<template #default="scope">
										<div>{{ scope.row.recipient.recipientName }}</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payment Type"
									data-qa="paymentExGratia_column_paymentType"
								>
									<template>
										<div>Ex Gratia</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payment Details"
									data-qa="paymentExGratia_column_paymentDetails"
								>
									<template #default="scope">
										<span class="font-weight-bold">
											{{ getPaymentMethod(scope.row.selectedPayment.paymentMethod) }}:
										</span>
										<template v-if="scope.row.selectedPayment.paymentMethod === 1">
											<span>{{ scope.row.selectedPayment.payee }}</span>
										</template>
										<template v-else-if="scope.row.selectedPayment.paymentMethod === 2">
											<span>{{ scope.row.selectedPayment.payee }},&nbsp;</span>
											<span v-if="scope.row.selectedPayment.bankName">
												{{ scope.row.selectedPayment.bankName }},&nbsp;
											</span>
											<span>{{ scope.row.selectedPayment.sortCode }},&nbsp;</span>
											<span>{{ scope.row.selectedPayment.accountNumber }}</span>
										</template>
									</template>
								</el-table-column>
								<el-table-column
									label="Paid"
									data-qa="paymentExGratia_column_paid"
								>
									<template #default="scope">
										<div class="text-left">
											{{ scope.row.paymentAmount | currency(locale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									data-qa="paymentExGratia_column_edit"
									width="150"
								>
									<template #default="scope">
										<div class="d-flex justify-content-around">
											<aq-icon-avatar
												v-if="scope.row.createdBy"
												class="small"
												:value="[scope.row.createdBy.firstName, scope.row.createdBy.lastName]"
											/>
											<div
												v-if="!isReadonly"
												class="card-icon small cursor-pointer"
												@click="$emit('edit-ex-gratia', scope.row)"
											>
												<i class="fas fa-pencil-alt fs-13 text-warning" />
											</div>
											<div
												v-if="!isReadonly"
												class="card-icon small cursor-pointer"
												@click="$emit('delete-ex-gratia', scope.row)"
											>
												<i class="aqv-delete fs-13 text-danger" />
											</div>
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<button
							class="btn btn-primary ml-14"
							:disabled="isReadonly"
							@click="$emit('show-ex-gratia-form')"
						>
							Add Ex Gratia
						</button>
					</div>
				</div>
			</template>
		</aq-sandwich>
	</div>
</template>

<script>
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

export default {
	name: 'PaymentExGratia',
	props: {
		exGratiaPayments: {
			type: Array,
			required: true,
		},
		paymentMethods: {
			type: Array,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	methods: {
		getPaymentMethod (paymentMethod) {
			return this.paymentMethods.find((type) => type.value === paymentMethod).description;
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
	},
	computed: {
		...mapState(['appSettings']),
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
};
</script>
