<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div
								v-if="isParent"
								class="row"
							>
								<div class="logo">
									<ReassessClaimParentLogo class="file-icon" />
								</div>
								<div class="col-12 mt-2 text-center font-weight-bold">
									Parent Claim
								</div>
								<div class="col-12 py-12">
									You are about to reassess a <span class="font-weight-bold">Parent</span> claim, any changes made will effect existing claim data and claim calculation
								</div>
							</div>
							<div
								v-else
								class="row"
							>
								<div class="logo">
									<ReassessClaimsLogo />
								</div>
								<div class="col-12 py-12">
									You are about to reassess a claim, any changes made will effect existing claim data and claim calculation
								</div>
							</div>
							<div class="col-12 pb-12">
								<aq-select
									label="Select Reasons"
									:options="reassessmentReasons"
									option-label="description"
									v-model="selectedReason"
									no-result-message="No Reason found"
								>
									<template #default="props">
										<div class="px-10">
											<div class="select-option-header text-truncate">
												{{ props.option.description }}
											</div>
										</div>
									</template>
								</aq-select>
							</div>
							<div class="col-12 pb-12">
								Are you sure you wish to continue?
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onContinue"
						:disabled="v$.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Continue
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import ExtraService from '@commonServices/extraService';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ReassessClaimsLogo, ReassessClaimParentLogo } from '@assets/icons';

export default {
	name: 'ManualReassessmentModal',
	components: {
		ReassessClaimsLogo,
		ReassessClaimParentLogo,
	},
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		isParent: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			title: 'Reassess Claim',
			reassessmentReasons: [],
			selectedReason: null,
		};
	},
	async mounted () {
		this.reassessmentReasons = await ExtraService.getReassessmentReasons();
	},
	validations () {
		return {
			selectedReason: {
				required,
			},
		};
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onContinue () {
			this.$emit('reassess-claim', { reason: this.selectedReason });
		},
	},
};
</script>

<style scoped>
.logo {
  position: relative;
  left: 45%;
}
</style>
