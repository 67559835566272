<template>
	<div>
		<aq-select
			data-qa="aqLossTypes_input_losstype"
			:options="lossTypes"
			label="Loss Type"
			option-label="firstCause"
			:is-valid="isValid"
			class="w-100"
			:value="value"
			@input="$emit('input', $event)"
			:disabled="disabled"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
	name: 'AqLossTypes',
	props: {
		value: {
			type: Object,
			required: false,
			default: null,
		},
		isValid: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	mounted () {
		this.loadLossTypes();
	},
	computed: {
		...mapState(['lossTypes']),
	},
	methods: {
		...mapActions(['loadLossTypes']),
	},
};
</script>
