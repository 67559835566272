<template>
	<VTooltip
		:triggers="[]"
		placement="bottom"
		:shown="popoverState"
		@apply-hide="resetHideDelay"
		:delay="{ show: showDelay, hide: hideDelay }"
		:popper-class="['list-items-popover', {'tooltip-arrow-hidden':disabled }]"
		:disabled="disabled"
		@auto-hide="onAutoHide"
	>
		<div
			class="d-flex"
			@mouseover="hover(true)"
			@mouseleave="hover(false)"
		>
			<slot />
		</div>
		<template #popper>
			<div
				v-if="!disabled"
				class="popover-content"
				@mouseover="hover(true)"
				@mouseleave="hover(false)"
			>
				<label class="list-items-label">{{ label }}</label>
				<div
					v-if="items"
					class="popover-body p-2 pr-4"
				>
					<div
						class="row no-gutters list-item--bg mt-2 mb-1 py-3 popover-item"
						:class="{ 'popover-item-selected': item[trackBy] === selectedItemKey }"
						v-for="(item, index) in items"
						:key="index"
					>
						<div
							class="col-12 ml-8 pr-5"
							@click="onSelectItem(item)"
						>
							<div
								class="cursor-pointer d-flex align-items-center"
								:class="{ 'cursor-default': item[trackBy] === selectedItemKey }"
							>
								<i
									class="fas fa-angle-right mr-4 arrow-icon"
									aria-hidden="true"
								/>
								<span class="fs-12 text-truncate">
									{{ typeof itemLabel === 'string' ? item[itemLabel] : itemLabel(item) }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</VTooltip>
</template>

<script>

const hoverHideDelay = 700;
const hoverShowDelay = 200;

export default {
	name: 'AqListItemsPopover',
	props: {
		label: {
			type: String,
			required: false,
			default: '',
		},
		items: {
			type: Array,
			required: false,
			default: null,
		},
		itemLabel: {
			type: [String, Function],
			required: true,
		},
		trackBy: {
			type: String,
			required: false,
			default: 'id',
		},
		selectedItem: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		isOpen: {
			type: Boolean,
			required: false,
			default: null, // when prop is not provided popover will use hover approach
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isTooltipOpen: false,
			selectedItemKey: null,
			hideDelay: hoverHideDelay,
			showDelay: hoverShowDelay,
		};
	},
	mounted () {
		this.selectedItemKey = this.selectedItem[this.trackBy];
	},
	watch: {
		selectedItem (newVal) {
			this.selectedItemKey = newVal[this.trackBy];
		},
	},
	computed: {
		popoverState () {
			return this.isOpen ?? this.isTooltipOpen;
		},
	},
	methods: {
		onSelectItem (value) {
			if (value === this.selectedItem) {
				return;
			}

			this.hideDelay = 0;
			this.isTooltipOpen = false;

			this.$emit('input', value);
		},
		hover (value) {
			if (this.isOpen !== null) { return; }

			this.setTooltipOpen(value);
		},
		setTooltipOpen (value) {
			this.isTooltipOpen = value;
		},
		resetHideDelay () {
			this.hideDelay = hoverHideDelay;
		},
		onAutoHide () {
			this.hideDelay = 0;
			this.$emit('auto-hide');
		},
	},
};
</script>

<style lang="scss">
.list-items-popover {
  box-shadow: 0 0 10px var(--listItemsPopupShadow);

  .v-popper__inner {
    background-color: var(--listItemsPopupContentColor);
    border-radius: 0.25rem;
    border: 3px solid var(--popoverColorBorder);
    padding: 5px;
  }

  .v-popper__arrow-outer,
  .v-popper__arrow-inner {
    border-color: var(--popoverColorBorder);
  }

  .popover-content {
    max-height: 162px;
    overflow: hidden;
    min-width: 120px;
    max-width: 230px;
    position: relative;
  }

  .list-items-label {
    color: var(--sectionTextColour);
    text-align: center;
    width: 100%;
    display: block;
    padding: 3px;
  }

  .popover-body {
    overflow: hidden auto;
    max-height: 120px;
  }

  .popover-item {
    background-color: var(--listItemsPopupItemColor);
    box-sizing: border-box;

    &-selected {
      opacity: 0.5;
    }
  }

  .arrow-icon {
    color: var(--arrowIconColor);
    font-size: 10px;
  }
}
</style>
