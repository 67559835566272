<template>
	<div>
		<aq-sandwich
			ref="sandwich"
			header="Payment Recipients"
			:active-class="''"
			is-active-on-start
			header-class="pl-25"
			class="mb-50 mr-25"
		>
			<template #content>
				<div>
					<div class="section-content ml-25">
						<div class="container-fluid">
							<el-table
								ref="paymentRecipients"
								empty-text="The table is empty"
								:data="regularPayments"
								data-qa="paymentRecipients_table_recipients"
							>
								<el-table-column
									label="Recipient"
									width="200"
									min-width="10"
									data-qa="paymentRecipients_column_recipient"
								>
									<template #default="scope">
										<div class="font-weight-bold">
											{{ toRecipientLabel(scope.row.recipient.recipientType) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payee"
									prop="recipient.recipientName"
									min-width="20"
									data-qa="paymentRecipients_column_payee"
								/>
								<el-table-column
									label="Payment Type"
									min-width="25"
									data-qa="paymentRecipients_column_paymentType"
								>
									<template>
										Claim
									</template>
								</el-table-column>
								<el-table-column
									label="Payment Details"
									min-width="30"
									data-qa="paymentRecipients_column_paymentDetails"
								>
									<template #default="{ row }">
										<div
											:key="row.selectedPayment ? row.selectedPayment.id : 0"
											class="mr-10"
										>
											<aq-select
												:options="getActivePaymentAccounts(row.recipient.accounts)"
												option-label="payee"
												placeholder="Payment Details"
												:disabled="!$can.AddPayment || isReadOnlyMode || (totalRemainingInitial === 0 && row.selectedPayment == null)"
												v-model="row.selectedPayment"
												@remove="onPaymentDetailsRemoved(row.uniqId)"
												@input="autoSetSplitAmount(row); touch(); "
												track-by="id"
												no-result-message="No Payment Details found"
												data-qa="paymentRecipients_select_paymentDetails"
											>
												<template #default="{ option }">
													<div class="select-option-header text-truncate d-flex align-items-center">
														<div class="payment-icon">
															<component :is="iconSettings[option.paymentMethod]" />
														</div>
														{{ option.payee }}
													</div>
													<div
														class="select-option-info text-truncate"
														v-if="isBacsPaymentMethod(option.paymentMethod)"
													>
														<span v-if="option.bankName">
															{{ option.bankName }},&nbsp;
														</span>
														<span>{{ option.sortCode }},&nbsp;</span>
														<span>{{ option.accountNumber }}</span>
													</div>
													<div />
												</template>
												<template #singleLabel="{ option }">
													<div>
														<span>{{ option.payee }}</span>
														<span v-if="isBacsPaymentMethod(option.paymentMethod)">
															<span v-if="option.bankName">
																,&nbsp;{{ option.bankName }},
															</span>
															{{ option.sortCode }},
															{{ option.accountNumber }}
														</span>
													</div>
													<div
														v-if="isReadOnlyMode && !option.isActive"
														class="text-warning fs-12"
													>
														Account marked as inactive
													</div>
												</template>
												<template #before-list>
													<div
														class="p-20 create-new"
														@click="$emit('open-payee', row.recipient)"
														v-if="$can.ManagePaymentAccount"
													>
														<button
															type="button"
															class="btn small btn-primary mr-10"
															data-qa="paymentRecipients_button_openPayee"
														>
															<i class="fas fa-plus text-white" />
														</button>
														<span>Create New Account</span>
													</div>
												</template>
											</aq-select>
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Split"
									min-width="15"
									data-qa="paymentRecipients_column_splitAmount"
								>
									<template #default="{ row }">
										<PaymentAmount
											v-model="row.paymentAmount"
											:historical-payments-total="getHistoricalPaymentsTotal(row)"
											:is-positive-net="isPositiveNet"
											:locale="locale"
											:disabled="!$can.AddPayment || isReadOnlyMode || !row.selectedPayment"
											@input="touch()"
										/>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<div class="d-flex ml-25 section-footer p-0">
						<div class="total-remaining d-flex align-items-center justify-content-end px-20 ml-auto">
							Total {{ totalRemainingInitial | currency(locale) }}
						</div>
						<div :class="[{ 'valid' : tableValid }, 'total-remaining d-flex align-items-center justify-content-end px-20']">
							Remaining {{ totalRemaining | currency(locale) }}
						</div>
						<div
							:class="[{ 'valid bg-success' : tableValid, 'bg-danger': !tableValid }, 'total mr-20 px-20 d-flex align-items-center']"
						>
							<div class="text-truncate">
								{{ totalAmount | currency(locale) }}
							</div>
						</div>
					</div>
				</div>
			</template>
		</aq-sandwich>
	</div>
</template>

<script>
import PaymentAmount from '@commonView/ClaimPage/ClaimPayment/PaymentAmount';
import { useVuelidate } from '@vuelidate/core';
import { areNumbersEqual } from '@commonServices/utils/general';
import { PaymentMethodIconSettings } from '@commonServices/settings/themeSettings';
import { PaymentMethod } from '@commonServices/models/PaymentMethod';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

export default {
	components: {
		PaymentAmount,
	},
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		regularPayments: {
			type: Array,
			required: true,
		},
		totalRemainingInitial: {
			type: Number,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		isPositiveNet: {
			type: Boolean,
			required: false,
		},
		paymentHistoryItems: {
			type: Array,
			required: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			tableValid: false,
		};
	},
	validations () {
		return {};
	},
	computed: {
		...mapState(['appSettings']),
		totalAmount () {
			return this.regularPayments
				.reduce((accum, currentValue) => accum + Number(currentValue.paymentAmount), 0);
		},
		totalRemaining () {
			return this.totalRemainingInitial - this.totalAmount;
		},
		isRecipientsValidForDraft () {
			return this.v$.$errors.filter(er => er.$property === 'amount'
			&& this.isPositiveNet
				? (er.$validator === 'minValue' || er.$validator === 'maxLength')
				: (er.$validator === 'maxValue' || er.$validator === 'minValue')).length === 0;
		},
		compound () {
			return this.totalAmount + !this.v$.$invalid + Date.now();
		},
		iconSettings () {
			return PaymentMethodIconSettings;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	methods: {
		validate () {
			this.v$.$touch();
			return !this.v$.$invalid;
		},
		touch () {
			this.v$.$touch();
			this.$emit('dirty');
		},
		onPaymentDetailsRemoved (id) {
			this.regularPayments.find((item) => item.uniqId === id).paymentAmount = 0;
		},
		autoSetSplitAmount (row) {
			if (this.regularPayments.length === 1 && row.selectedPayment) {
				row.paymentAmount = this.totalRemainingInitial;
			}
			if (!row.selectedPayment) {
				row.paymentAmount = 0;
			}
		},
		autoSetPaymentDetailsAndSplitAmount () {
			const activePaymentAccounts = this.getActivePaymentAccounts(this.regularPayments[0].recipient.accounts);
			if (!this.isReadOnlyMode && this.regularPayments.length === 1 && activePaymentAccounts.length === 1 && this.totalRemainingInitial !== 0) {
				this.regularPayments[0].selectedPayment = {
					...activePaymentAccounts[0],
				};
				this.regularPayments[0].paymentAmount = this.totalRemainingInitial;
			}
		},
		isBacsPaymentMethod (paymentMethod) {
			return paymentMethod === PaymentMethod.Bacs;
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
		getActivePaymentAccounts (paymentAccounts) {
			return paymentAccounts.filter(a => a.isActive);
		},
		getHistoricalPaymentsTotal (recipient) {
			const previousPayments = this.paymentHistoryItems.filter((item) => item.uniqId === recipient.uniqId);
			return previousPayments.map(item => isNaN(+item.paymentAmount) ? 0 : +item.paymentAmount).reduce((a, b) => a + b, 0);
		},
	},
	watch: {
		compound () {
			this.tableValid = areNumbersEqual(this.totalRemaining, 0) && !this.v$.$invalid;
			this.$emit('amount-changed', this.tableValid);
		},
		regularPayments () {
			this.autoSetPaymentDetailsAndSplitAmount();
		},
	},

};
</script>

<style lang="scss" scoped>
.total-remaining {
  height: 57px;
  color: $body-color;
}

.total {
  height: 57px;
  width: 128px;
  color: $body-color;
  overflow-x: auto;

  &.valid {
    background: rgba($success, 0.23);
  }
}

::v-deep .el-table tr {
  td {
    &:nth-child(3),
    &:last-child {
      padding: 0;

      > .cell {
        padding: 0;
      }
    }
  }
}

::v-deep .el-table .el-table__body {
  margin-top: -25px;
  border-spacing: 0 30px;
}

.error {
  white-space: nowrap;
  position: absolute;
  left: 0;
}

.payment-icon {
  fill: $body-color;
  width: 32px;
  margin: 0 15px;
}
</style>
