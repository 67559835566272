<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Edit Multiple Treatments"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="fluid-container">
						Please select from below:
						<div class="mt-20">
							<aq-form-input
								label="Date"
								type="date"
								v-model="v$.scannedDataItem.date.$model"
								:is-valid="!v$.scannedDataItem.date.$error"
							/>
							<aq-form-input-error
								class="error mt-4"
								:validator="v$.scannedDataItem.date"
							/>
						</div>
						<div
							class="mt-20"
							v-if="multicondition"
						>
							<aq-select
								:options="conditions"
								label="Ailment"
								option-label="description"
								track-by="id"
								searchable
								:is-sorted="false"
								v-model="v$.scannedDataItem.selectedClaimCondition.$model"
								no-result-message="No Ailment found"
								:is-valid="!v$.scannedDataItem.selectedClaimCondition.$error"
								@input="onConditionChanged"
							/>
							<aq-form-input-error
								class="error mt-4"
								:validator="v$.scannedDataItem.selectedClaimCondition"
							/>
						</div>

						<div class="mt-20">
							<aq-select
								:options="conditionPolicySections"
								label="Policy Section"
								option-label="description"
								track-by="id"
								searchable
								v-model="v$.scannedDataItem.selectedPolicySection.$model"
								no-result-message="No Policy Section found"
								:is-valid="!v$.scannedDataItem.selectedPolicySection.$error"
							/>
							<aq-form-input-error
								class="error mt-4"
								:validator="v$.scannedDataItem.selectedPolicySection"
							/>
						</div>

						<div class="mt-20">
							<aq-select
								:options="conditionDeductionTypes"
								label="User Deduction"
								option-label="description"
								track-by="id"
								multiple
								v-model="scannedDataItem.selectedUserDeductions"
								no-result-message="No User Deduction found"
							/>
						</div>
						<div class="mt-20">
							<aq-form-input
								label="Pet Name"
								v-model="scannedDataItem.petName"
								type="text"
							/>
						</div>
						<div class="mt-20">
							<aq-select
								label="Line Type"
								:options="LineTypeOptions"
								:value="selectedLineType"
								option-label="value"
								:is-sorted="false"
								:allow-empty="false"
								:searchable="false"
								data-qa="multiEditLineItemModal_select_lineType"
								@input="onLineTypeSelected"
							/>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
					<button
						:disabled="v$.$invalid"
						@click="onFinish"
						type="button"
						class="btn btn-success save"
					>
						Save
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { fromStringToDate } from '@commonServices/utils/dateUtils';
import { LineTypeOptions } from '@commonServices/models/InvoiceLineType';
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { required, withMessage } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	props: {
		selectedLineItem: {
			type: Object,
			required: true,
		},
		deductions: {
			type: Array,
			required: true,
		},
		policySections: {
			type: Array,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			scannedDataItem: {
				date: undefined,
				selectedUserDeductions: [],
				selectedPolicySection: undefined,
				selectedClaimCondition: undefined,
				itemType: undefined,
			},
			LineTypeOptions,
		};
	},
	mounted () {
		this.scannedDataItem.date = fromStringToDate(this.selectedLineItem.date.value);
		this.scannedDataItem.selectedPolicySection = this.selectedLineItem.policySection.value;
		this.scannedDataItem.selectedClaimCondition = this.selectedLineItem.claimCondition.value;
		this.scannedDataItem.petName = this.selectedLineItem.petName.value;
		this.scannedDataItem.itemType = this.selectedLineItem.itemType?.value ?? 0;
		this.scannedDataItem.selectedUserDeductions = this.selectedLineItem.deductions?.map((deduction) => {
			const deductionData = this.deductions.find(d => d.id === deduction.value?.id);
			return {
				id: deductionData.id,
				description: deductionData.description,
			};
		}) ?? [];
	},
	validations: {
		scannedDataItem: {
			date: {
				required: withMessage(ValidationMessages.required, required),
			},
			selectedPolicySection: {
				required: withMessage(ValidationMessages.required, required),
			},
			selectedClaimCondition: {
				required: withMessage(ValidationMessages.required, required),
			},
		},
	},
	computed: {
		isItemInvalid () {
			return this.v$.scannedDataItem.selectedPolicySection.$error
				|| this.v$.scannedDataItem.selectedClaimCondition.$error
				|| this.v$.scannedDataItem.date.$error;
		},
		conditionPolicySections () {
			return this.policySections.filter(ps => ps.claimConditionId === this.scannedDataItem.selectedClaimCondition?.id);
		},
		conditionDeductionTypes () {
			return this.deductions.filter(d => d.claimConditionId === this.scannedDataItem.selectedClaimCondition?.id && !d.isSystem);
		},
		selectedLineType () {
			return this.LineTypeOptions.find(option => option.key === this.scannedDataItem.itemType);
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
		onFinish () {
			this.v$.scannedDataItem.$touch();
			if (!this.isItemInvalid) {
				this.$emit('finish', this.scannedDataItem);
				this.$modal.hide(this.$parent.name);
			}
		},
		onConditionChanged () {
			this.scannedDataItem.selectedPolicySection = null;
		},
		onLineTypeSelected (itemType) {
			this.scannedDataItem.itemType = itemType.key;
		},
	},
};
</script>

<style lang="scss" scoped>
.row-icon {
  margin-right: 5px;
  font-size: 17px;
}

.save {
  margin-left: auto;
}
</style>
