<template>
	<div>
		<aq-confirmation-modal
			ref="saveChangesDialog"
			name="save-changes-dialog"
			title="Save changes"
			description="You have unsaved changes, Click Save to Save as Draft or Cancel to Close without saving."
			yes-label="Save"
			no-label="Cancel"
			data-qa="missingItems_modal_saveChanges"
		>
			<template
				v-if="!isAllowedToSaveClaimAsDraft"
				#yesButton
			>
				<button
					type="button"
					class="btn btn-success ml-auto"
					disabled
				>
					<div v-tooltip="'Current claim status prevents saving as draft.'">
						Save
					</div>
				</button>
			</template>
		</aq-confirmation-modal>
		<aq-modal name="reject-claim">
			<manual-rejection-modal
				:rejection-context="{ claimId }"
				@reject="onReject"
				data-qa="missingItems_modal_rejectClaim"
			/>
		</aq-modal>
		<aq-modal name="fraud-check">
			<fraud-check-modal
				@fraud-check="onFraudCheck"
				:claim-id="claimId"
				data-qa="missingItems_modal_fraudCheck"
			/>
		</aq-modal>
		<reassessment-outcome-modal
			v-if="claimId"
			data-qa="claimMissingItems_modal_reassessmentOutcomeModal"
			name="reassessment-outcome-modal"
			ref="saveReassessmentOutcomeModal"
			:selected-reassessment-outcome="selectedReassessmentOutcome"
			:claim-id="claimId"
			@input="selectedReassessmentOutcome = $event"
		/>
		<div class="d-flex flex-column">
			<div class="container-fluid p-0 mb-50">
				<div class="d-flex flex-row">
					<div
						class="d-flex flex-column w-100"
						:class="[ !isFraudCheck ? 'mt-30 mb-180' : 'mb-150' ]"
					>
						<aq-fraud-check-actions
							v-if="isFraudCheck"
							:claim-id="sharedData.claimId"
							:is-reassessment="sharedData.isReassessment"
							:has-claim-unsaved-changes="isDirty"
							:fraud-check-reasons="sharedData.fraudCheckReasons"
							@finish="onFraudChekActionFinish"
						/>
						<div class="row no-gutters">
							<div class="col-4">
								<div class="section-header pl-45">
									<div class="section-header__title">
										Missing Information
									</div>
									<p>What information do you need in order to proceed with this claim?</p>
								</div>
								<div class="section-content ml-25">
									<div class="container-fluid">
										<div
											class="row no-gutters"
											v-if="!isReadOnlyMode"
										>
											<div class="col-12">
												<aq-select
													:options="missingItemsDictionary"
													option-label="description"
													searchable
													:option-height="10"
													placeholder="Missing item"
													:is-valid="!v$.selectedMissingItem.$error"
													v-model="selectedMissingItem"
													class="select"
													no-result-message="No Missing Item found"
													data-qa="missingItems_select_description"
												/>
											</div>
											<div class="w-100 mt-10" />
											<div class="col-12">
												<aq-select
													:options="missingItemContacts"
													option-label="name"
													:is-sorted="false"
													searchable
													:option-height="10"
													placeholder="Who to contact"
													:async-find="searchVetsAsync"
													:loading="isVetLoading"
													:is-valid="!v$.selectedContact.$error"
													v-model="selectedContact"
													class="select"
													no-result-message="No Contact found"
													data-qa="missingItems_select_contactName"
												>
													<template #default="{ option }">
														<div class="d-flex align-items-center">
															<i
																v-if="option.contactType === 1"
																class="aqv-customer mx-5 fs-20"
															/>
															<RegisteredVet
																v-else-if="option.isRegisteredVet"
																class="ml-2 mr-2 registered-vet-icon"
															/>
															<div class="ml-8 select-option-header text-break text-truncate text-right">
																{{ option.name }}
															</div>
														</div>
													</template>
												</aq-select>
											</div>
											<div class="w-100 mt-10" />
											<div class="col-12">
												<aq-form-input
													label="Comments"
													type="textarea"
													v-model="missingItemComment"
													@input="v$.missingItemComment.$touch"
													:is-valid="!v$.missingItemComment.$error"
													data-qa="missingItems_input_comment"
												/>
												<aq-form-input-error
													class="error mt-8"
													:validator="v$.missingItemComment"
												/>
											</div>
										</div>
										<div
											v-if="showDuplicationError"
											class="row mt-20"
										>
											<div class="col-10">
												<div class="text-danger">
													{{ duplicationErrorMessage }}
												</div>
											</div>
										</div>
										<div
											class="row no-gutters mt-20"
											v-if="!isReadOnlyMode"
										>
											<div class="col-12">
												<button
													type="button"
													class="btn btn-primary d-block ml-auto"
													:disabled="v$.$invalid"
													@click="onAddClaimMissingItem()"
													data-qa="missingItems_button_add"
												>
													Done
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-8">
								<div class="pl-30 h-100 d-flex flex-column">
									<div class="section-header">
										<div class="section-header__title">
											Requested Information
										</div>
										<p>View and manage requested information</p>
									</div>
									<div class="section-content h-100 mr-25">
										<div class="container-fluid">
											<el-table
												v-if="assignedMissingItems.length"
												ref="requestedInformation"
												empty-text="The table is empty"
												:data="assignedMissingItems"
												data-qa="missingItems_table_requestedInfo"
											>
												<el-table-column
													label="Description"
													data-qa="missingItems_column_description"
													min-width="3"
												>
													<template #default="scope">
														<div class="d-flex align-items-center">
															<i class="aqv-description mr-6 fs-20" />
															{{ scope.row.missingItemDescription }}
														</div>
													</template>
												</el-table-column>
												<el-table-column
													label="Contact Name"
													data-qa="missingItems_column_contactName"
													min-width="3"
												>
													<template #default="scope">
														<div class="d-flex align-items-center">
															<i :class="[scope.row.contactType === 1 ? 'aqv-customer' : 'aqv-vet', 'mr-6', 'fs-20']" />
															{{ scope.row.contactName }}
														</div>
													</template>
												</el-table-column>
												<el-table-column
													label="Comment"
													data-qa="missingItems_column_comment"
													min-width="6"
												>
													<template #default="scope">
														<aq-comment :data="scope.row.comment" />
													</template>
												</el-table-column>
												<el-table-column
													data-qa="missingItems_column_remove"
													width="80"
												>
													<template #default="scope">
														<div v-if="!isReadOnlyMode">
															<button
																@click="onRemove(scope.$index)"
																type="button"
																class="btn small btn-row"
																data-qa="missingItems_button_remove"
															>
																<i class="fas fa-times" />
															</button>
														</div>
													</template>
												</el-table-column>
											</el-table>
											<div
												class="d-flex flex-column align-items-center"
												v-else
											>
												<img
													alt="No Missing Info Image"
													:src="require('assets/images/NoMissingInfo.svg')"
												>
												<div class="font-weight-bold fs-16 mt-24 text-subtitle">
													No Requested Information Entered
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<claim-navigation-bar
					:shared-data="sharedData"
					:status="getPageStatus"
					:is-read-only-mode="isReadOnlyMode"
					:multicondition="multicondition"
					:is-last-step="isLastStep"
					@cancel="onCancel"
					@back="onBack"
					@next="onNext"
					@manual-reject="$modal.show('reject-claim')"
					@fraud="$modal.show('fraud-check')"
					@escalate-claim="onClaimEscalateSelect"
					@remove-escalation="onRemoveEscalation"
				>
					<template #success>
						<div class="d-flex align-items-center mx-30">
							<div class="mx-auto">
								<div class="d-inline-block d-flex">
									<div><i class="mr-10 fas fa-check text-white" /></div>
									<div>You have completed all of the required fields. Please proceed to the next screen.</div>
								</div>
							</div>
						</div>
					</template>
				</claim-navigation-bar>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, withMessage } from '@clientCommon/services/utils/validators';
import cloneDeep from 'lodash.clonedeep';
import claimService from '@commonServices/claimService';
import ClaimMissingItemInput from '@commonServices/models/ClaimMissingItemInput';
import { outcomeState, claimNumberStatus, saveClaimAsDraftAllowedStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import { pageStatusEnum } from '@commonServices/models/PageStatusEnum';
import { ValidationMessages } from '@commonServices/utils/constants';
import { mapState } from 'vuex';
import { RegisteredVet } from '@assets/icons';
import { sortComparer, multipleSortComparer } from '@commonServices/utils/general';
import SortingDirection from '@clientCommon/services/models/SortingDirection';

export default {
	inject: ['goNext', 'goPrevious', 'goTo', 'exitClaim', 'updateClaimStatus', 'mountClaimPage', 'leavePage', 'onClaimEscalate'],
	components: {
		RegisteredVet,
	},
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isLoaded: false,
			originalData: null,
			claimId: 0,
			claimStatusId: 0,
			assignedMissingItems: [],
			missingItemsDictionary: [],
			contacts: [],
			selectedMissingItem: null,
			selectedContact: null,
			missingItemComment: null,
			duplicationErrorMessage: 'Current claim already contains selected missing item and contact.',
			showDuplicationError: false,
			rejectionData: {
				isManuallyRejected: false,
				rejectionReasonId: 0,
				comment: null,
			},
			fraudCheck: false,
			fraudCheckReasons: [],
			fraudCheckComment: null,
			rejectionContext: {},
			selectedReassessmentOutcome: null,
			isDirty: false,
			escalationReasonId: null,
			vets: [],
			isVetLoading: false,
		};
	},
	computed: {
		...mapState(['currentUser']),
		isFraudCheck () {
			return this.sharedData.claimStatus === claimNumberStatus.FraudCheck;
		},
		isReadOnlyMode () {
			if (this.sharedData.isContinuationClaim) {
				return !this.$can.EditMissingInformationContinuation || this.sharedData.isReadOnlyMode;
			} else {
				return !this.$can.EditMissingInformation || this.sharedData.isReadOnlyMode;
			}
		},
		isAllowedToSaveClaimAsDraft () {
			return saveClaimAsDraftAllowedStatuses.includes(this.sharedData.claimStatus);
		},
		missingItemContacts () {
			if (this.vets && this.vets.length) {
				return this.vets.map(v => ({ contactId: v.id, name: v.practiceName, contactType: 2 }));
			} else {
				return this.contacts;
			}
		},
		getPageStatus () {
			return pageStatusEnum.Success;
		},
		isLastStep () {
			return this.isReadOnlyMode && !(this.sharedData.claim.hasClaimItems || this.sharedData.claim.hasPayments || this.sharedData.isReassessment);
		},
	},
	async mounted () {
		await this.mountClaimPage();
		this.mountPage();
	},
	validations () {
		return {
			selectedMissingItem: {
				required,
			},
			selectedContact: {
				required,
			},
			missingItemComment: {
				maxLength: withMessage(ValidationMessages.maxLength2000, maxLength(2000)),
			},
		};
	},
	methods: {
		mountPage () {
			this.claimId = this.sharedData.claimId;
			this.claimStatusId = this.sharedData.claimStatus;
			Promise.all([this.getClaimMissingItems(), this.getMissingItems(), this.getMissingItemContacts()]);
			this.isLoaded = true;
		},
		getClaimMissingItems () {
			return claimService.getClaimMissingItems(this.claimId)
				.then(data => {
					this.originalData = cloneDeep(data);
					this.assignedMissingItems = data;
				});
		},
		getMissingItems () {
			if (this.isReadOnlyMode) {
				return Promise.resolve();
			}

			return claimService.getMissingItems()
				.then(data => { this.missingItemsDictionary = data; });
		},
		getMissingItemContacts () {
			if (this.isReadOnlyMode) {
				return Promise.resolve();
			}

			return claimService.getMissingItemContacts(this.claimId)
				.then(data => {
					// Order by customer => registered vets => historical vets => current claim vets => other claims vets.
					const customerContacts = data.filter(c => c.contactType === 1).sort(sortComparer('name'));
					const currentClaimVetIds = this.sharedData.claimVets.map(cv => cv.id);
					const vetContacts = data.filter(c => c.contactType === 2)
						.map(c => {
							const registeredVet = this.sharedData.registeredVets.find(rv => rv.id === c.contactId);
							return {
								...c,
								isRegisteredVet: !!registeredVet?.isRegisteredVet,
								isHistorical: !!registeredVet?.isHistorical,
								isCurrentClaimVet: currentClaimVetIds.includes(c.contactId),
							};
						})
						.sort(multipleSortComparer(['isRegisteredVet', SortingDirection.Descending], ['isHistorical', SortingDirection.Descending], ['isCurrentClaimVet', SortingDirection.Descending], ['name']));
					this.contacts = [
						...customerContacts,
						...vetContacts,
					];
				});
		},
		onRemove (claimMissingItemIndex) {
			this.isDirty = true;
			this.assignedMissingItems.splice(claimMissingItemIndex, 1);
		},
		onAddClaimMissingItem () {
			this.isDirty = true;
			const itemDuplicateIndex = this.assignedMissingItems.findIndex((item) => item.missingItemId === this.selectedMissingItem.id
				&& item.contactId === this.selectedContact.contactId && item.contactType === this.selectedContact.contactType);

			if (itemDuplicateIndex !== -1) {
				this.showDuplicationError = true;
				return;
			}

			const newClaimMissingItemInput = new ClaimMissingItemInput(
				0,
				this.selectedMissingItem.description,
				this.selectedMissingItem.id,
				this.selectedContact.name,
				this.selectedContact.contactId,
				this.selectedContact.contactType,
				this.missingItemComment,
			);

			this.assignedMissingItems.push(newClaimMissingItemInput);
			this.selectedMissingItem = null;
			this.selectedContact = null;
			this.showDuplicationError = false;
			this.missingItemComment = null;
		},
		showOutcomesModal () {
			return this.rejectionData.isManuallyRejected;
		},
		beforeLeaveHandler (isNextStep, newStepName) {
			return this.saveStep();
		},
		async saveStep () {
			if (this.isReadOnlyMode || this.escalationReasonId) {
				return Promise.resolve();
			} else {
				if (this.showOutcomesModal()) {
					const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(outcomeState.Rejected);
					if (userChoice === 'no' || userChoice === 'cross') {
						return;
					}
				}
				return claimService.saveClaimMissingItems(this.claimId, this.assignedMissingItems, this.selectedReassessmentOutcome?.id, this.rejectionData, this.fraudCheck, this.fraudCheckReasons, this.fraudCheckComment)
					.then((claimStatus) => this.updateClaimStatus(claimStatus.value));
			}
		},
		async saveDraft () {
			if (this.isReadOnlyMode
				|| this.rejectionData.isManuallyRejected
				|| this.fraudCheck
				|| this.escalationReasonId) {
				return Promise.resolve();
			}
			if (this.originalData.length !== this.assignedMissingItems.length) {
				const result = await this.$refs.saveChangesDialog.show();
				if (result === 'yes') return this.saveStep();
				if (result === 'no') return Promise.resolve();
				return Promise.reject(new Error('user click cross'));
			}
			return this.saveStep();
		},
		async onNext () {
			if (this.isLastStep) {
				this.leavePage();
			}
			await this.goNext();
		},
		async onBack () {
			await this.goPrevious();
		},
		onCancel () {
			this.leavePage();
		},
		onReject (value) {
			this.rejectionData.isManuallyRejected = true;
			this.rejectionData.rejectionReasonId = value.reasonId;
			this.rejectionData.comment = value.comment;
			this.exitClaim();
		},
		onFraudCheck (reasons, comment) {
			this.fraudCheck = true;
			this.fraudCheckReasons = reasons;
			this.fraudCheckComment = comment;
			this.exitClaim();
		},
		async onFraudChekActionFinish (leaveClaimPage) {
			await this.mountClaimPage();
			if (leaveClaimPage) {
				this.$router.back();
			}
		},
		async onClaimEscalateSelect () {
			this.escalationReasonId = await this.onClaimEscalate(this.isDirty);

			if (this.escalationReasonId) {
				try {
					await this.exitClaim();
					await claimService.escalate(this.claimId, this.escalationReasonId);
				} catch {
				}
				this.escalationReasonId = null;
			}
		},
		async onRemoveEscalation () {
			await this.exitClaim();
			await claimService.removeEscalation(this.claimId);
		},
		searchVetsAsync: debounce(function (searchTerm) {
			if (searchTerm.length >= 3) {
				this.isVetLoading = true;
				claimService.getVets(searchTerm).then(res => {
					this.vets = [...res];
					this.isVetLoading = false;
				});
			} else {
				this.vets = [];
			}
		}, 500),
	},
};
</script>

<style lang="scss" scoped>
.registered-vet-icon {
  fill: var(--sectionTextColour);
  max-height: 22px !important;
  padding-left: 0 !important;
  margin-bottom: 0;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-180 {
  margin-bottom: 180px;
}
</style>
