<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="">
						<div class="pb-12">
							{{ description }}
						</div>
						<div class="pb-12">
							<aq-form-input
								:label="`${label} Name`"
								type="text"
								v-model="name"
								@input="onTouch"
							/>
							<aq-form-input-error
								class="mt-8"
								:validator="v$.name"
							/>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onSave"
						:disabled="v$.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Save
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { maxLength, required, alphaNumAndSpace, withMessage } from '@commonServices/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	props: {
		isNameUniq: {
			type: Boolean,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: null,
		},
		reservedNames: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	data () {
		return {
			name: this.value,
		};
	},
	validations () {
		return {
			name: {
				required: withMessage(ValidationMessages.required, required),
				maxLength: withMessage(ValidationMessages.maxLength100, maxLength(100)),
				alphaNumAndSpace: withMessage('The field can have digital and letter only', alphaNumAndSpace),
				isUniq: withMessage(`${this.label} with such name already exists`, () => this.isNameUniq),
				reservedNames: withMessage('This name is reserved by the system', (value) => !this.reservedNames.includes(value.toLowerCase())),
			},
		};
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onSave () {
			this.$emit('save', this.name);
		},
		onTouch () {
			this.v$.name.$touch();
			this.$emit('touch');
		},
	},
};
</script>
