<template>
	<aq-sandwich
		:header="header"
		ref="sandwich"
		is-active-on-start
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template #content>
			<div class="section-content flex-grow-1">
				<div class="container-fluid">
					<aq-ailments
						data-qa="conditionForm_select_ailment"
						v-model="v$.selectedAilment.$model"
						:is-valid="!v$.selectedAilment.$error"
						:disabled="data.hasRejectedClaims"
					/>
					<aq-form-input-error
						class="error mt-4"
						:validator="v$.selectedAilment"
					/>
					<div class="mt-20" />
					<aq-body-parts
						data-qa="conditionForm_select_bodyPart"
						v-model="v$.selectedBodyPart.$model"
						:is-valid="!v$.selectedBodyPart.$error"
						:disabled="data.hasRejectedClaims"
					/>
					<aq-form-input-error
						class="error mt-4"
						:validator="v$.selectedBodyPart"
					/>
					<div class="mt-20" />
					<slot name="diagnosisCondition" />
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<div class="d-flex text-right">
						<button
							class="btn btn-row mr-10 ml-auto"
							@click="$emit('cancel')"
						>
							Cancel
						</button>
						<button
							class="btn btn-primary"
							@click="onProceed()"
						>
							Proceed
						</button>
					</div>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, unique, withMessage } from '@commonServices/utils/validators';
import { ValidationMessages } from '@commonServices/utils/constants';

export default {
	name: 'DiagnosisCondition',
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	data () {
		return {
			selectedAilment: this.data.condition,
			selectedBodyPart: this.data.bodyPart,
		};
	},
	beforeDestroy () {
		this.onProceed();
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
		header: {
			type: String,
			required: true,
		},
		validation: {
			type: Object,
			required: true,
		},
	},
	validations () {
		return {
			selectedAilment: {
				required: withMessage(ValidationMessages.required, required),
				unique: withMessage('Pre-Existing condition has already been selected as the Related condition', unique(this.data.relatedConditions, this.relatedConditionsComparator)),
			},
			selectedBodyPart: {
				required: withMessage(ValidationMessages.required, required),
			},
		};
	},
	computed: {
		isFormValid () {
			return !this.v$.$invalid && !this.validation.$invalid;
		},
	},
	methods: {
		onProceed () {
			this.v$.$touch();
			this.validation.$touch();
			if (this.isFormValid) {
				this.$refs.sandwich.onToggle();
				this.$emit('passed', {
					condition: this.selectedAilment,
					bodyPart: this.selectedBodyPart,
				});
			}
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		relatedConditionsComparator (value1, value2) {
			if (!value1 || !value2) return false;
			return value1.id === value2.id;
		},
	},
};
</script>
