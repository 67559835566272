<template>
	<div class="dashboard-wrapper">
		<template
			v-if="$can.Search && customerSearchResult.length"
		>
			<aq-search-result :customers="customerSearchResult" />
			<div class="section-footer d-flex pl-45 mx-25 mb-40">
				<aq-page-control
					v-if="customersPaging.rowCount"
					:total="customersPaging.rowCount"
					:page-size="customersPaging.pageSize"
					@page-changed="onCustomersPageChanged"
					class="ml-auto mr-20"
				/>
			</div>
		</template>
		<template v-else>
			<aq-alert-modal
				ref="proceedClaimPaymentAlert"
				name="proceed-claim-payment-alert"
				title="Claim payment approval"
				description="You are not able to approve this claim payment."
				data-qa="taskPage_modal_proceedClaimPaymentAlert"
			/>
			<aq-modal name="reject-task">
				<reject-claim-modal @rejected-claim="onRejectClaim" />
			</aq-modal>
			<aq-modal name="payees-data-modal">
				<payees-data-modal
					title="Payees"
					:data="selectedData"
					@close="onCloseModal"
				/>
			</aq-modal>
			<aq-modal name="flags-data-modal">
				<flags-data-modal
					title="Fraud Flags"
					:data="selectedData"
					@close="onCloseModal"
				/>
			</aq-modal>
			<aq-modal name="missing-data-modal">
				<missing-data-modal
					title="Missing Items"
					:data="selectedData"
					@close="onCloseModal"
				/>
			</aq-modal>
			<reassessment-outcome-modal
				data-qa="aqDashboard_modal_reassessmentOutcomeModal"
				name="reassessment-outcome-modal"
				ref="saveReassessmentOutcomeModal"
				:selected-reassessment-outcome="selectedReassessmentOutcome"
				:claim-id="currentClaimId"
				@input="selectedReassessmentOutcome = $event"
				v-if="currentClaimId"
			/>
			<aq-confirmation-modal
				ref="confirmChaseModal"
				name="confirm-chase-modal"
				title="Chase Confirmation"
				description="Please confirm you wish to begin the missing information chase process"
				yes-label="Proceed"
				no-label="Cancel"
				data-qa="aq-dashboard_modal_confirm-chase"
			/>
			<div>
				<div
					v-if="$can.ViewCustomerAndPet && $can.ViewClaims"
					class="myTasks main"
				>
					<aq-sandwich
						ref="task-sandwich"
						:active-class="''"
						is-active-on-start
						left-caret
						active-container-class="position-relative"
						section-header="section-header click-outside"
						@change-state="onChangeTaskState"
					>
						<template #left-caret>
							<i
								class="text-primary fs-24 fas cursor-pointer"
								:class="[ isTasksVisible ? 'fa-chevron-down' : 'fa-chevron-right' ]"
							/>
						</template>
						<template #header>
							<AqFlagCard
								v-if="isLoaded && ($can.ClaimEdit || $can.ClaimEditContinuation)"
								class="closed-today-flag"
								title="Closed Today"
								:description="currentDateUserClosedClaims"
							/>
							<div class="mx-auto d-flex align-items-center">
								<div class="section-header__title">
									<span>My Tasks</span>
								</div>
								<button
									@click="onGetTask"
									class="btn btn-primary px-50 mx-10 position-absolute"
									:disabled="!getTaskBtnAvailable"
								>
									Get Task
									<div class="plus-button">
										<i class="fas fa-plus text-white" />
									</div>
								</button>
							</div>
						</template>
						<template #content>
							<div
								v-if="allTasks.length === 0"
								class="pt-40 no-tasks"
							>
								<img
									alt="No Tasks Image"
									:src="require(`@/assets/images/${themeIconSettings.NoTasks}.svg`)"
								>
							</div>
							<dashboard-table
								v-if="allTasks.length"
								ref="dashboardTable"
								v-show="isListTaskViewMode"
								class="mb-50"
								:tasks="tasks"
								:paging="tasksPaging"
								:get-task-available="getTaskBtnAvailable"
								:filters="filters"
								:sorting="sorting"
								@page-changed="onPageChanged"
								@row-action="onRowAction"
								@select-payees="onSelectPayees"
								@select-flags="onSelectFlags"
								@select-missing="onSelectMissingInfo"
								@select-task-item="onSelectTask"
								@clear-claim-filters="onClearFilters"
								@delete-claim-filter="onDeleteFilter"
								@apply-claim-filters="onApplyFilters($event); saveFilterState();"
							/>
							<aq-carousel
								v-show="isCardTaskViewMode"
								:data="allTasks"
								@changed-carousel="onResetSelectedTask"
								class="mb-50"
								height="20rem"
							>
								<template #default="slotProps">
									<aq-task-card
										class="aq-dashboard-card"
										:class="[
											{ 'selected': selectedTask === slotProps.card },
											{ 'unselected': selectedTask && selectedTask !== slotProps.card },
										]"
										:task="slotProps.card"
										@select-flags="onSelectFlags"
										@select-payees="onSelectPayees"
										@select-missing="onSelectMissingInfo"
										@row-action="onRowAction"
										@click.native="onSelectTask(slotProps.card)"
									/>
								</template>
							</aq-carousel>
						</template>
						<template #inactive-content>
							<task-summary :tasks="allTasks" />
						</template>
					</aq-sandwich>
				</div>
				<div
					class="main"
					v-if="customers.length > 0"
				>
					<aq-sandwich
						ref="recently-viewed-sandwich"
						:active-class="''"
						is-active-on-start
						left-caret
						active-container-class="position-relative"
						section-header="section-header click-outside"
						@change-state="onChangeRecentlyViewedState"
					>
						<template #left-caret>
							<i
								class="text-primary fs-24 fas cursor-pointer"
								:class="[ isRecentlyViewedVisible ? 'fa-chevron-down' : 'fa-chevron-right' ]"
							/>
						</template>
						<template #header>
							<recently-viewed-sandwich-header
								:view-mode="recentlyViewedMode"
								@toggle-recently-viewed="onUpdateRecentlyViewedMode"
							/>
						</template>
						<template #content>
							<aq-carousel
								v-if="isCustomersRecenltyViewedMode"
								:data="customers"
								@changed-carousel="onResetSelectedCustomer"
								class="mb-25"
								height="16rem"
							>
								<template #default="slotProps">
									<aq-customer-card
										class="aq-dashboard-card bordered-wrapper"
										:class="[
											{ 'special-highlight-card breadcrumb-header--bordered p-0': requireSpecialLicensing(slotProps.card.address.regionCounty) },
											{ 'selected': selectedCustomer === slotProps.card },
											{ 'unselected': selectedCustomer && selectedCustomer !== slotProps.card },
										]"
										:customer="slotProps.card"
										@click.native="onSelectCustomer(slotProps.card)"
									/>
								</template>
							</aq-carousel>
							<aq-carousel
								v-if="isClaimsRecenltyViewedMode"
								:data="recentClaims"
								class="mb-25"
								height="16rem"
							>
								<template #default="slotProps">
									<aq-claim-card
										class="aq-dashboard-card"
										:class="[
											{ 'selected': selectedRecentClaim === slotProps.card },
											{ 'unselected': selectedRecentClaim && selectedRecentClaim !== slotProps.card },
										]"
										:claim="slotProps.card"
										@click.native="onSelectRecentClaim(slotProps.card)"
									/>
								</template>
							</aq-carousel>
						</template>
					</aq-sandwich>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import CustomerService from '@commonServices/customerService';
import WorkflowService from '@commonServices/workflowService';
import { toastActions } from '@commonServices/settings/toastSettings';
import Paging from '@commonServices/models/Paging';
import DashboardTable from '@commonLayout/DashboardTable';
import RecentlyViewedSandwichHeader from '@commonView/RecentlyViewedSandwichHeader';
import TaskSummary from '@commonLayout/tasks/TaskSummary';
import taskRowActionsHandlerMixin from '@mixins/taskRowActionsHandlerMixin';
import { TaskViewModeEnum } from '@commonServices/settings/taskViewSettings';
import { RecentlyViewedModeEnum } from '@clientCommon/services/settings/recentlyViewedSettings';
import AqTaskCard from '@commonWidgets/AqTaskCard';
import AqFlagCard from '@commonWidgets/AqFlagCard';
import { mapState, mapActions, mapGetters } from 'vuex';
import claimService from '@clientCommon/services/claimService';
import eventBus from '@commonServices/eventBus';
import { toClaimDisplayStatus } from '@commonServices/utils/converter';
import { sortComparer, uniqueBy } from '@commonServices/utils/general';
import SortingDirection from '@commonServices/models/SortingDirection';
import SortingColumn from '@commonServices/models/SortingColumn';
import { ClaimFilterEnum, ClaimFilterDisplayMap } from '@commonServices/models/ClaimFilterEnum';
import FilterComponentTypeEnum from '@commonServices/models/FilterComponentTypeEnum';
import reportService from '@commonServices/reportService';
import { getSavedFilters } from '@commonServices/utils/filtersConverter';

const toastOptions = {
	action: toastActions.close,
	duration: 3000,
	singleton: true,
};

const toastNoTasksOptions = {
	icon: 'fa-tasks',
	className: ['toast-alert'],
	action: toastActions.close,
	duration: 3000,
};

const noTasksErrorMessage = 'No available tasks';
export default {
	name: 'AqDashboard',
	components: {
		DashboardTable,
		RecentlyViewedSandwichHeader,
		TaskSummary,
		AqTaskCard,
		AqFlagCard,
	},
	mixins: [taskRowActionsHandlerMixin],
	emits: ['set-view'],
	data () {
		return {
			allTasks: [],
			pageIndex: 0,
			allFilteredTasks: [],
			searchValue: '',
			customerSearchResult: [],
			selectedData: null,
			currentClaimId: null,
			toastMessage: 'Your search returned 0 results',
			customers: [],
			recentClaims: [],
			tasksPaging: new Paging(5),
			customersPaging: new Paging(8),
			isTasksVisible: true,
			isRecentlyViewedVisible: true,
			selectedReassessmentOutcome: null,
			selectedCustomer: null,
			selectedTask: null,
			selectedRecentClaim: null,
			filters: [
				{
					label: ClaimFilterDisplayMap[ClaimFilterEnum.HideAutoAssignedClaims],
					value: false,
					filterFunction: value => task => !value || !task.autoAssigned,
					visible: true,
					key: ClaimFilterEnum.HideAutoAssignedClaims,
					type: FilterComponentTypeEnum.Checkbox,
				},
				{
					label: ClaimFilterDisplayMap[ClaimFilterEnum.Brand],
					value: null,
					multiselect: false,
					filterFunction: value => task => task.brandName === value,
					options: [],
					visible: true,
					key: ClaimFilterEnum.Brand,
					type: FilterComponentTypeEnum.Select,
				},
				{
					label: ClaimFilterDisplayMap[ClaimFilterEnum.Customer],
					value: null,
					multiselect: false,
					filterFunction: value => task => task.customerFullName === value,
					options: [],
					visible: true,
					key: ClaimFilterEnum.Customer,
					type: FilterComponentTypeEnum.Select,
				},
				{
					label: ClaimFilterDisplayMap[ClaimFilterEnum.Pet],
					value: null,
					multiselect: false,
					filterFunction: value => task => task.petName === value,
					options: [],
					visible: true,
					key: ClaimFilterEnum.Pet,
					type: FilterComponentTypeEnum.Select,
				},
				{
					label: ClaimFilterDisplayMap[ClaimFilterEnum.ClaimStatus],
					value: null,
					multiselect: false,
					filterFunction: value => task => task.displayStatus === value,
					options: [],
					visible: true,
					key: ClaimFilterEnum.ClaimStatus,
					type: FilterComponentTypeEnum.Select,
				}],
			sorting: {
				value: null,
				direction: SortingDirection.Ascending,
				columns: [
					new SortingColumn('Time Open', 'openedDate'),
				],
			},
			currentDateUserClosedClaims: 0,
			isLoaded: false,
		};
	},
	async mounted () {
		this.resetInteractionContext();
		this.searchValue = this.$route.query.search;
		if (this.searchValue) {
			const routerObject = await this.search();
			if (routerObject) {
				this.$router.push(routerObject);
				return;
			}

			if (this.customersPaging.rowCount > 0) { // no need to load dashboard data in case of non empty search results
				return;
			}
		}

		this.loadAssignedTasks();
		await this.loadDashboard();

		this.isLoaded = true;
		this.resetInteractionContext();
		eventBus.$on('notes-updated', this.loadAssignedTasks);
	},
	computed: {
		...mapState(['appSettings', 'themeIconSettings', 'isSearchButtonClicked', 'currentUser', 'assignedTasks']),
		...mapGetters(['taskViewMode', 'recentlyViewedMode', 'requireSpecialLicensing', 'tasksFilter']),
		getTaskBtnAvailable () {
			return this.$can.AssignTaskToMe && (this.tasksPaging.rowCount < this.appSettings.maxUserTasks || this.$can.UnlimitedTasksAssignment);
		},
		isListTaskViewMode () {
			return this.taskViewMode === TaskViewModeEnum.List && this.allTasks.length > 0;
		},
		isCardTaskViewMode () {
			return this.taskViewMode === TaskViewModeEnum.Card && this.allTasks.length > 0;
		},
		isCustomersRecenltyViewedMode () {
			return this.recentlyViewedMode === RecentlyViewedModeEnum.Customers;
		},
		isClaimsRecenltyViewedMode () {
			return this.recentlyViewedMode === RecentlyViewedModeEnum.Claims;
		},
		tasks () {
			return this.allFilteredTasks.slice(this.pageIndex, this.pageIndex + this.tasksPaging.pageSize);
		},
	},
	watch: {
		async isSearchButtonClicked () {
			const routerObject = await this.search();
			if (routerObject) {
				this.$router.push(routerObject);
			}
		},
		assignedTasks () {
			this.setTaskData();
		},
	},
	methods: {
		...mapActions(['resetInteractionContext', 'changeInteractionContext', 'updateTaskViewMode', 'updateRecentlyViewedMode', 'loadAssignedTasks', 'saveTasksFilter']),
		search () {
			return CustomerService.getCustomerSearchResult(this.searchValue, this.customersPaging)
				.then((data) => {
					if (data.claim) {
						return { name: 'pet', params: { customerId: data.claim.customerId, petId: data.claim.petId, claimId: data.claim.claimId } };
					}

					if (data.customers.rowCount === 0) {
						this.$toasted.show(this.toastMessage, toastOptions);
					}

					const pet = this.tryGetPetByPolicyNumber(data.customers.result, this.searchValue);
					if (pet) {
						return { name: 'customer', params: { customerId: pet.customerId, petId: pet.petId, policyNumber: this.searchValue } };
					}

					if (data.customers.rowCount === 1) {
						return { name: 'customer', params: { customerId: data.customers.result[0].id } };
					}

					const { result, ...paging } = data.customers;
					this.customersPaging.rowCount = paging.rowCount;
					this.customerSearchResult = result;
					return null;
				});
		},
		tryGetPetByPolicyNumber (customers, policyNumber) {
			for (const customer of customers) {
				const pet = customer.pets.find(pet => pet.policyNumber === policyNumber);
				if (pet) {
					return {
						customerId: customer.id,
						petId: pet.id,
					};
				}
			}
			return null;
		},
		async loadDashboard () {
			const getRecentCustomers = async () => {
				if (this.$can.ViewCustomerAndPet) {
					this.customers = await CustomerService.getRecentlyViewedCustomers();
				}
			};
			const getRecentClaims = async () => {
				if (this.$can.ViewClaims) {
					this.recentClaims = await claimService.getRecentlyViewedClaims();
				}
			};
			const loadUserClosedClaims = async () => {
				this.currentDateUserClosedClaims = (await reportService.getClosedClaims()).claimCount;
			};

			const loadDashboard = [getRecentCustomers(), getRecentClaims()];
			if (this.$can.ClaimEdit || this.$can.ClaimEditContinuation) {
				loadDashboard.push(loadUserClosedClaims());
			}

			await Promise.all(loadDashboard);
		},
		setTaskData () {
			if (this.$can.ViewCustomerAndPet && this.$can.ViewClaims) {
				this.allTasks = this.getTasks(this.assignedTasks);
				for (const filter of this.filters.filter(f => f.type === FilterComponentTypeEnum.Select)) {
					filter.options.splice(0);
				}
				this.filters.find(f => f.key === ClaimFilterEnum.Brand).options.push(...uniqueBy(this.allTasks, 'brandName').map(task => ({ id: task.brandId, description: task.brandName })));
				this.filters.find(f => f.key === ClaimFilterEnum.Customer).options.push(...uniqueBy(this.allTasks, 'customerFullName').map(task => ({ id: task.customerId, description: task.customerFullName })));
				this.filters.find(f => f.key === ClaimFilterEnum.Pet).options.push(...uniqueBy(this.allTasks, 'petName').map(task => ({ id: task.petId, description: task.petName })));
				this.filters.find(f => f.key === ClaimFilterEnum.ClaimStatus).options.push(...uniqueBy(this.allTasks, 'claimStatus').map(task => ({ id: task.claimStatus, description: task.displayStatus })));
				this.allFilteredTasks = this.allTasks;
				this.updatePaging();
				const { filtersToApply } = getSavedFilters(this.filters, this.tasksFilter);
				this.onApplyFilters({ filtersToApply, sortingToApply: this.sorting });
			}
		},
		loadData () {
			this.loadAssignedTasks();
		},
		onPageChanged (index) {
			this.pageIndex = index;
			this.tasksPaging.pageNumber = Math.ceil(this.pageIndex / this.tasksPaging.pageSize) + 1;
			this.onResetSelectedTask();
		},
		async onCustomersPageChanged (pageNumber) {
			this.customersPaging.pageNumber = pageNumber;
			const routerObject = await this.search();
			if (routerObject) {
				this.$router.push(routerObject);
			}
			this.onResetSelectedCustomer();
		},
		async reloadData () {
			await this.$refs.reloadDataAlert.show();
			this.loadDashboard();
			this.loadAssignedTasks();
		},
		async onGetTask () {
			try {
				const nextTask = await WorkflowService.getTask();
				if (nextTask.value === null) {
					this.$toasted.show(noTasksErrorMessage, toastNoTasksOptions);
				}
			} catch (err) {
			}
		},
		setTaskViewMode (mode) {
			this.updateTaskViewMode(mode);
			this.onResetSelectedTask();
		},
		onChangeTaskState (isActive) {
			this.isTasksVisible = isActive;
		},
		onChangeRecentlyViewedState (isActive) {
			this.isRecentlyViewedVisible = isActive;
		},
		sortTasks (task1, task2) {
			return (task1.prioritizedWorkQueue === task2.prioritizedWorkQueue ? 0 : task1.prioritizedWorkQueue ? -1 : 1)
				|| task1.createdDate - task2.createdDate;
		},
		onSelectCustomer (customer) {
			this.onResetSelectedTask();
			this.selectedCustomer = customer;
			this.changeInteractionContext({ customer, claim: null, pet: null });
		},
		onSelectTask (task) {
			this.onResetSelectedCustomer();
			this.onResetSelectedRecentClaim();
			this.selectedTask = task;

			const customer = {
				id: task.customerId,
				brandId: task.brandId,
				firstName: task.customerFirstName,
				lastName: task.customerLastName,
			};
			const pet = {
				id: task.petId,
				name: task.petName,
				policies: task.policies,
			};
			const claim = { id: task.claimId, status: task.claimStatus };
			this.changeInteractionContext({ claim, pet, customer });
		},
		onSelectRecentClaim (selectedClaim) {
			this.onResetSelectedTask();
			this.selectedRecentClaim = selectedClaim;

			const customer = {
				id: selectedClaim.customer.id,
				brandId: selectedClaim.brandId,
				firstName: selectedClaim.customer.firstName,
				lastName: selectedClaim.customer.lastName,
			};
			const pet = { id: selectedClaim.pet.id, name: selectedClaim.pet.name, policies: selectedClaim.pet.policies };
			const claim = { id: selectedClaim.id, status: selectedClaim.claimStatus };
			this.changeInteractionContext({ claim, pet, customer });
		},
		onResetSelectedCustomer () {
			if (this.selectedCustomer) {
				this.selectedCustomer = null;
				this.resetInteractionContext();
			}
		},
		onResetSelectedTask () {
			if (this.selectedTask) {
				this.selectedTask = null;
				this.$refs.dashboardTable.resetSelection();
				this.resetInteractionContext();
			}
		},
		onResetSelectedRecentClaim () {
			if (this.selectedRecentClaim) {
				this.selectedRecentClaim = null;
				this.resetInteractionContext();
			}
		},
		onUpdateRecentlyViewedMode (mode) {
			this.onResetSelectedRecentClaim();
			this.onResetSelectedCustomer();
			this.updateRecentlyViewedMode(mode);
		},
		getTasks (items) {
			return [...items].sort(this.sortTasks).map(t => {
				return {
					...t,
					displayStatus: toClaimDisplayStatus(t.claimStatus),
					customerFullName: t.customerFirstName + ' ' + t.customerLastName,
				};
			});
		},
		onClearFilters () {
			this.sorting.value = null;
			this.sorting.direction = SortingDirection.Ascending;
			for (const filter of this.filters) {
				filter.value = filter.type === FilterComponentTypeEnum.Select ? null : false;
			}
			this.allFilteredTasks = this.allTasks;
			this.updatePaging();
			this.saveFilterState();
		},
		onApplyFilters ({ filtersToApply, sortingToApply }) {
			this.allFilteredTasks = this.allTasks;
			this.filters = filtersToApply;
			this.sorting = sortingToApply;

			const sortingFunc = this.sorting.value ? sortComparer(this.sorting.value.field, this.sorting.direction) : sortComparer('openedDate', SortingDirection.Ascending);
			for (const selectedFilter of this.filters.filter(f => f.value !== null)) {
				const value = selectedFilter.type === FilterComponentTypeEnum.Checkbox ? selectedFilter.value : selectedFilter.value.description;
				this.allFilteredTasks = this.allFilteredTasks.filter(selectedFilter.filterFunction(value));
			}
			this.allFilteredTasks.sort(sortingFunc);
			this.updatePaging();
		},
		onDeleteFilter (term) {
			this.filters.find(filter => filter.key === term.key).value = term.type === FilterComponentTypeEnum.Checkbox ? false : null;
			this.onApplyFilters({ filtersToApply: this.filters, sortingToApply: this.sorting });
			this.saveFilterState();
		},
		updatePaging () {
			this.tasksPaging.pageNumber = 1;
			this.tasksPaging.rowCount = this.allFilteredTasks.length;
			this.tasksPaging.pageCount = Math.ceil(this.tasksPaging.rowCount / this.tasksPaging.pageSize);
		},
		saveFilterState () {
			const filtersToStore = Object.fromEntries(this.filters.map(item => [item.key, item.type === FilterComponentTypeEnum.Checkbox ? item.value : { id: item.value?.id ?? null }]));
			this.saveTasksFilter(filtersToStore);
		},
	},
	beforeDestroy () {
		eventBus.$off('notes-updated', this.loadAssignedTasks);
	},
};
</script>

<style lang="scss" scoped>
.main {
  padding-top: 7px;
}

.no-tasks {
  max-width: 822px;
  display: block;
  margin: auto;
}

.aq-dashboard-card {
  cursor: pointer;
  margin-top: 4px;

  &.selected {
    box-shadow: 0 0 5px var(--controlShadow);
    opacity: 1;
    border: 2px solid $active-color;
    cursor: default;
  }

  &.unselected {
    opacity: 0.8;
    cursor: pointer;
  }
}

.closed-today-flag {
  position: absolute;
  margin-left: 50px;
}

.plus-button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  border: 2px solid $body-color;
  border-radius: 50%;
  transform: scale(0.7);

  .fa-plus {
    font-size: 18px;
    line-height: 35px;
  }
}

.btn-primary {
  right: 1rem;
  letter-spacing: 0.5px;
  font-size: 16px;
  border-radius: 10px 50px 50px 10px;
}
</style>
