<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Add new Ailment"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="no-gutters">
						<div class="pb-12">
							Search for and add a new ailment
						</div>
						<div class="row pb-20">
							<div class="col-12">
								<aq-loss-types
									v-if="isOtherLoss"
									:is-valid="!v$.selectedAilment.$error"
									v-model="selectedAilment"
								/>
								<aq-ailments
									v-else
									data-qa="addClaimCondition_input_ailment"
									v-model="selectedAilment"
									@input="updateConditionType"
									:is-valid="!v$.selectedAilment.$error"
								/>
								<aq-form-input-error
									class="error mt-4"
									:validator="v$.selectedAilment"
								/>
							</div>
						</div>
						<div
							class="row"
						>
							<div
								class="col-6"
								v-if="!isOtherLoss"
							>
								<aq-body-parts
									data-qa="addClaimCondition_input_bodyPart"
									v-model="v$.selectedBodyPart.$model"
									:is-valid="!v$.selectedBodyPart.$error"
								/>
								<aq-form-input-error
									class="error mt-4"
									:validator="v$.selectedBodyPart"
								/>
							</div>
							<div :class="[isOtherLoss ? 'col' : 'col-6']">
								<div v-if="selectedAilment">
									<div v-if="getConditionTypes(selectedAilment).length > 1">
										<aq-select
											data-qa="addClaimCondition_input_conditionType"
											:options="getConditionTypes(selectedAilment)"
											label="Condition Type"
											option-label="description"
											:is-valid="!v$.selectedConditionType.$error"
											class="w-100"
											v-model="v$.selectedConditionType.$model"
											track-by="id"
											no-result-message="No Condition Types found"
										/>
										<aq-form-input-error
											class="error mt-4"
											:validator="v$.selectedConditionType"
										/>
									</div>
									<div v-if="getConditionTypes(selectedAilment).length === 1 && selectedConditionType">
										<div class="label text-primary">
											Condition Type
										</div>
										<div class="mt-10">
											{{ selectedConditionType.description }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="addClaimCondition_button_cancel"
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="addClaimCondition_button_groupSelected"
						@click="onCreate"
						:disabled="v$.$invalid"
						type="button"
						class="btn btn-success btn-height ml-auto"
					>
						Create
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, bypass, withMessage } from '@clientCommon/services/utils/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import ClaimService from '@commonServices/claimService';
import { mapState } from 'vuex';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import AqLossTypes from '@commonWidgets/AqLossTypes';

export default {
	components: {
		AqLossTypes,
	},
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	data () {
		return {
			selectedAilment: null,
			selectedBodyPart: null,
			selectedConditionType: null,
		};
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapState(['claimFormType']),
		isOtherLoss () {
			return this.claimFormType.id === ClaimFormType.OtherLosses;
		},
	},
	validations () {
		const requireIfNotOtherLossFormType = this.isOtherLoss ? bypass : required;

		return {
			selectedAilment: {
				required: withMessage(ValidationMessages.required, required),
			},
			selectedBodyPart: {
				required: withMessage(ValidationMessages.required, requireIfNotOtherLossFormType),
			},
			selectedConditionType: {
				required: withMessage(ValidationMessages.required, required),
			},
		};
	},
	methods: {
		onCreate () {
			if (this.v$.$invalid) {
				return;
			}

			ClaimService.createClaimCondition(this.claimId, this.selectedAilment.id, this.selectedBodyPart?.id, this.selectedConditionType.id).then(res => {
				this.$emit('add-claim-condition', res);
			});
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		updateConditionType () {
			this.v$.selectedAilment.$touch();
			this.selectedConditionType = null;
			if (this.selectedAilment) {
				const conditionTypes = this.getConditionTypes(this.selectedAilment);
				if (conditionTypes.length === 1) {
					this.selectedConditionType = conditionTypes[0];
				}
			}
		},
		getConditionTypes (ailment) {
			return ailment.conditionTypes.filter((item) => item.system);
		},
	},
};
</script>
