<template>
	<VTooltip
		placement="top"
		data-qa="linked-claims-icon"
	>
		<div class="flex-shrink-0 card-icon linked-icon small">
			<i class="fas fa-link" />
		</div>
		<template #popper>
			<div>
				<div
					class="item d-flex justify-content-between"
					v-for="(item, index) in linkedClaims"
					:key="index"
				>
					<div>
						Linked <span
							class="linked-claim text-underline text-bold skip-click-outside"
							@click="onRedirect(item.claimId)"
						>
							{{ claimIdFormat(item.claimId) }}</span>
						for {{ item.ailment || 'no ailment' }}
					</div>
				</div>
			</div>
		</template>
	</VTooltip>
</template>

<script>
import { openClaimNewWindow } from '@clientCommon/services/utils/routerUtils';
import { claimIdFormat } from '@commonServices/utils/filters';

export default {
	name: 'LinkedClaims',
	props: {
		linkedClaims: {
			type: Array,
			required: true,
		},
	},
	methods: {
		claimIdFormat,
		onRedirect (claimId) {
			openClaimNewWindow(this.$router, claimId);
		},
	},
};
</script>

<style lang="scss" scoped>
  .linked-claim {
    cursor: pointer;
  }
</style>
