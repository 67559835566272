import { evaluate } from 'mathjs';
import { helpers, required, requiredIf, maxValue, minValue, maxLength, minLength, and, decimal, between, sameAs, email } from '@vuelidate/validators';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { fromStringToDate, isDate as checkIsDate } from './dateUtils';

const MAX_INT_NUMBER = 2147483647;

// reactive validators
const lessThenOrEqual = (param) =>
	helpers.withParams(
		{ type: 'lessThenOrEqual', value: param },
		(value) => !helpers.req(value) || (!param && param !== 0) || value <= param,
	);

const moreThenOrEqual = (param) =>
	helpers.withParams(
		{ type: 'moreThenOrEqual', value: param },
		(value) => !helpers.req(value) || (!param && param !== 0) || value >= param,
	);

const moreThen = (param) =>
	helpers.withParams(
		{ type: 'moreThen', value: param },
		(value) => !helpers.req(value) || (!param && param !== 0) || value > param,
	);

const notEqual = (param) =>
	helpers.withParams(
		{ type: 'notEqual', value: param },
		(value) => !helpers.req(value) || (!param && param !== 0) || value !== param,
	);

const unique = (param, comparator) =>
	helpers.withParams(
		{ type: 'unique', value: param, comparator },
		(value) => !helpers.req(value) || !param || (!param.some((x) => comparator(x, value))),
	);

const spaces = (value) => !helpers.req(value) || (!!value.trim());
const maxAmount = (param) => (value) => !helpers.req(value) || (helpers.len(value.toString().replace(/\D/g, '')) <= param);
const isDate = (value) => !helpers.req(value) || checkIsDate(value) || fromStringToDate(value) != null;
const alphaNumAndSpace = helpers.regex(/^[a-zA-Z0-9\s]*$/);
const isPostalCode = (locale) => (value) => !helpers.req(value) || getUiSettingsByLocale(locale).postalCodeRegex.test(value);
const isBankCode = (locale) => (value) => !helpers.req(value) || getUiSettingsByLocale(locale).bankCodeRegex.test(value);
const isAccountNumber = (locale) => (value) => !helpers.req(value) || getUiSettingsByLocale(locale).accountNumberRegex.test(value);
const integerOnly = (value) => !helpers.req(value) || (/^[0-9]*$/.test(value) && value <= MAX_INT_NUMBER);
const bypass = helpers.withParams(
	{ type: 'bypass' },
	() => true,
);

const isMathExpression = (value) => !helpers.req(value) || checkIsMathExpression(value);

function checkIsMathExpression (value) {
	try {
		evaluate(value);
		return true;
	} catch {
		return false;
	}
}

const withMessage = helpers.withMessage;

export {
	// vuelidate validators
	required,
	requiredIf,
	maxValue,
	minValue,
	maxLength,
	minLength,
	and,
	decimal,
	between,
	withMessage,
	sameAs,
	email,

	// custom validators
	lessThenOrEqual,
	moreThenOrEqual,
	moreThen,
	notEqual,
	unique,
	spaces,
	maxAmount,
	isDate,
	alphaNumAndSpace,
	isPostalCode,
	isBankCode,
	isAccountNumber,
	integerOnly,
	isMathExpression,
	bypass,
};
