<template>
	<div>
		<aq-list-navigation-control
			:list="zippedLetters"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				fit
				:data="zippedLetters"
				empty-text="There is no data to display"
				:row-class-name="getRowClassName"
				data-qa="zippedLettersView_table_zipFiles"
			>
				<el-table-column
					type="index"
					:index="getIndex"
					width="65"
				/>
				<el-table-column
					prop="fileName"
					label="Zip File"
					min-width="160"
				/>
				<el-table-column
					prop="createdBy"
					label="User Created"
					min-width="150"
				/>
				<el-table-column
					prop="createdDateTime"
					label="Date Created"
					min-width="150"
				/>
				<el-table-column
					prop="lettersCount"
					label="Containing"
				/>
				<el-table-column
					prop="size"
					label="Size"
					min-width="110"
				/>
				<el-table-column
					min-width="150"
				>
					<template #default="scope">
						<button
							class="btn btn-primary small"
							@click="onDownloadZipFile(scope.row.id, scope.row.fileName)"
						>
							Download <i class="ml-10 fas fa-cloud-download-alt" />
						</button>
					</template>
				</el-table-column>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>
import rowHighlightMixin from '@mixins/rowHighlightMixin';

export default {
	mixins: [rowHighlightMixin],
	props: {
		zippedLetters: {
			type: Array,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
		highlightFileId: {
			type: Number,
			default: 0,
		},
	},
	data () {
		return {
			highlighted: false,
		};
	},
	updated () {
		if (this.highlightFileId && !this.highlighted) {
			const itemToHighlight = this.zippedLetters.find(zipFile => zipFile.id === this.highlightFileId);
			this.onSelect(itemToHighlight);
			this.highlighted = true;
		}
	},
	computed: {
	},
	methods: {
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
		onDownloadZipFile (fileId, fileName) {
			this.$emit('file-download', { fileId, fileName });
		},
	},
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-table td {
    padding: 5px 0;
  }
</style>
