import statusCode from '@commonServices/HttpStatusCode';
import SortingDirection from '@commonServices/models/SortingDirection';
import { capitalizeLetter } from '@commonServices/utils/filters';
import { flatten, unflatten } from 'flat';
import { ExportType } from '@clientCommon/services/models/ExportType';

export const FileTypes = {
	pdf: 'pdf',
	image: 'image',
	hosp: 'html',
	html: 'html',
	email: 'email',
};

export const FileExtensions = {
	Pdf: 'pdf',
	Jpg: 'jpg',
	Jpeg: 'jpeg',
	Png: 'png',
	Tiff: 'tiff',
	Tif: 'tif',
	Hosp: 'html',
	Html: 'html',
	Eml: 'eml',
	Oft: 'oft',
	Msg: 'msg',
	Docx: 'docx',
};

export const MimeTypes = {
	[FileExtensions.Pdf]: 'application/pdf',
	[FileExtensions.Jpg]: 'image/jpeg',
	[FileExtensions.Jpeg]: 'image/jpeg',
	[FileExtensions.Png]: 'image/png',
	[FileExtensions.Tiff]: 'image/tiff',
	[FileExtensions.Tif]: 'image/tiff',
	[FileExtensions.Hosp]: 'text/html; charset=utf-8',
	[FileExtensions.Html]: 'text/html; charset=utf-8',
	[FileExtensions.Eml]: 'application/octet-stream',
	[FileExtensions.Oft]: 'application/octet-stream',
	[FileExtensions.Msg]: 'application/octet-stream',
	[FileExtensions.Docx]: 'application/octet-stream',
};

export const MediaTypeMap = {
	[ExportType.Csv]: 'octet/stream',
	[ExportType.Xlsx]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const FileTypesExtensions = [
	{
		key: FileTypes.pdf,
		extensions: [FileExtensions.Pdf],
	},
	{
		key: FileTypes.image,
		extensions: [FileExtensions.Jpg, FileExtensions.Jpeg, FileExtensions.Png, FileExtensions.Tiff, FileExtensions.Tif],
	},
	{
		key: FileTypes.hosp,
		extensions: [FileExtensions.Hosp],
	},
	{
		key: FileTypes.email,
		extensions: [FileExtensions.Eml, FileExtensions.Msg, FileExtensions.Oft],
	},
];

export function getFileExtension (fileName) {
	let extension = '';
	if (fileName) {
		extension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
	}
	return extension.toLowerCase();
}

export function getFileType (fileName) {
	const extension = getFileExtension(fileName);
	return (FileTypesExtensions.find(entry => entry.extensions.includes(extension.toLowerCase())) || {}).key;
}

export function isKnownError (e, ...expectedErrorCodes) {
	return e.response
		&& e.response.status === statusCode.BadRequest
		&& expectedErrorCodes.includes(e.response.data.errorCode);
}

export function validationErrorSummary (e) {
	if (e.response
		&& e.response.status === statusCode.BadRequest
		&& e.response.data
		&& e.response.data.errors) {
		const summary = Object.entries(e.response.data.errors).map(([key, values]) => `${key}: ${values.join('; ')}`).join('<br/>');
		return `Validation errors: <br/> ${summary}`;
	}

	return 'No validation summary';
}

export const NumberFilerTypes = Object.freeze({
	Equals: 'Equals To',
	GreaterThan: 'Greater Than',
	LessThan: 'Less Than',
	Between: 'Between',
});

export function teamInitials (team) {
	return team.name ? team.name.split(' ').slice(0, 2) : ['Team', 'Name'];
}

export const AllNumberFilterTypes = Object.freeze([NumberFilerTypes.Equals, NumberFilerTypes.GreaterThan, NumberFilerTypes.LessThan, NumberFilerTypes.Between]);

export function sortComparer (prop, direction = SortingDirection.Ascending) {
	const multiplier = (direction === SortingDirection.Ascending ? 1 : -1);
	return (a, b) => {
		if (a[prop] === null) return -1 * multiplier;
		if (b[prop] === null) return multiplier;
		return (a[prop]?.valueOf() < b[prop]?.valueOf() ? -1 : 1) * multiplier;
	};
}

export function multipleSortComparer (...args) {
	return (a, b) => {
		for (const prop of args) {
			const multiplier = (prop[1] === SortingDirection.Descending ? -1 : 1);
			const propName = prop[0];
			if (a[propName]?.valueOf() === b[propName]?.valueOf()) continue;
			if (a[propName] === null) return -1 * multiplier;
			if (b[propName] === null) return multiplier;
			return (a[propName]?.valueOf() < b[propName]?.valueOf() ? -1 : 1) * multiplier;
		};
	};
}

export function getQueryString (queryParams) {
	return Object.entries(queryParams).filter(([key, value]) => !!value).map(([key, value]) => `${key}=${value}`).join('&');
}

export function areEqual (obj1, obj2) {
	return deepEqual(obj1, obj2);
}

export function shallowEqual (object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}
	return true;
}

export function areNumbersEqual (value1, value2) {
	return Number.parseFloat(value1.toFixed(2)) === Number.parseFloat(value2.toFixed(2));
}

export function hasFlag (value, enumValue) {
	return (value & enumValue) === enumValue;
}

export function initials (value) {
	return Array.isArray(value) ? value.filter(Boolean).map(capitalizeLetter).join('') : capitalizeLetter(value);
}

export function uniqueBy (array, propertyName) {
	return array.reduce((unique, item) =>
		unique.find(p => p[propertyName] === item[propertyName]) ? unique : [...unique, item], []);
}

export function unique (array) {
	return [...new Set(array)];
}

function deepEqual (object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}
	return true;
}

export function isObject (object) {
	return object != null && typeof object === 'object';
}

export function getPropertyFromObject (object, propertyName) {
	if (object == null || object[propertyName] == null) {
		return null;
	}

	return object[propertyName];
}

export function isObjectValueDifferent (object1, object2, propertyName, nullDefault = false) {
	const object1Property = getPropertyFromObject(object1, propertyName);
	const object2Property = getPropertyFromObject(object2, propertyName);
	if (object1Property == null || object2Property == null) {
		return nullDefault;
	}

	return object1[propertyName] !== object2[propertyName];
}

export function flattenObject (object) {
	return flatten(object);
}

export function unflattenObject (source) {
	return unflatten(source, { object: true });
}

export function stringifyJSON (object) {
	return JSON.stringify(object, (k, v) => (v == null || (v && typeof v === 'object')) ? v : '' + v);
}

export function findAllIndexes (array, predicate) {
	return array.reduce((c, v, i) => predicate(v) ? c.concat(i) : c, []);
}
