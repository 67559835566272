<template>
	<div>
		<aq-form-input
			v-model="v$.amount.$model"
			:disabled="disabled"
			icon-class="fa-sm"
			input-class="table-control"
			type="money"
			:locale="locale"
			@input="$emit('input', $event)"
			:is-valid="!v$.amount.$error"
			data-qa="paymentRecipients_input_paymentAmount"
		/>
		<aq-form-input-error
			class="error mt-4"
			:validator="v$.amount"
		/>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { withMessage, required, minValue, maxLength, maxValue } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		value: {
			type: Number,
			required: false,
		},
		historicalPaymentsTotal: {
			type: Number,
			required: false,
		},
		isPositiveNet: {
			type: Boolean,
			required: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			amount: this.value,
		};
	},
	watch: {
		value (newValue) {
			this.v$.amount.$model = newValue;
		},
	},
	validations () {
		const commonValidations = {
			required: withMessage(ValidationMessages.required, required),
			valid: withMessage('Value cannot be 0', (value) => this.disabled || value !== 0),
			maxLength: withMessage('The max length is 18 digits', maxLength(18)),
		};

		if (this.isPositiveNet) {
			return {
				amount: {
					...commonValidations,
					minValue: withMessage(ValidationMessages.noNegative, minValue(0)),
				},
				$validationGroups: {
					firstGroup: ['amount.minValue', 'amount.maxLength'],
				},
			};
		} else {
			return {
				amount: {
					...commonValidations,
					maxValue: withMessage('This field cannot be positive', maxValue(0)),
					exceedValue: withMessage('Value cannot exceed original split amount', (value) => {
						const amount = parseFloat(value);

						if (amount !== 0 && this.historicalPaymentsTotal) {
							const difference = amount + this.historicalPaymentsTotal;
							return difference >= 0;
						}

						return true;
					}),
				},
				$validationGroups: {
					firstGroup: ['amount.maxValue', 'amount.maxLength'],
				},
			};
		}
	},
};
</script>
