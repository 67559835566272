<template>
	<div>
		<aq-modal name="fast-track-modal">
			<aq-modal-container
				title="Fast Tracked Reasons"
				@close="onCancel"
				v-click-outside="onCancel"
			>
				<div class="modal-content">
					<div class="p-20">
						<div class="container-fluid">
							<div class="d-flex justify-content-center fast-track-reviewed-icon">
								<i class="aqv-fast-track" />
							</div>
							<div class="d-flex justify-content-center col-12 pb-12 font-weight-bold">
								Please select a reason for Fast Track
							</div>
							<div class="row no-gutters">
								<div class="col-12">
									<aq-select
										:options="fastTrackReasons"
										label="Fast Track Reason"
										option-label="description"
										track-by="id"
										no-result-message="No Reason found"
										:is-sorted="false"
										v-model="selectedReason"
										data-qa="fastTrackModal_select_reasons"
									>
										<template #default="props">
											<div class="px-10">
												<div class="select-option-header text-truncate">
													{{ props.option.description }}
												</div>
											</div>
										</template>
									</aq-select>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-bottom d-flex align-items-center px-20 py-10">
						<button
							@click="onCancel"
							type="button"
							class="btn btn-secondary"
						>
							Cancel
						</button>
						<button
							@click="onSave"
							:disabled="v$.$invalid"
							type="button"
							class="btn btn-success ml-auto"
						>
							Save
						</button>
					</div>
				</div>
			</aq-modal-container>
		</aq-modal>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ExtraService from '@clientCommon/services/extraService';
import { sortComparer } from '@commonServices/utils/general';

export default {
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
	},
	data () {
		return {
			selectedReason: null,
			fastTrackReasons: [],
			promiseResolver: null,
		};
	},
	validations () {
		return {
			selectedReason: {
				required,
			},
		};
	},
	methods: {
		async show () {
			this.fastTrackReasons = await ExtraService.getFastTrackReasons();
			this.fastTrackReasons.sort(sortComparer('description'));
			const otherReasonIndex = this.fastTrackReasons.findIndex(reason => reason.description.toLowerCase() === 'other');

			if (otherReasonIndex > -1) {
				this.fastTrackReasons.push(this.fastTrackReasons.splice(otherReasonIndex, 1)[0]);
			}

			this.$modal.show('fast-track-modal');
			return new Promise((resolve) => {
				this.promiseResolver = resolve;
			});
		},
		complete (result) {
			this.$modal.hide('fast-track-modal');
			this.promiseResolver(result);
		},
		onCancel () {
			this.selectedReason = null;
			this.complete(false);
		},
		onSave () {
			ClaimService.flagFastTrack(this.claimId, this.selectedReason.id)
				.then(result => {
					this.complete(result);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.fast-track-reviewed-icon {
  fill: var(--bodyColour);
}

.aqv-fast-track::before {
  font-size: 40px;
}
</style>
