<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="modalTitle"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid content-wrapper">
						<aq-sandwich
							header="Policy Sections"
							is-active-on-start
							active-class=""
							header-class="fs-20"
							class="mb-4"
							right-caret
						>
							<template #content>
								<PolicySectionsDetails :policy-sections="details.policySections" />
							</template>
						</aq-sandwich>
						<aq-sandwich
							header="Wait Periods"
							active-class=""
							header-class="fs-20"
							class="mb-4"
							right-caret
						>
							<template #content>
								<AqWaitPeriodsTable
									:waiting-periods="addEndDateToWaitingPeriods(details.waitingPeriods, details.policyInceptionDate)"
								/>
							</template>
						</aq-sandwich>
						<aq-sandwich
							class="mb-4"
							header="Optional Coverages"
							active-class=""
							header-class="fs-20"
							right-caret
						>
							<template #content>
								<div
									v-if="addonProducts.length"
								>
									<div
										v-for="addon in addonProducts"
										:key="addon.id"
										class="pt-4"
									>
										<aq-sandwich
											active-class=""
											section-header="addon-header d-flex"
											right-caret
										>
											<template #header>
												<div class="addon-sub-header row">
													<div class="col-4">
														<i class="fas fa-flag text-danger mr-10" /> {{ addon.productName }}
													</div>
													<div class="col-8 text-right">
														<p class="text-primary font-weight-bold">
															Cover period
														</p>
														<div>{{ addon.policyTermStartDate | longDate }} - {{ addon.policyTermEndDate | longDate }}</div>
													</div>
												</div>
											</template>
											<template #content>
												<PolicySectionsDetails :policy-sections="addon.policySections" />
												<aq-sandwich
													header="Wait Periods"
													active-class=""
													header-class="fs-14 font-weight-normal"
													class="mb-4 wp-header"
													right-caret
												>
													<template #content>
														<AqWaitPeriodsTable
															:waiting-periods="addEndDateToWaitingPeriods(addon.waitingPeriods, addon.policyInceptionDate)"
														/>
													</template>
												</aq-sandwich>
											</template>
											<template #right-caret="slotProps">
												<div class="icon mt-15 cursor-pointer">
													<i :class="['fas', slotProps.isActive ? 'fa-minus-circle' : 'fa-plus-circle']" />
												</div>
											</template>
										</aq-sandwich>
									</div>
								</div>
								<div
									v-else
									class="text-center no-data-message"
								>
									No Optional Coverages
								</div>
							</template>
						</aq-sandwich>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import ProductsService from '@commonServices/productsService';
import { ProductFeatures } from '@commonServices/models/ProductFeatures';
import { hasFlag } from '@commonServices/utils/general';
import AqWaitPeriodsTable from '@commonWidgets/Products/AqWaitPeriodsTable';
import { addEndDateToWaitingPeriods } from '@commonServices/utils/converter';
import PolicySectionsDetails from '@commonView/Shared/ProductDetailsModal/PolicySectionsDetails';

export default {
	components: {
		AqWaitPeriodsTable,
		PolicySectionsDetails,
	},
	name: 'ProductDetailsModal',
	props: {
		policyTermId: {
			type: Number,
			required: true,
		},
	},
	data () {
		return {
			details: {
				productDisplayName: '',
			},
		};
	},
	mounted () {
		ProductsService.getProductDetailsByPolicyTerm(this.policyTermId)
			.then(data => (this.details = data));
	},
	computed: {
		modalTitle () {
			return `${this.details.productName} ${hasFlag(this.details.productFeatures, ProductFeatures.NoIllnessCoverage) ? '- Accident Only'.toUpperCase() : ''}`;
		},
		addonProducts () {
			return this.details.addonProducts ?? [];
		},
	},
	methods: {
		addEndDateToWaitingPeriods,
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-content {
  width: 80rem;

  .icon {
    color: $body-color;
  }

  ::v-deep .table-wait-period {
    div {
      table {
        border-spacing: 0 6px !important;
      }
    }
  }

  ::v-deep .table-wait-period-row {
    &.highlighted {
      td {
        border-top: 2px solid $warning;
        border-bottom: 2px solid $warning;
      }

      td:is(:last-child) {
        border-right: 2px solid $warning;
      }

      td:is(:first-child) {
        border-left: 2px solid $warning;
      }
    }
  }

  ::v-deep .table-wait-period-header {
    background: var(--tableGroupBg);

    th > div.cell {
      font-size: 14px !important;
      padding: 10px;
      font-weight: bold;
    }
  }

  ::v-deep .addon-header {
    background: var(--tableGroupBg);

    .addon-sub-header {
      padding: 5px 20px;
      align-items: center !important;
      width: 100%;
      color: var(--bodyColour) !important;

      div:first-child {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .wp-header {
    .section-header {
      padding: 5px 20px;
      min-height: 50px;
      background: var(--subHeaderBg);
    }
  }
}

.content-wrapper {
  overflow-y: auto;
  max-height: 35rem;

  .no-data-message {
    padding: 10px 0;
    margin-top: 6px;
    background: var(--attributeBg);
  }
}
</style>
