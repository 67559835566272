<template>
	<div class="d-flex flex-column h-100">
		<div class="section-header d-flex create-claim-header">
			<div>
				<div class="section-header__title">
					Create Claim
				</div>
				<p>You are now creating a new linked claim for claim id: {{ claimId | claimIdFormat }}</p>
			</div>
			<div
				@click="$emit('close')"
				v-if="!isReadOnlyMode"
				class="close text-white ml-auto mr-24"
			>
				<i class="fas fa-times fa-lg" />
			</div>
		</div>
		<div class="section-content items-list">
			<div class="flex-grow-1 d-flex flex-column ml-20 mr-20 ">
				<div class="section-header section-header-min-height">
					<div class="section-header__title">
						Basic Details
					</div>
				</div>
				<div class="row no-gutters mt-20 claim-basic">
					<ClaimBasic
						:locale="locale"
						:basic="basic"
						:validator="v$.basic"
						:is-read-only-mode="isReadOnlyMode"
						:labels="labels"
					/>
				</div>
				<div class="section-header section-header-min-height mt-40">
					<div class="section-header__title">
						{{ labels.diagnosis }}
					</div>
				</div>
				<div class="row no-gutters mt-20">
					<div class="col-6">
						<aq-loss-types
							v-if="isOtherLoss"
							:is-valid="!v$.selectedAilment.$error"
							v-model="v$.selectedAilment.$model"
							:disabled="isReadOnlyMode"
						/>
						<aq-ailments
							v-else
							data-qa="addClaimLayout_input_ailment"
							v-model="selectedAilment"
							@input="updateConditionType"
							:is-valid="!v$.selectedAilment.$error"
						/>
						<aq-form-input-error
							class="error mt-4"
							:validator="v$.selectedAilment"
						/>
					</div>
					<div
						v-if="!isOtherLoss"
						class="col-3 pl-10"
					>
						<aq-body-parts
							data-qa="addClaimLayout_input_bodyPart"
							v-model="v$.selectedBodyPart.$model"
							:is-valid="!v$.selectedBodyPart.$error"
						/>
						<aq-form-input-error
							class="error mt-4"
							:validator="v$.selectedBodyPart"
						/>
					</div>
					<div class="col-3 pl-10">
						<div v-if="selectedAilment">
							<div v-if="getConditionTypes(selectedAilment).length > 1">
								<aq-select
									data-qa="addClaimCondition_input_conditionType"
									:options="getConditionTypes(selectedAilment)"
									label="Condition Type"
									option-label="description"
									:is-valid="!v$.selectedConditionType.$error"
									class="w-100"
									v-model="v$.selectedConditionType.$model"
									track-by="id"
									no-result-message="No Condition Types found"
								/>
								<aq-form-input-error
									class="error mt-4"
									:validator="v$.selectedConditionType"
								/>
							</div>
							<div v-if="getConditionTypes(selectedAilment).length === 1 && selectedConditionType">
								<div class="label text-primary">
									Condition Type
								</div>
								<div class="mt-10">
									{{ selectedConditionType.description }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="section-header section-header-min-height mt-40"
					v-if="!isOtherLoss"
				>
					<div class="section-header__title">
						Vet Details
					</div>
				</div>
				<div
					v-if="!isOtherLoss"
					class="mt-20 vets-container"
				>
					<claim-vets
						:selected-vets="selectedVets"
						:registered-vets="registeredVets"
						:is-read-only-mode="isReadOnlyMode"
						:locale="locale"
						:validator="v$.selectedVets"
					/>
				</div>
			</div>
		</div>

		<div class="section-footer">
			<div class="container-fluid d-flex justify-content-end">
				<button
					class="btn btn-secondary btn-radius--less mr-10"
					:disabled="isReadOnlyMode"
					@click="$emit('close')"
				>
					Cancel
				</button>
				<button
					class="btn btn-primary btn-radius--less"
					:disabled="isReadOnlyMode"
					@click="onCreateClaim"
				>
					Proceed
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { and, required, maxValue, maxLength, spaces, moreThenOrEqual, lessThenOrEqual, moreThen, maxAmount, bypass, withMessage } from '@commonServices/utils/validators';
import { addDays, minDate } from '@commonServices/utils/dateUtils';
import { shortDate } from '@commonServices/utils/filters';
import { ValidationMessages } from '@commonServices/utils/constants';
import { mapState, mapGetters } from 'vuex';
import ClaimVets from '@commonView/ClaimPage/ClaimEnter/ClaimVets';
import ClaimBasic from '@commonView/ClaimPage/ClaimEnter/ClaimBasic';
import { ClaimFormType, ClaimFormTypeLabels } from '@commonServices/models/ClaimFormType';
import AqLossTypes from '@commonWidgets/AqLossTypes';

const TODAY = new Date();
const FORTNIGHT = 14;

export default {
	name: 'AddClaimLayout',
	components: {
		ClaimVets,
		ClaimBasic,
		AqLossTypes,
	},
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			basic: {
				description: null,
				dateOfLoss: null,
				dateOfDeath: null,
				treatmentStart: null,
				treatmentEnd: null,
				amount: null,
			},
			selectedAilment: null,
			selectedBodyPart: null,
			selectedConditionType: null,
			selectedVets: [],
		};
	},
	validations () {
		return {
			basic: {
				description: {
					required: withMessage(ValidationMessages.required, and(required, spaces)),
					maxLength: withMessage(ValidationMessages.maxLength2000, maxLength(2000)),
				},
				dateOfLoss: {
					required: withMessage(ValidationMessages.required, required),
					maxValue: withMessage(ValidationMessages.dateInFuture, maxValue(TODAY)),
					minValue: withMessage(ValidationMessages.dateShouldBeEqualOrAfter(shortDate(minDate)), moreThenOrEqual(minDate)),
				},
				dateOfDeath: {
					maxValue: withMessage(ValidationMessages.dateInFuture, maxValue(TODAY)),
					minValue: withMessage(ValidationMessages.dateCannotBeBefore(this.dateOfLossLabel), moreThenOrEqual(this.basic.dateOfLoss)),
				},
				treatmentStart: {
					required: withMessage(ValidationMessages.required, required),
					maxValue: withMessage(ValidationMessages.dateInFuture, maxValue(TODAY)),
					minValue: withMessage(ValidationMessages.dateCannotBeBefore(this.dateOfLossLabel), moreThenOrEqual(this.basic.dateOfLoss)),
					deathValue: withMessage('Date of Death plus 14 days', lessThenOrEqual(this.basic.dateOfDeath ? addDays(this.basic.dateOfDeath, FORTNIGHT) : null)),
				},
				treatmentEnd: {
					required: withMessage(ValidationMessages.required, required),
					maxValue: withMessage(ValidationMessages.dateInFuture, maxValue(TODAY)),
					treatmentStart: withMessage(ValidationMessages.dateShouldBeAfter(this.dateOfLossLabel), moreThenOrEqual(this.basic.dateOfLoss)),
					minValue: withMessage(ValidationMessages.dateShouldBeAfter('Treatment From'), moreThenOrEqual(this.basic.treatmentStart)),
					deathValue: withMessage('Date of Death plus 14 days', lessThenOrEqual(this.basic.dateOfDeath ? addDays(this.basic.dateOfDeath, FORTNIGHT) : null)),
				},
				amount: {
					required: withMessage(ValidationMessages.required, required),
					minValue: withMessage(ValidationMessages.greaterThanZero('Claim Amount'), moreThen(0)),
					maxAmount: withMessage(ValidationMessages.maxAmount, maxAmount(16)),
				},
			},
			selectedAilment: {
				required: withMessage(ValidationMessages.required, required),
			},
			selectedBodyPart: {
				required: withMessage(ValidationMessages.required, this.isOtherLoss ? bypass : required),
			},
			selectedConditionType: {
				required: withMessage(ValidationMessages.required, required),
			},
			selectedVets: {
				required: bypass,
			},
		};
	},
	computed: {
		...mapState(['interactionContext', 'claimFormType']),
		...mapGetters(['dateOfLossLabel']),
		registeredVets () {
			return this.interactionContext.pet?.registeredVets ?? [];
		},
		isOtherLoss () {
			return this.claimFormType.id === ClaimFormType.OtherLosses;
		},
		labels () {
			return ClaimFormTypeLabels[this.claimFormType.id];
		},
	},
	methods: {
		onCreateClaim () {
			this.v$.$touch();
			if (this.v$.$invalid) {
				return;
			}

			const data = {
				description: this.basic.description,
				dateOfLoss: this.basic.dateOfLoss,
				dateOfDeath: this.basic.dateOfDeath,
				treatmentStart: this.basic.treatmentStart,
				treatmentEnd: this.basic.treatmentEnd,
				claimAmount: this.basic.amount,
				ailmentId: this.selectedAilment.id,
				bodyPartId: this.selectedBodyPart?.id,
				conditionTypeId: this.selectedConditionType.id,
				vetIds: this.selectedVets.map((vet) => vet.id),
				claimFormType: this.claimFormType.id,
			};

			this.$emit('create', data);
		},
		updateConditionType () {
			this.v$.selectedAilment.$touch();
			this.selectedConditionType = null;
			if (this.selectedAilment) {
				const conditionTypes = this.getConditionTypes(this.selectedAilment);
				if (conditionTypes.length === 1) {
					this.selectedConditionType = conditionTypes[0];
				}
			}
		},
		getConditionTypes (ailment) {
			return ailment.conditionTypes.filter((item) => item.system);
		},
	},
};
</script>

<style lang="scss" scoped>
  .items-list {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
  }

  .claim-basic {
    ::v-deep .date-input {
      margin-right: 10px;
    }

    ::v-deep .control-container {
      padding-right: 10px;
      padding-left: 10px;

      .label {
        left: 10px;
      }
    }
  }

  .vets-container {
    .container-fluid {
      padding: 0 !important;
    }
  }

  .create-claim-header {
    background-color: $primary;
  }

  .section-header-min-height {
    min-height: 0;
  }

  .close {
    cursor: pointer;
  }

  ::v-deep .vdp-datepicker {
    .vdp-datepicker__calendar {
      width: 250px !important;
    }

    .vdp-datepicker__clear-button {
      right: 20px !important;
    }
  }

</style>
