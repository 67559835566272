<template>
	<div
		v-if="parentClaim"
		class="claim-versions h-100"
	>
		<div class="container-fluid p-0 h-100 d-flex flex-column">
			<div class="row no-gutters align-items-stretch">
				<div class="col current-details-bg px-23 py-21 d-flex align-items-center">
					<button
						data-qa="claimVersions_btn_back"
						type="button"
						class="btn btn-secondary mr-14"
						@click="$emit('close')"
					>
						Back
					</button>
					<span class="fs-16">
						Return to Claim
					</span>
				</div>
				<div
					class="col version-details-bg px-23 py-21 d-flex align-items-center justify-content-between"
					v-if="claimCopy"
				>
					<VDropdown
						:triggers="[]"
						:shown="isVersionsListOpen"
						@hide="isVersionsListOpen = false"
						popper-class="claim-versions-popover tooltip-arrow-hidden"
					>
						<div
							data-qa="claimVersions_btn_versionsList"
							class="claim-versions-selector d-flex align-items-center pl-16 pr-10 py-6 cursor-pointer"
							@click="onToggleVersionsList"
						>
							<div
								data-qa="claim-versions_icon_reassesment"
								class="card-icon small warning"
							>
								<ReassessedClaimOpen />
							</div>
							<div class="flex-grow-1 ml-6">
								Version {{ claimCopy.version }}
							</div>
							<i
								class="text-warning fs-24 fas claim-versions-selector-arrow"
								:class="[isVersionsListOpen ? 'fa-chevron-down': 'fa-chevron-right']"
							/>
						</div>
						<template #popper>
							<div
								class="claim-versions-popover-list p-4"
							>
								<div
									v-for="(version, index) of claimCopies"
									:key="index"
									:class="[{ 'selected-version' : version === claimCopy }, 'claim-versions-popover-list-item px-18 py-4 mb-3 cursor-pointer']"
									@click="onSelectVersion(version, index)"
								>
									Version {{ version.version }}
									<span>- {{ (version.closedDate || version.assessedDate) | shortDate }}</span>
								</div>
							</div>
						</template>
					</VDropdown>
					<div class="d-flex align-items-center">
						<div
							:data-qa="`claimVersions_btn_step_${step.stepName}`"
							:class="[{ 'disabled': !step.available }, 'step d-flex align-items-center mr-20']"
							v-for="(step, index) of steps"
							:key="index"
							@click="onGoToStep(step.stepName)"
						>
							<div
								:class="[{ 'active': step.stepName === claimCopyStep },
									{ 'cursor-default': !step.available || step.stepName === claimCopyStep },
									'circle step-icon mr-10 fs-16']"
								v-tooltip="{ content: step.available ? '' : 'Step is not available' }"
							>
								<i :class="step.iconClass" />
							</div>
							<div class="font-weight-bold">
								{{ step.title }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row no-gutters">
				<div class="col bg-primary fs-18 p-20 text-white d-flex align-items-center">
					Current Claim Details
				</div>
				<div
					class="col reassess-copy-section p-20 fs-18 text-white d-flex align-items-center justify-content-between"
					v-if="claimCopy"
				>
					<div>
						<span>Claim Version {{ claimCopy.version }} </span>
						<span>({{ assessedDate | shortDate }})</span>
					</div>
					<aq-icon-avatar
						data-qa="claimVersions_icon_closedByUser"
						:value="assessedUser"
						class="user-icon small font-weight-bold"
					/>
				</div>
			</div>
			<div class="row no-gutters h-100 flex-grow-1">
				<component
					data-qa="claimVersions_page_parentClaimStep"
					v-if="parentClaim"
					class="col current-details-bg h-100"
					:is="parentClaimStep"
					:claim="parentClaim"
				/>
				<component
					data-qa="claimVersions_page_copyClaimStep"
					v-if="claimCopy"
					class="col version-details-bg h-100"
					:is="claimCopyStep"
					:claim="claimCopy"
					:claim-comparison="parentClaim"
					is-copy
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { ReassessedClaimOpen } from '@assets/icons';
import EnterClaimView from '@commonView/ClaimPage/ClaimVersions/EnterClaimView';
import PaymentClaimView from '@commonView/ClaimPage/ClaimVersions/PaymentClaimView';
import ProcessClaimView from '@commonView/ClaimPage/ClaimVersions/ProcessClaimView';
import claimService from '@commonServices/claimService';
import { toClaimVersions } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

const ClaimStepNames = Object.freeze({
	EnterClaimView: 'enter-claim-view',
	ProcessClaimView: 'process-claim-view',
	PaymentClaimView: 'payment-claim-view',
});

export default {
	name: 'ClaimVersions',
	components: {
		ReassessedClaimOpen,
		EnterClaimView,
		ProcessClaimView,
		PaymentClaimView,
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			isVersionsListOpen: false,
			claims: [],
			parentClaim: null,
			claimCopy: null,
			claimCopies: [],
			parentClaimStep: null,
			claimCopyStep: null,
			steps: [
				{
					title: 'Claim',
					iconClass: 'fas fa-file-invoice',
					stepName: ClaimStepNames.EnterClaimView,
					available: true,
				},
				{
					title: 'Process',
					iconClass: 'fas fa-sync',
					stepName: ClaimStepNames.ProcessClaimView,
					available: true,
				},
				{
					title: 'Payment',
					iconClass: 'fas fa-credit-card',
					stepName: ClaimStepNames.PaymentClaimView,
					available: true,
				},
			],
		};
	},
	mounted () {
		claimService.getClaimVersions(this.claimId)
			.then(claims => {
				this.claims = toClaimVersions(claims, this.locale, this.claimStepState);
				this.parentClaim = this.claims.find(claim => claim.isParent);
				this.claimCopies = this.claims.filter(claim => !claim.isParent);
				this.claimCopy = this.claimCopies.sort((a, b) => a.version - b.version)[this.claimCopies.length - 1];
				this.parentClaimStep = ClaimStepNames.EnterClaimView;
				this.claimCopyStep = ClaimStepNames.EnterClaimView;
				this.updateStepsAvailability();
			});
	},
	methods: {
		onToggleVersionsList () {
			this.isVersionsListOpen = !this.isVersionsListOpen;
		},
		onSelectVersion (version) {
			this.claimCopy = version;
			this.isVersionsListOpen = false;

			if (this.checkClaimAvailableSteps(version, this.claimCopyStep)) {
				this.onGoToStep(this.claimCopyStep);
			} else {
				this.onGoToStep(ClaimStepNames.EnterClaimView);
			}
			this.updateStepsAvailability();
		},
		checkClaimAvailableSteps (claim, stepName) {
			const availableSteps = [ClaimStepNames.EnterClaimView];

			if (claim.claimItems.length > 0) {
				availableSteps.push(ClaimStepNames.ProcessClaimView);
			}

			if (claim.payments.paymentBreakdown.summary.length > 0) {
				availableSteps.push(ClaimStepNames.PaymentClaimView);
			}

			return availableSteps.includes(stepName);
		},
		onGoToStep (stepName) {
			if (this.checkClaimAvailableSteps(this.parentClaim, stepName)) {
				this.parentClaimStep = stepName;
			}
			if (this.checkClaimAvailableSteps(this.claimCopy, stepName)) {
				this.claimCopyStep = stepName;
			}
		},
		updateStepsAvailability () {
			this.steps.forEach(step => {
				step.available = this.checkClaimAvailableSteps(this.parentClaim, step.stepName)
				|| this.checkClaimAvailableSteps(this.claimCopy, step.stepName);
			});
		},
	},
	computed: {
		...mapState(['claimStepState']),
		assessedUser () {
			const user = this.claimCopy.assessedBy ?? this.claimCopy.closedBy;
			return [user.firstName, user.lastName];
		},
		assessedDate () {
			return this.claimCopy.assessedDate ?? this.claimCopy.closedDate;
		},
	},
};
</script>

<style lang="scss" scoped>
.claim-versions {
  position: absolute;
  inset: 74px 0 30px 80px;
  z-index: 99;

  .user-icon {
    background: var(--copyStepIcon);
    color: var(--bodyColour);
  }

  .current-details-bg {
    background: var(--contentBg);
  }

  .version-details-bg {
    background: var(--claimVersionsBg);
  }

  &-selector {
    border: 2px solid $warning;
    border-radius: 4px;
    background: var(--contentBg);
    min-width: 20rem;

    &-arrow {
      min-width: 21px;
    }
  }

  .reassess-copy-section {
    background: var(--claimVersionsSection);
  }

  .step {
    &.disabled {
      opacity: 0.6;
    }

    &-icon {
      background: var(--copyStepIcon);

      &.active {
        background: $warning;
        color: var(--bodyBg);
      }
    }
  }
}
</style>

<style lang="scss">
.claim-versions-popover {
  .v-popper__inner {
    border-radius: 0;
  }

  &-list {
    background: var(--contentBg);
    box-shadow: 0 3px 6px #0006;
    min-width: 19rem;

    &-item {
      background: var(--revertSection);

      &.selected-version {
        background: $danger;
        color: $body-color;
      }

      &:hover {
        color: $body-color;
        background: $warning;
      }
    }
  }
}
</style>
