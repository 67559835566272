<template>
	<div>
		<aq-modal-container
			@close="$modal.hide(modalName)"
			title="Add a new Payee"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-6 pb-18 pr-16">
								<aq-select
									option-label="description"
									label="Payment Type"
									:options="paymentTypes"
									:max-height="150"
									@input="onPaymentChanged"
									class="payment-select"
									v-model="paymentMethod"
									no-result-message="No Payment Type found"
									data-qa="payeeModal_select_paymentType"
								>
									<template #default="{ option }">
										<div>{{ option.description }}</div>
									</template>
								</aq-select>
							</div>
							<div
								class="col-6 pb-18 pl-16"
								v-if="paymentMethod"
							>
								<aq-form-input
									label="Payee"
									type="text"
									v-model="v$.payee.$model"
									:is-valid="!v$.payee.$error"
									class="payee-input"
									data-qa="payeeModal_input_payee"
								/>
								<aq-form-input-error
									class="error mt-4"
									:validator="v$.payee"
								/>
							</div>
						</div>
						<div
							class="row no-gutters"
							v-if="paymentMethod && paymentMethod.value===2"
						>
							<div class="col-6 pb-12 pr-16">
								<aq-form-input
									:label="uiLocaleSettings.bankCodeLabel"
									v-model="v$.sortCode.$model"
									:is-valid="!v$.sortCode.$error"
									data-qa="payeeModal_input_sortCode"
								/>
								<aq-form-input-error
									class="error mt-4"
									:validator="v$.sortCode"
								/>
							</div>
							<div class="col-6 pb-12 pl-16">
								<aq-form-input
									label="Account Number"
									v-model="v$.accountNumber.$model"
									:is-valid="!v$.accountNumber.$error"
									data-qa="payeeModal_input_accountNumber"
								/>
								<aq-form-input-error
									class="error mt-4"
									:validator="v$.accountNumber"
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="errorMsg"
					class="bg-danger-darken modal-bottom d-flex align-items-center px-12 mt-16"
				>
					<div class="m-auto">
						{{ errorMsg }}
					</div>
				</div>
				<div
					v-else
					class="modal-bottom d-flex align-items-center px-20 py-10"
				>
					<div class="ml-auto">
						<button
							@click="onSave"
							:disabled="v$.$invalid"
							type="button"
							class="btn btn-success save"
							data-qa="payeeModal_button_save"
						>
							Save
						</button>
					</div>
				</div>
				<div id="service-helper" />
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf, maxLength, isBankCode, isAccountNumber, withMessage } from '@commonServices/utils/validators';
import claimService from '@commonServices/claimService';
import NewPayeeInput from '@commonServices/models/NewPayeeInput';
import NewRecipientInput from '@commonServices/models/NewRecipientInput';
import { ValidationMessages } from '@commonServices/utils/constants';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	data () {
		return {
			paymentMethod: undefined,
			payee: undefined,
			sortCode: undefined,
			accountNumber: undefined,
			bankName: undefined,
			errorMsg: undefined,
		};
	},
	props: {
		paymentTypes: {
			type: Array,
			required: true,
		},
		recipient: {
			type: Object,
			required: true,
		},
		modalName: {
			type: String,
			required: false,
			default: 'new-payee',
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	mounted () {
		if (this.paymentTypes.length === 1) {
			this.paymentMethod = this.paymentTypes[0];
		}
	},
	validations () {
		return {
			payee: {
				required: withMessage(ValidationMessages.required, requiredIf(() => this.paymentMethod)),
				maxLength: withMessage(ValidationMessages.maxLength(100), maxLength(100)),
			},
			sortCode: {
				required: withMessage(ValidationMessages.required, requiredIf(() => this.paymentMethod && this.paymentMethod.value === 2)),
				isBankCode: withMessage(this.uiLocaleSettings.bankCodeErrorMessage, isBankCode(this.locale)),
			},
			accountNumber: {
				required: withMessage(ValidationMessages.required, requiredIf(() => this.paymentMethod && this.paymentMethod.value === 2)),
				isAccountNumber: withMessage(this.uiLocaleSettings.accountNumberErrorMessage, isAccountNumber(this.locale)),
			},
		};
	},
	computed: {
		...mapState(['appSettings']),
		payeeDetails () {
			return this.paymentMethod + this.payee + this.accountNumber + this.sortCode;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	watch: {
		payeeDetails: function (value) {
			this.errorMsg = undefined;
		},
	},
	methods: {
		onSave () {
			const payeeModel = new NewPayeeInput(
				this.paymentMethod.value,
				this.payee,
				this.sortCode,
				this.accountNumber,
				this.bankName,
				this.locale,
				true,
			);
			const recipientModel = new NewRecipientInput(
				payeeModel.sortCode,
				payeeModel.accountNumber,
				payeeModel.bankName,
				payeeModel.payee,
				this.paymentMethod.value,
				this.recipient.recipientType,
				this.recipient.recipientId,
				this.locale,
			);

			if (payeeModel.paymentMethod === 1) {
				this.createPaymentAccount(payeeModel, recipientModel);
			} else {
				if (this.appSettings.externalBankValidationEnabled) {
					claimService.checkBankAccountSortCode(payeeModel)
						.then(data => {
							if (data.isValid) {
								payeeModel.bankName = data.account.bankName;
								recipientModel.bankName = data.account.bankName;
								this.createPaymentAccount(payeeModel, recipientModel);
							} else {
								this.errorMsg = data.errorMessage ? data.errorMessage : 'Bank account information is invalid';
							}
						});
				} else {
					this.createPaymentAccount(payeeModel, recipientModel);
				}
			}
		},
		onPaymentChanged (value) {
			if (this.paymentMethod?.value === 1) {
				this.accountNumber = undefined;
				this.sortCode = undefined;
			}
		},
		createPaymentAccount (payeeModel, recipientModel) {
			claimService.createPaymentAccount(recipientModel)
				.then(data => {
					payeeModel.id = data.value;
					payeeModel.accountNumber = data.accountNumber;
					this.$emit('added-payee', payeeModel, `${this.recipient.recipientType}${this.recipient.recipientId}`);
				})
				.catch((err) => {
					this.errorMsg = err.response.data.message;
				});
		},
	},
};
</script>
