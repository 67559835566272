<template>
	<div
		data-qa="parentClaimsTableCard_card_parentClaimsInfo"
		class="parent-claims-table-wrapper"
	>
		<div class="parent-claims-table-container">
			<div class="parent-claims-table-label">
				{{ label }}
			</div>
			<el-table
				empty-text="There are no Claim groups to select"
				:data="parentClaims"
				style="width: 100%;"
				class="w-100"
				:class="areMarkersNeeded ? 'with-markers' : ''"
				:row-class-name="tableRowClassName"
				cell-class-name="py-10"
			>
				<el-table-column
					label="ID"
					width="80"
				>
					<template #default="{ row }">
						<div
							v-if="row.isPartialMatch || row.automation"
							class="info-icon-container"
						>
							<VTooltip
								v-if="row.isPartialMatch"
								placement="right"
								class="info-icon-popover"
								:popper-class="`info-icon-popover-inner ${getMatchClass(row)} tooltip-arrow-hidden` "
								:distance="-5"
								:delay="{ show: 0 }"
							>
								<span :class="`fa-solid aqv-info-circle info-icon ${getMatchClass(row)}`" />
								<template #popper>
									<div class="fs-13 text-white">
										{{ row.matches.filter(Boolean).join(', ') }}
									</div>
								</template>
							</VTooltip>
							<VTooltip
								v-if="row.automation"
								placement="right"
								class="info-icon-popover"
								:class="{ 'ml-5': row.isPartialMatch }"
								popper-class="info-icon-popover-inner tooltip-arrow-hidden"
								:distance="0"
								:arrow-padding="0"
							>
								<ClaimBotRequestIcon class="card-icon circle-badge" />
								<template #popper>
									<div class="fs-13 text-white">
										ClaimBot suggestion
									</div>
								</template>
							</VTooltip>
						</div>
						<div>{{ row.claimId | claimIdFormat }}</div>
					</template>
				</el-table-column>
				<el-table-column
					:label="dateOfLossLabel"
					width="110"
				>
					<template #default="{ row }">
						<div>
							{{ row.dateOfLoss | shortDate }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Diagnosis"
					min-width="130"
				>
					<template #default="{ row }">
						<truncated-text-cell :value="row.ailment.firstCause" />
					</template>
				</el-table-column>
				<el-table-column
					label="Treatment Start"
					width="110"
				>
					<template #default="{ row }">
						<div>
							{{ row.treatmentStart | shortDate }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Treatment End"
					width="110"
				>
					<template #default="{ row }">
						<div>
							{{ row.treatmentEnd | shortDate }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Body Part"
					width="110"
				>
					<template #default="{ row }">
						<truncated-text-cell :value="row.bodyPart?.description || 'N/A'" />
					</template>
				</el-table-column>
				<el-table-column width="40">
					<template #default="{ row }">
						<pre-authorisation-icon
							:form-type="row.formType"
						/>
					</template>
				</el-table-column>
				<el-table-column width="150">
					<template #default="{ row }">
						<button
							data-qa="parentClaimsTableCard_button_selectParent"
							type="button"
							:class="['btn w-100', selectedClaimConditionId === row.conditionId ? 'btn-warning darken' : 'btn-primary' ]"
							:disabled="canBeParent(row)"
							@click="onSelectClaim(row)"
						>
							<div
								v-tooltip="errorMessage(row)"
							>
								{{ selectedClaimConditionId === row.conditionId ? 'Selected' : 'Select' }}
							</div>
						</button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PreAuthorisationIcon from '@commonView/Shared/PreAuthorisationIcon';
import { ClaimBotRequestIcon } from '@assets/icons';

export default {
	name: 'ParentClaimsTableCard',
	components: {
		PreAuthorisationIcon,
		ClaimBotRequestIcon,
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
		dateOfLoss: {
			type: Date,
			required: false,
			default: null,
		},
		parentClaims: {
			type: Array,
			required: false,
			default: () => [],
		},
		claimCondition: {
			type: Object,
			required: true,
		},
		selectedClaimConditionId: {
			type: Number,
			required: false,
			default: null,
		},
		markedParentConditionIds: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	methods: {
		onSelectClaim (claimCondition) {
			this.$emit('claim-selected', claimCondition);
		},
		tableRowClassName (rowObject) {
			return `list-item--bg ${this.getMatchClass(rowObject.row)}`;
		},
		getMatchClass (claim) {
			if (claim.isFullMatch) {
				return 'full-match';
			} else if (claim.isPartialMatch) {
				return 'partial-match';
			}

			return '';
		},
		canBeParent (claimCondition) {
			return this.multicondition
			&& !claimCondition.canBeParent
			&& this.markedParentConditionIds.some(conditionId => conditionId === claimCondition.conditionId);
		},
		errorMessage (claimCondition) {
			if (claimCondition.canBeParent) {
				return '';
			}

			if (this.multicondition && this.markedParentConditionIds.some(conditionId => conditionId === claimCondition.conditionId)) {
				return 'A condition in this claim is already marked as the parent condition';
			}

			return 'Current condition will become the parent condition';
		},
	},
	computed: {
		...mapGetters(['dateOfLossLabel']),
		areMarkersNeeded () {
			return this.parentClaims.some(claim => claim.isPartialMatch);
		},
	},
};
</script>

<style lang="scss" scoped>
.parent-claims-table-wrapper {
  padding-top: 20px;

  .parent-claims-table-container {
    border: 2px solid var(--attributeBg);
    border-radius: 3px;
    position: relative;
    padding: 15px 15px 5px;
  }

  .parent-claims-table-label {
    background-color: var(--attributeBg);
    margin-left: 3px;
    padding: 3px 5px;
    border-radius: 3px;
    width: fit-content;
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .info-icon-container {
    position: absolute;
    top: -10px;
    left: -10px;
    height: 24px;

    .info-icon-popover {
      display: inline-block;
    }

    .info-icon {
      background: var(--contentBg);
      border-radius: 50%;
      font-size: 24px;
      height: 24px;
      width: 24px;
    }
  }

  .circle-badge {
    background: var(--contentBg);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
  }

  ::v-deep .with-markers .el-table__body {
    border-spacing: 0 10px + 3px;
  }
}
</style>

<style lang="scss">

.info-icon-popover-inner {
  .v-popper__inner {
    border: none;
    border-radius: 0;
    padding: 0 5px 0 7px;
  }
}

.full-match {
  &.el-table__row {
    box-shadow: 0 3px 0 0 var(--infoColor);
  }

  &.info-icon {
    color: var(--infoColor);
  }

  .info-icon:hover {
    color: #03aca0;
  }

  &.info-icon-popover-inner {
    .v-popper__inner {
      background: #03aca0;
    }
  }
}

.partial-match {
  &.el-table__row {
    box-shadow: 0 3px 0 0 var(--warningColor);
  }

  &.info-icon {
    color: var(--warningColor);
  }

  .info-icon:hover {
    color: #d5a80f;
  }

  &.info-icon-popover-inner {
    .v-popper__inner {
      background: #d5a80f;
    }
  }
}
</style>
