<template>
	<div class="container-fluid">
		<aq-list-navigation-control
			:list="items"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				:data="items"
				fit
				cell-class-name="text-left"
				empty-text="There is no data to display "
				data-qa="claimStatus_table_reports"
				:key="currentColumnPage"
			>
				<paged-columns
					:current-column-page="currentColumnPage"
					:columns-per-page="columnsPerPage"
				>
					<el-table-column
						type="index"
						:index="getIndex"
						width="65"
					/>
					<el-table-column
						prop="brandName"
						label="Brand"
					/>
					<el-table-column
						prop="customerId"
						label="Customer Ref"
						width="100"
					/>
					<el-table-column label="Policy Ref">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.policyReference" />
						</template>
					</el-table-column>
					<el-table-column
						label="Claim Ref"
						width="100"
					>
						<template #default="scope">
							<div>{{ scope.row.claimId | claimIdFormat }}</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Customer Full Name"
						width="140"
					>
						<template #default="scope">
							<router-link
								:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
								class="d-inline-block"
							>
								<aq-icon-avatar
									class="small mr-auto cursor-pointer"
									:value="[scope.row.customerFirstName, scope.row.customerLastName]"
								/>
							</router-link>
						</template>
					</el-table-column>
					<el-table-column label="Pet Name">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.petName" />
						</template>
					</el-table-column>
					<el-table-column
						prop="petType"
						label="Species"
						width="100"
					/>
					<el-table-column label="Breed">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.petBreed" />
						</template>
					</el-table-column>

					<el-table-column label="Ailment">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.ailments" />
						</template>
					</el-table-column>
					<el-table-column
						label="Condition"
						width="130"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.conditionTypes" />
						</template>
					</el-table-column>
					<el-table-column
						prop="amountSubmitted"
						label="Amount Submitted"
						width="120"
					/>
					<el-table-column
						label="Fast Track Reviewed"
						width="170"
					>
						<template #default="scope">
							<boolean-cell :value="scope.row.isFastTrackReviewed" />
						</template>
					</el-table-column>
					<el-table-column
						label="Fast Tracked Reason"
						width="170"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.fastTrackReason" />
						</template>
					</el-table-column>
					<el-table-column
						label="Claim Created By"
						width="140"
					>
						<template #default="scope">
							<aq-icon-avatar
								class="small mr-auto"
								:value="[scope.row.claimCreatedBy.firstName, scope.row.claimCreatedBy.lastName]"
							/>
						</template>
					</el-table-column>
					<el-table-column
						prop="claimCreatedDate"
						label="Claim Created Date"
						width="130"
					/>
					<el-table-column
						prop="claimFormUploadedDate"
						label="Claim Form Uploaded Date"
						width="190"
					/>
					<el-table-column
						prop="dateOnSet"
						label="Date Onset"
						width="140"
					/>
					<el-table-column
						prop="dateTreatmentStart"
						label="Date Treatment Start"
						width="140"
					/>
					<el-table-column label="Last Interaction">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.lastInteraction" />
						</template>
					</el-table-column>
					<el-table-column
						prop="lastInteractionDate"
						label="Last Interaction Date"
						width="160"
					/>
					<el-table-column
						label="Last Interaction User"
						width="200"
					>
						<template #default="scope">
							<aq-icon-avatar
								class="small mr-auto"
								v-if="scope.row.lastInteractionBy"
								:value="[scope.row.lastInteractionBy.firstName, scope.row.lastInteractionBy.lastName]"
							/>
							<template v-else>
								No User
							</template>
						</template>
					</el-table-column>
					<el-table-column
						label="Assigned To"
						width="200"
					>
						<template #default="scope">
							<aq-icon-avatar
								class="small mr-auto"
								v-if="scope.row.assignedToUser"
								:value="[scope.row.assignedToUser.firstName, scope.row.assignedToUser.lastName]"
							/>
							<template v-else>
								Unassigned
							</template>
						</template>
					</el-table-column>
					<el-table-column
						label="Rejection Overridden By"
						width="200"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.rejectionOverriddenBy"
								class="small mr-auto"
								:value="[scope.row.rejectionOverriddenBy.firstName, scope.row.rejectionOverriddenBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="claimStatus"
						label="Claim Status"
					/>
					<el-table-column
						label="Assessed By"
						width="200"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.claimAssessedBy"
								class="small mr-auto"
								:value="[scope.row.claimAssessedBy.firstName, scope.row.claimAssessedBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Closed By"
						width="200"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.claimClosedBy"
								class="small mr-auto"
								:value="[scope.row.claimClosedBy.firstName, scope.row.claimClosedBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="claimClosedDate"
						label="Closed Date"
						width="200"
					/>
					<el-table-column
						prop="claimFormType"
						label="Form Type"
						width="200"
					/>
					<el-table-column
						prop="daysOpenClosed"
						label="Days Open/Closed"
						width="200"
					/>
				</paged-columns>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';

export default {
	mixins: [rowHighlightMixin],
	props: {
		items: {
			type: Array,
			required: true,
		},
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
	},
	methods: {
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
	},
};
</script>
