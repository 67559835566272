<template>
	<div class="row no-gutters">
		<div class="col-7 pr-18">
			<aq-select
				:options="conditionDeductionTypes"
				label="Deduction Type"
				option-label="description"
				track-by="id"
				v-model="v$.deductionItem.type.$model"
				:is-valid="!v$.deductionItem.type.$error"
				no-result-message="No Deduction Type found"
				data-qa="claimItemForm_select_deductionType"
			/>
			<aq-form-input-error
				class="error mt-4"
				:validator="v$.deductionItem.type"
			/>
		</div>
		<div class="col-4 pl-18">
			<aq-calc-input
				label="Amount"
				icon-class="text-primary fa-lg"
				:locale="locale"
				:value="deductionItem.amount"
				:value-expression="deductionItem.amountExpression"
				:is-valid="!v$.deductionItem.amount.$error && !v$.deductionItem.amountExpression.$error"
				@input="onDeductionAmountUpdated($event)"
				:disabled="isReadOnlyMode"
				data-qa="claimItemForm_input_deductionAmount"
			/>
			<aq-form-input-error
				class="error mt-4 position-relative"
				:validator="v$.deductionItem.amount"
			/>
			<aq-form-input-error
				class="error mt-4 position-relative"
				:validator="v$.deductionItem.amountExpression"
			/>
		</div>
		<div class="col-1 pl-6 align-self-start mt-16">
			<button
				class="d-block btn small btn-secondary ml-auto"
				type="button"
				@click="$emit('remove')"
				data-qa="claimItemForm_button_removeDeduction"
			>
				<i class="fas fa-times" />
			</button>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { withMessage, required, minValue, maxAmount, isMathExpression } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	props: {
		deduction: {
			type: Object,
			required: true,
		},
		conditionDeductionTypes: {
			type: Array,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			deductionItem: this.deduction,
		};
	},
	validations () {
		return {
			deductionItem: {
				type: {
					required: withMessage(ValidationMessages.required, required),
				},
				amount: {
					required: withMessage(ValidationMessages.required, required),
					minValue: withMessage(ValidationMessages.noNegative, minValue(0)),
					maxAmount: withMessage(ValidationMessages.maxAmount, maxAmount(16)),
				},
				amountExpression: {
					isMathExpression: withMessage(ValidationMessages.invalidExpression, isMathExpression),
				},
			},
		};
	},
	methods: {
		onDeductionAmountUpdated (data) {
			this.deductionItem.amount = data.value;
			this.deductionItem.amountExpression = data.valueExpression;
			this.v$.deductionItem.amount.$touch();
			this.v$.deductionItem.amountExpression.$touch();
		},
	},
};
</script>
