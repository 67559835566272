<template>
	<div>
		<div :class="[{ 'highlight-border': !!color }, color, border, { 'highlight-background': !!backgroundHighlight }, { 'add-shadow': shadow }]">
			<slot />
			<VTooltip
				v-if="color"
				placement="top"
				class="highlight-icon"
				:class="size"
				:popper-class="tooltipClass"
			>
				<i :class="['fas', icon, color, {'icon-circle': iconCircle}]" />
				<template #popper>
					<slot name="tooltipContent" />
				</template>
			</VTooltip>
		</div>
	</div>
</template>
<script>

export default {
	name: 'AqHighlightBorderWithIcon',
	props: {
		highlightColor: {
			type: String,
			required: false,
			default: null,
		},
		backgroundHighlight: {
			type: Boolean,
			required: false,
			default: false,
		},
		icon: {
			type: String,
			required: false,
			default: null,
		},
		iconCircle: {
			type: Boolean,
			required: false,
			default: false,
		},
		iconSize: {
			type: String,
			required: false,
			default: null,
		},
		borderStyle: {
			type: String,
			required: false,
			default: null,
		},
		shadow: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		tooltipClass () {
			return 'tooltip-' + (this.color === 'danger' ? 'error' : this.color);
		},
		color () {
			return ['danger', 'warning', 'success', 'info'].find(color => color === this.highlightColor);
		},
		iconClass () {
			return ['aqv-circle-thumbs-up', 'fa-exclamation-triangle', 'fa-exclamation'].find(icon => icon === this.icon);
		},
		size () {
			return ['small'].find(size => size === this.iconSize);
		},
		border () {
			return ['bold'].find(border => border === this.borderStyle);
		},
	},
};
</script>

<style lang="scss" scoped>
.highlight-border {
  border-radius: 5px;
  border: 2px solid;
  position: relative;
  margin: -2px;

  &.bold {
    border-width: 3px;
    margin: -3px;
  }

  &.add-shadow {
    box-shadow: 0 3px 12px -4px var(--listItemsPopupShadow);
  }

  &.warning {
    border-color: var(--backgroundWarning);
  }

  &.danger {
    border-color: var(--backgroundDanger);
  }

  &.success {
    border-color: var(--backgroundSuccess);
  }

  &.info {
    border-color: var(--infoColor);
  }

  .highlight-icon {
    font-size: 30px;
    position: absolute;
    right: -10px;
    top: -28px;

    .warning {
      color: var(--backgroundWarning);
    }

    .danger {
      color: var(--backgroundDanger);
    }

    .success {
      color: var(--backgroundSuccess)
    }

    .info {
      color: var(--infoColor);
    }

    .icon-circle {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      padding: 6px;
      color: white;
      font-size: 22px;
      display: flex;
      justify-content: center;

      &.warning {
        background-color: var(--backgroundWarning);
      }

      &.danger {
        background-color: var(--backgroundDanger);
      }

      &.success {
        background-color: var(--backgroundSuccess);
      }

      &.info {
        background-color: var(--infoColor);
      }
    }

    &.small {
      font-size: 24px;
      top: -24px;

      .icon-circle {
        width: 30px;
        height: 30px;
        font-size: 16px;
      }
    }
  }
}
</style>

<style lang="scss">
  @mixin background-highlight($backgroundColor, $accentColor) {
    .control-container {
      background-color: $backgroundColor;

      .multiselect__select::before { // dropdown arrow
        border-color: $accentColor transparent transparent;
      }

      .label, .label.text-primary, // labels
      .calendar-icon .fa-calendar-alt { // date picker calendar icon
        &:not(.invalid) {
          color: $accentColor !important;
        }
      }
    }
  }

  .highlight-border.highlight-background {
    &.warning {
      @include background-highlight(var(--warningBackgroundHighlight), var(--warningHighlightAccent));
    }

    &.danger {
      @include background-highlight(var(--dangerBackgroundHighlight), var(--dangerHighlightAccent));
    }

    &.success {
      @include background-highlight(var(--successBackgroundHighlight), var(--successHighlightAccent));
    }

    &.info {
      @include background-highlight(var(--infoBackgroundHighlight), var(--infoHighlightAccent));
    }
  }

</style>
