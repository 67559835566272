<template>
	<aq-sandwich
		ref="sandwich"
		header="Policy Details"
		sub-header="Validate extracted data to verify policy details"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
		is-active-on-start
	>
		<template #content>
			<div class="section-content flex-fill">
				<div class="container-fluid">
					<aq-scan-form-input
						class="mb-16"
						v-model="v$.customerName.value.$model"
						label="Customer Name"
						:is-valid="!v$.customerName.$error"
						:warning-messages="v$.customerName.$errors"
						:disabled="isReadonly"
						@input="customerName.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.customerName)"
					/>
					<aq-scan-form-input
						class="mb-10"
						v-model="v$.petName.value.$model"
						label="Pet Name"
						:is-valid="!v$.petName.$error"
						:warning-messages="v$.petName.$errors"
						:disabled="isReadonly"
						@input="petName.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.petName)"
					/>
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<button
						class="btn btn-primary btn-radius--less mr-0 ml-auto d-block"
						@click="onProceed()"
					>
						Proceed
					</button>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { withMessage, sameAs } from '@clientCommon/services/utils/validators';
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
	name: 'PolicyInformationScan',
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	data () {
		return {
			customerName: this.customerNameData,
			petName: this.petNameData,
		};
	},
	props: {
		customerNameData: {
			type: Object,
			required: true,
		},
		petNameData: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
		recognizedData: {
			type: Object,
			required: true,
		},
	},
	mounted () {
		this.v$.$touch();
	},
	computed: {
		...mapState(['interactionContext']),
		existingCustomerName () {
			return `${this.interactionContext.customer.firstName} ${this.interactionContext.customer.lastName}`;
		},
		existingPetName () {
			return this.interactionContext.pet.name;
		},
	},
	validations () {
		return {
			customerName: {
				value: {
					sameAs: withMessage(`Customer name does not match existing: ${this.existingCustomerName}`, sameAs(this.existingCustomerName)),
				},
			},
			petName: {
				value: {
					sameAs: withMessage(`Pet name does not match existing: ${this.existingPetName}`, sameAs(this.existingPetName)),
				},
			},
		};
	},
	methods: {
		async onProceed () {
			this.$refs.sandwich.onToggle();
			this.$emit('passed', cloneDeep({ customerName: this.customerName, petName: this.petName }));
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		highlight (field) {
			const { page, boundingBox } = field;
			this.$emit('focused', { page, boundingBox });
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-danger-darken {
  color: $body-color;
}

.search-item {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease;

  &:last-child {
    border: none;
  }

  &:hover,
  &.selected {
    p {
      color: $body-color;
    }
  }

  &:hover {
    background: $primary;
  }

  &.selected {
    background: $active-color;
  }

  &.create-new {
    &:hover {
      color: $body-color;
      background: $primary-d-15;
    }
  }
}
</style>
