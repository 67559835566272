<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="`Reject ${rejectionTarget}`"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-12 pb-12">
								Please select a reason to reject this {{ rejectionTarget.toLowerCase() }}
							</div>
							<div class="col-12 pb-12">
								<aq-select
									label="Select Reasons"
									:options="rejectionReasons"
									option-label="description"
									v-model="v$.selectedReason.$model"
									no-result-message="No Reason found"
									:is-valid="!v$.selectedReason.$error"
								>
									<template #default="{ option }">
										<div class="px-10">
											<div class="select-option-header text-truncate">
												{{ option.description }}
											</div>
										</div>
									</template>
								</aq-select>
								<aq-form-input-error
									class="error mt-8"
									:validator="v$.selectedReason"
								/>
							</div>
							<div
								v-if="selectedReason"
								class="col-12 pb-12"
							>
								<aq-form-input
									label="Comment"
									type="textarea"
									v-model="v$.comment.$model"
									:is-valid="!v$.comment.$error"
								/>
								<aq-form-input-error
									class="error mt-8"
									:validator="v$.comment"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onContinue"
						:disabled="v$.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Continue
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { withMessage, required, maxLength } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	props: {
		rejectionContext: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			selectedReason: null,
			comment: null,
			rejectionReasons: [],
		};
	},
	mounted () {
		const { rejectionData, claimId, ...restParams } = this.rejectionContext;
		Promise.all(
			[rejectionData == null ? ClaimService.getClaimRejectionReason(claimId) : Promise.resolve(rejectionData), // current rejection data
				ClaimService.getClaimRejectionReasons(claimId, restParams), // load available rejection reasons based on rejection context
			]).then(([claimRejectionData, rejectionReasons]) => {
			this.rejectionReasons = rejectionReasons;
			if (claimRejectionData) {
				this.selectedReason = this.rejectionReasons.find(reason => reason.id === claimRejectionData.rejectionReasonId);
				this.comment = claimRejectionData.comment;
			}
		});
	},
	computed: {
		rejectionTarget () {
			return this.rejectionContext.rejectionData == null ? 'Claim' : 'Condition';
		},
	},
	validations: {
		selectedReason: {
			required: withMessage('The reason should be selected', required),
		},
		comment: {
			maxLength: withMessage(ValidationMessages.maxLength2000, maxLength(2000)),
		},
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
			this.$emit('reject-cancel');
		},
		onContinue () {
			this.$modal.hide(this.$parent.name);
			this.$emit('reject', { reasonId: this.selectedReason.id, description: this.selectedReason.description, comment: this.comment });
		},
	},
};
</script>
