<template>
	<div
		class="invoice-line-item"
		:class="{ 'line-item-edit': isEditing, 'line-item-create': isNew , disabled: !included }"
	>
		<div
			v-if="showRow"
			@click="highlightRow"
			class="row no-gutters standard-row px-5 py-8 align-items-center"
			:class="[isItemInvalid && 'bg-danger-darken',
				isDuplicate && 'duplicate-row',
				item.parentId && 'child-row']"
		>
			<div
				class="d-flex align-items-center"
				:class="gridSizes[0]"
			>
				<div v-tooltip="isExcludedMultiEdit ? 'Excluded from multi edit' : ''">
					<aq-checkbox
						v-if="isEditMultiMode"
						class="round large thin"
						v-model="multiEditIncluded"
						:disabled="isReadOnlyMode || isExcludedMultiEdit"
					/>
					<aq-checkbox
						v-else
						class="round large thin"
						v-model="included"
						:disabled="isReadOnlyMode"
					/>
				</div>
				<div
					class="pl-10 text-truncate"
					:class="{'text-danger': included && v$.item.date.$error }"
				>
					<span
						class="error pr-4"
						v-if="included && v$.item.date.value.$error"
					>
						<i
							class="fas fa-exclamation-circle"
							v-for="error of v$.item.date.value.$errors"
							:key="error.$uid"
							v-tooltip="error.$message"
						/>
					</span>
					<span v-tooltip="item.date.value">
						{{ item.date.value }}
					</span>
				</div>
			</div>
			<div
				class="text-truncate pr-10"
				:class="[included && v$.item.description.$error && 'text-danger', gridSizes[1]]"
			>
				<invoice-line-type-icon
					:selected-type-id="item.itemType.value"
					@line-type-selected="onLineTypeSelected"
					:invalid="isItemInvalid"
					:disabled="isEditing || isReadOnlyMode || isEditMultiMode"
					:excluded="!included"
				/>
				<span
					class="px-2"
					v-if="included && v$.item.description.value.$error"
				>
					<i
						class="fas fa-exclamation-circle"
						v-for="error of v$.item.description.value.$errors"
						:key="error.$uid"
						v-tooltip="error.$message"
					/>
				</span>
				<span v-tooltip="item.quantity.value + ' x ' + item.description.value">
					{{ item.quantity.value }} x {{ item.description.value }}
				</span>
			</div>
			<div
				class="text-truncate"
				:class="[included && v$.item.discountAmount.$error && 'text-danger', gridSizes[2]]"
			>
				<span
					class="error pr-4"
					v-if="included && v$.item.discountAmount.value.$error"
				>
					<i
						class="fas fa-exclamation-circle"
						v-for="error of v$.item.discountAmount.value.$errors"
						:key="error.$uid"
						v-tooltip="error.$message"
					/>
				</span>
				<span
					v-if="preAppliedDiscount"
					class="pre-applied-discount"
					:class="preAppliedDiscount.discount > item.amount.value ? 'invalid' : ''"
				>
					{{ preAppliedDiscount.discount | currency(locale) }}
				</span>
				<span v-else-if="item.discountAmount">
					{{ item.discountAmount.value | currency(locale) }}
				</span>
			</div>
			<div
				class="text-truncate"
				:class="[included && v$.item.tax.$error && 'text-danger', gridSizes[3]]"
			>
				<span
					class="error pr-4"
					v-if="included && v$.item.tax.value.$error"
				>
					<i
						class="fas fa-exclamation-circle"
						v-for="error of v$.item.tax.value.$errors"
						:key="error.$uid"
						v-tooltip="error.$message"
					/>
				</span>
				<span>
					{{ item.tax.value | currency(locale) }}
				</span>
			</div>
			<div
				class="d-flex"
				:class="[included && v$.item.amount.$error && 'text-danger', gridSizes[4]]"
			>
				<div
					class="error pr-4"
					v-if="included && v$.item.amount.value.$error"
				>
					<i
						class="fas fa-exclamation-circle"
						v-for="error of v$.item.amount.value.$errors"
						:key="error.$uid"
						v-tooltip="error.$message"
					/>
				</div>
				<div class="text-truncate">
					{{ item.amount.value | currency(locale) }}
				</div>
				<div
					class="circle-15 mx-4"
					v-if="item.deductions?.length > 0"
					v-tooltip="item.deductions.map((deduction) => deduction.value.value).join(', ')"
				>
					D
				</div>
			</div>
			<div
				v-if="multicondition"
				class="text-truncate pl-5"
				:class="[included && v$.item.claimCondition.$error && 'text-danger', gridSizes[5]]"
			>
				<span
					class="error pr-4"
					v-if="included && v$.item.claimCondition.value.$error"
				>
					<i
						class="fas fa-exclamation-circle"
						v-for="error of v$.item.claimCondition.value.$errors"
						:key="error.$uid"
						v-tooltip="error.$message"
					/>
				</span>
				<span v-tooltip="item.claimCondition.value ? item.claimCondition.value.description : ''">
					{{ item.claimCondition.value ? item.claimCondition.value.description : '' }}
				</span>
			</div>
			<div
				class="text-truncate pl-5"
				:class="[included && v$.item.policySection.$error && 'text-danger', gridSizes[6]]"
			>
				<span
					class="error pr-4"
					v-if="included && v$.item.policySection.value.$error"
				>
					<i
						class="fas fa-exclamation-circle"
						v-for="error of v$.item.policySection.value.$errors"
						:key="error.$uid"
						v-tooltip="error.$message"
					/>
				</span>
				<span v-tooltip="item.policySection.value ? item.policySection.value.description : ''">
					{{ item.policySection.value ? item.policySection.value.description : '' }}
				</span>
			</div>

			<div
				class="d-flex align-items-center justify-content-end"
				:class="gridSizes[7]"
				v-if="!isReadOnlyMode && !isEditMultiMode"
			>
				<aq-icon-avatar
					v-if="lastUser"
					class="small d-inline-flex mr-5"
					:value="[lastUser.firstName,lastUser.lastName]"
				/>
				<div
					v-if="canMakeCopy(item)"
					class="circle original mr-5"
					:class="[ isEditing ? 'disabled' : 'original']"
					@click="onMakeCopy"
					v-tooltip="'Make copy'"
				>
					<i class="aqv-make-copy" />
				</div>
				<div
					class="circle mr-5"
					:class="[ isEditing || lastUser ? 'edited' : 'original']"
					@click="onEdit"
					v-tooltip="'Edit'"
				>
					<i class="fas fa-pencil-alt" />
				</div>

				<div
					v-if="item.createdByUser.value"
					class="circle danger"
					@click="onDelete"
				>
					<i class="aqv-delete" />
				</div>
			</div>
		</div>
		<div
			class="section-content py-15"
			v-if="isEditing"
		>
			<invoice-scan-edit
				:invoice-scan-data="item"
				:deductions="deductions"
				:policy-sections="policySections"
				:conditions="conditions"
				@cancel="onCancel"
				@finish="onFinish"
				@focused="onFocus"
				:locale="locale"
				:multicondition="multicondition"
			/>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { decimal, maxLength, required, minValue, bypass, isDate, moreThen, withMessage } from '@commonServices/utils/validators';
import { DateFormats } from '@commonServices/settings/localeSettings';
import { shortDate, currency } from '@commonServices/utils/filters';
import { ValidationMessages } from '@commonServices/utils/constants';
import InvoiceLineTypeIcon from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceLineItem/InvoiceLineTypeIcon';
import cloneDeep from 'lodash.clonedeep';

const threshold = 0.004;
const stdMultiplier = 2.5;

export default {
	inject: ['setScannedItemCoordinates'],
	components: { InvoiceLineTypeIcon },
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	data () {
		return {
			item: {
				date: null,
				description: null,
				amount: null,
				quantity: null,
				claimCondition: null,
				policySection: null,
				deductions: null,
				editedByUser: null,
				discountAmount: null,
				discountPercent: null,
				tax: null,
				itemType: null,
				id: 0,
				parentId: null,
			},
			isEditing: false,
		};
	},
	props: {
		dataItem: {
			type: Object,
			required: true,
		},
		deductions: {
			type: Array,
			required: true,
		},
		policySections: {
			type: Array,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		selected: {
			type: Boolean,
		},
		isNew: {
			type: Boolean,
			required: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		gridSizes: {
			type: Array,
			required: true,
		},
		isEditMultiMode: {
			type: Boolean,
			default: false,
		},
		isSelectedMultiEdit: {
			type: Boolean,
			default: false,
		},
		isExcludedMultiEdit: {
			type: Boolean,
			default: false,
		},
		preAppliedDiscount: {
			type: Object,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			default: false,
		},
		isDuplicate: {
			type: Boolean,
			default: false,
		},
	},
	validations () {
		const moreThanZero = this.isNonFinancialLimitPolicySection(this.item.policySection.value) ? moreThen(0) : minValue(0);
		const requiredNonFinancialNumber = this.isNonFinancialLimitPolicySection(this.item.policySection.value) ? required : bypass;
		return {
			item: {
				date: {
					value: {
						isDate: withMessage(`Please enter a date as ${DateFormats.ShortDate}`, isDate),
					},
				},
				description: {
					value: {
						maxLength: withMessage(ValidationMessages.maxLength2000, maxLength(2000)),
					},
				},
				amount: {
					value: {
						decimal: withMessage('Please enter amount as decimal (e.g. 100.00)', decimal),
					},
				},
				policySection: {
					value: {
						required: withMessage(ValidationMessages.required, required),
					},
				},
				claimCondition: {
					value: {
						required: withMessage(ValidationMessages.required, required),
					},
				},
				nonFinancialNumber: {
					value: {
						required: withMessage(ValidationMessages.required, requiredNonFinancialNumber),
						minValue: withMessage(ValidationMessages.noNegative, moreThanZero),
					},
				},
				discountAmount: {
					value: {
						decimal: withMessage('Please enter discount amount as decimal (e.g. 100.00)', decimal),
					},
				},
				discountPercent: {
					value: {
						decimal: withMessage('Please enter discount percent as decimal (e.g. 100.00)', decimal),
					},
				},
				tax: {
					value: {
						decimal: withMessage('Please enter tax amount as decimal (e.g. 100.00)', decimal),
					},
				},
			},
		};
	},
	mounted () {
		this.v$.item.$touch();
		if (this.isNew) {
			this.onEdit();
		}
	},
	watch: {
		dataItem: {
			deep: true,
			immediate: true, // calls watch handler before mount
			handler (newVal) {
				this.item = cloneDeep(newVal);
			},
		},
	},
	computed: {
		showRow () {
			return !this.isEditing || !this.isNew;
		},
		isItemInvalid () {
			const isInvalid = this.included && (
				this.v$.item.date.$invalid
				|| this.v$.item.description.$invalid
				|| this.v$.item.amount.$invalid
				|| this.v$.item.claimCondition.$error
				|| this.v$.item.policySection.$invalid
				|| this.v$.item.nonFinancialNumber.$error
				|| this.v$.item.discountAmount.$error
				|| this.v$.item.discountPercent.$error
				|| this.v$.item.tax.$error
			);
			this.$emit('setValidity', { isInvalid });
			return isInvalid;
		},
		included: {
			get () {
				return !this.dataItem.excluded.value;
			},
			set (newVal) {
				this.$emit('toggle', newVal);
			},
		},
		multiEditIncluded: {
			get () {
				return this.isSelectedMultiEdit;
			},
			set (newVal) {
				this.$emit('multiEditToggle', newVal);
			},
		},
		lastUser () {
			return this.item.editedByUser.value || this.item.createdByUser.value || null;
		},
	},
	methods: {
		onEdit () {
			this.isEditing = true;
			this.$emit('startEdit');
		},
		onDelete () {
			this.$emit('delete');
		},
		onFinish (updatedValue) {
			updatedValue.date = shortDate(updatedValue.date);
			if (this.isUpdated(updatedValue)) {
				this.$emit('finish', updatedValue);
			}

			this.isEditing = false;
		},
		onCancel () {
			this.isEditing = false;
			this.$emit('cancel');
		},
		onMultipleTreatmentSelected () {
			this.$emit('multipleTreatmentSelected');
		},
		isUpdated (updatedValue) {
			const deductionsEqual = this.dataItem.deductions?.length === updatedValue.selectedUserDeductions.length
				&& this.dataItem.deductions.every((deduction, index) => deduction.value.id === updatedValue.selectedUserDeductions[index].id);

			const areEqual = this.dataItem.date.originalValue === updatedValue.date
				&& this.dataItem.quantity.originalValue === Number(updatedValue.quantity).toFixed(2)
				&& this.dataItem.description.originalValue === updatedValue.description
				&& this.dataItem.amount.originalValue === currency(updatedValue.amount, this.locale, true)
				&& this.dataItem.policySection.value?.id === updatedValue.selectedPolicySection.id
				&& this.dataItem.discountAmount.originalValue === currency(updatedValue.discountAmount, this.locale, true)
				&& this.dataItem.discountPercent.originalValue === Number(updatedValue.discountPercent).toFixed(2)
				&& this.dataItem.tax.originalValue === currency(updatedValue.tax, this.locale, true)
				&& this.dataItem.petName.originalValue === updatedValue.petName
				&& this.dataItem.itemType.value === updatedValue.itemType
				&& deductionsEqual;

			return !areEqual;
		},
		onFocus (propertyName) {
			const field = this.item[propertyName];
			this.setScannedItemCoordinates(field.boundingBox, field.page);
		},
		highlightRow () {
			const highlightArea = this.getBoundingBoxForTableRow();
			this.$emit('highlight', highlightArea);
		},
		getBoundingBoxForTableRow () {
			let fields = [this.item.date, this.dataItem.quantity, this.dataItem.description, this.item.amount, this.dataItem.petName].filter(({ boundingBox }) => boundingBox != null);
			let highlightPage = 1;

			if (fields.length > 2) {
				// filter out fields from other pages
				const fieldsPage = Object.entries(fields.reduce((acc, next) => { acc[next.page] = acc[next.page] ? acc[next.page] + 1 : 1; return acc; }, {}))
					.map(([page, fieldsCount]) => ({ page: parseInt(page), fieldsCount }))
					.sort((a, b) => b.fieldsCount - a.fieldsCount)[0];
				fields = fields.filter(({ page }) => fieldsPage.page === page);
				highlightPage = fieldsPage.page;

				// filter out bounding boxes by standard deviation of y coordinate values (for cases where e.g. date value is out of invoice line )
				const yCoordinates = fields.map(({ boundingBox }) => boundingBox[1]);
				const mean = calcMean(yCoordinates);
				let std = calcStd(yCoordinates);
				if (std < threshold) {
					std *= stdMultiplier;
				}
				const upperYBound = mean + std;
				const lowerYBound = mean - std;
				fields = fields.filter(({ boundingBox }) => boundingBox[1] >= lowerYBound && boundingBox[1] <= upperYBound);
			}
			if (fields.length) {
				const topLeft = fields.sort(({ boundingBox: a }, { boundingBox: b }) => topLeftVal(a) - topLeftVal(b))[0].boundingBox; // Top left: min(x+max(y)-y), where inner max(y) means maximum possible value, in our case 1
				const bottomRight = fields.sort(({ boundingBox: a }, { boundingBox: b }) => bottomRightVal(a) - bottomRightVal(b))[0].boundingBox; // Bottom right: min(max(x)-x+y), where inner max(x) means maximum possible value, in our case 1

				return {
					page: highlightPage || 1,
					boundingBox: bottomRight.length === 4 ? [topLeft[0], topLeft[1], bottomRight[2], bottomRight[3]] : [topLeft[0], topLeft[1], bottomRight[4], bottomRight[5]],
				};
			} else return { boundingBox: null };
		},
		isNonFinancialLimitPolicySection (policySection) {
			return policySection && this.policySections && this.policySections.find(ps => ps.id === policySection.id)?.hasNonFinancialLimit;
		},
		canMakeCopy (item) {
			return item.id && !item.parentId;
		},
		onMakeCopy () {
			if (!this.isEditing) {
				this.$emit('copy');
			}
		},
		onLineTypeSelected (lineTypeId) {
			this.item.itemType.value = lineTypeId;
			this.$emit('line-type-selected', lineTypeId);
		},
	},
};

// Top left: x+max(y)-y
const topLeftVal = (bb) => bb[0] + 1 - bb[1];

// Bottom right: max(x)-x+y
const bottomRightVal = (bb) => 1 - bb[2] + bb[3];

const sum = arr => arr.reduce((a, b) => a + b, 0);

const calcMean = arr => sum(arr) / arr.length;

// standard deviation
const calcStd = (arr) => {
	const mu = calcMean(arr);
	const diffArr = arr.map(a => (a - mu) ** 2);
	return Math.sqrt(sum(diffArr) / (arr.length));
};

</script>

<style lang="scss" scoped>
  .bg-danger-darken {
    color: $body-color;
  }

  .error {
    font-size: 14px;

    i {
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $body-color !important;
      }
    }
  }

  .line-item-edit {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100%;

    &.line-item-create {
      top: -20px;
      height: calc(100% + 50px);
    }
  }

  .circle-15 {
    height: 15px;
    width: 15px;
    line-height: 15px;
    border-radius: 50%;
    font-size: 13px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    min-width: 15px;
    background-color: $danger;
  }

  ::v-deep .bg-secondary--lighten {
    background-color: $body-bg;
  }

  .border {
    &-default {
      border: 1px solid transparent;
    }

    &-highlighted {
      border: 1px solid $active-color;
    }
  }

  .disabled {
    color: $light !important;

    .circle,
    .circle-15,
    .card-icon,
    .circle:hover {
      color: $light;
      background-color: transparent;
    }
  }

  .pre-applied-discount.invalid {
    font-weight: bold;
    color: var(--backgroundDanger);
  }

  .standard-row {
    background: rgba(var(--attributeBgRgb));

    &.child-row {
      background: rgba(var(--attributeBgRgb), 0.5);
    }
  }

  .duplicate-row {
    background: rgba(var(--warningBackgroundHighlight));

    &.child-row {
      background: rgba(var(--warningBackgroundHighlight), 0.5);
    }
  }

</style>
