<template>
	<div class="container-fluid">
		<aq-modal name="flags-data-modal">
			<flags-data-modal
				title="Fraud Flags"
				:data="selectedData"
				@close="onCloseModal"
			/>
		</aq-modal>
		<aq-list-navigation-control
			:list="items"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				:data="items"
				fit
				cell-class-name="text-left"
				empty-text="There is no data to display "
				data-qa="claimQualityAudit_table_reports"
				:key="currentColumnPage"
			>
				<paged-columns
					:current-column-page="currentColumnPage"
					:columns-per-page="columnsPerPage"
				>
					<el-table-column
						type="index"
						:index="getIndex"
						width="65"
					/>
					<el-table-column
						prop="brandName"
						label="Brand"
					/>
					<el-table-column
						prop="customerId"
						label="Customer ID"
						width="100"
					/>
					<el-table-column
						label="Customer Name"
						width="140"
					>
						<template #default="scope">
							<router-link
								:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
								class="d-inline-block"
							>
								<aq-icon-avatar
									class="small mr-auto cursor-pointer"
									:value="[scope.row.customerFirstName, scope.row.customerLastName]"
								/>
							</router-link>
						</template>
					</el-table-column>
					<el-table-column label="Policy Ref">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.policyReference" />
						</template>
					</el-table-column>
					<el-table-column
						prop="petId"
						label="Pet ID"
						width="100"
					/>
					<el-table-column label="Pet Name">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.petName" />
						</template>
					</el-table-column>
					<el-table-column
						label="Claim Ref"
						width="100"
					>
						<template #default="scope">
							<div>{{ scope.row.claimId | claimIdFormat }}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="claimCreatedDate"
						label="Created Date"
						width="200"
					/>
					<el-table-column
						prop="claimDateOfLoss"
						:label="dateOfLossLabel"
						width="130"
					/>
					<el-table-column
						label="Invoice ID"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.invoiceNumbers" />
						</template>
					</el-table-column>
					<el-table-column
						prop="claimAmountSubmitted"
						label="Amount Claimed"
						width="150"
					/>
					<el-table-column
						prop="claimAmountPaid"
						label="Amount Paid"
						width="150"
					/>
					<el-table-column
						label="Fraud Flags"
						width="300"
					>
						<template #default="scope">
							<div
								class="link"
								data-qa="claimQualityAudit_button_fraudFlags"
								@click="onSelectFlags(scope.row.fraudCheckReasons)"
							>
								<strong>{{ scope.row.fraudCheckReasons.length }}</strong>
								{{ 'Flag' | pluralize(scope.row.fraudCheckReasons.length) }}
							</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Assessed By"
						width="200"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.claimAssessedBy"
								class="small mr-auto"
								:value="[scope.row.claimAssessedBy.firstName, scope.row.claimAssessedBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="claimClosedDateTime"
						label="Closed Date"
						width="300"
					/>
					<el-table-column
						prop="claimStatus"
						label="Status"
						width="300"
					/>
				</paged-columns>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';
import taskRowActionsHandlerMixin from '@mixins/taskRowActionsHandlerMixin';
import { mapGetters } from 'vuex';

export default {
	mixins: [rowHighlightMixin, taskRowActionsHandlerMixin],
	props: {
		items: {
			type: Array,
			required: true,
		},
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
	},
	data () {
		return {
			selectedData: null,
		};
	},
	computed: {
		...mapGetters(['dateOfLossLabel']),
	},
	methods: {
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
	},
};
</script>
