<template>
	<div>
		<aq-confirmation-modal
			ref="saveChangesDialog"
			name="save-changes-dialog"
			title="Save changes"
			description="You have unsaved changes, Click Save to Save as Draft or Cancel to Close without saving."
			yes-label="Save"
			no-label="Cancel"
			data-qa="claimPayment_modal_saveChanges"
		>
			<template
				v-if="!isAllowedToSaveClaimAsDraft"
				#yesButton
			>
				<button
					type="button"
					class="btn btn-success ml-auto"
					disabled
				>
					<div v-tooltip="'Current claim status prevents saving as draft.'">
						Save
					</div>
				</button>
			</template>
		</aq-confirmation-modal>
		<aq-alert-modal
			ref="saveChangesAlert"
			name="save-changes-alert"
			title="Invalid data"
			description="Save aborted due to a validation error. Please correct it before saving"
			data-qa="claimPayment_modal_saveChangesAlert"
		/>
		<aq-modal
			v-if="paymentMethods && paymentMethods.length > 0"
			name="new-payee"
		>
			<new-payee-modal
				:recipient="recipient"
				:payment-types="paymentMethods"
				:locale="locale"
				@added-payee="onAddPayee"
				data-qa="claimPayment_modal_addPayee"
			/>
		</aq-modal>
		<aq-modal name="ex-gratia">
			<ExGratiaPaymentModal
				:recipients="exGratiaRecipients"
				:payment-methods="paymentMethods"
				:ex-gratia-payment-for-edit="editExGratiaPayment"
				:all-ex-gratia-payments="exGratiaPayments"
				:all-regular-payments="selectedPayeePayments"
				:is-positive-net="isPositiveNet"
				@add-ex-gratia="onAddExGratia"
				@edit-ex-gratia="onEditExGratia"
				@cancel-ex-gratia="onCancelExGratia"
				:locale="locale"
			/>
		</aq-modal>
		<aq-modal name="reject-claim">
			<manual-rejection-modal
				:rejection-context="{ claimId }"
				@reject="onReject"
				data-qa="claimPayment_modal_rejectClaim"
			/>
		</aq-modal>
		<aq-modal name="fraud-check">
			<fraud-check-modal
				@fraud-check="onFraudCheck"
				:claim-id="claimId"
				data-qa="claimPayment_modal_fraudCheck"
			/>
		</aq-modal>
		<reassessment-outcome-modal
			v-if="claimId"
			data-qa="claimPayment_modal_reassessmentOutcomeModal"
			name="reassessment-outcome-modal"
			ref="saveReassessmentOutcomeModal"
			:selected-reassessment-outcome="selectedReassessmentOutcome"
			:claim-id="claimId"
			@input="selectedReassessmentOutcome = $event"
		/>
		<EligibleConditionsModal
			data-qa="claimPayment_modal_saveEligibleConditions"
			name="eligible-conditions-modal"
			ref="saveEligibleConditionsModal"
			:claim="sharedData.claim"
			@finished="onEligibleConditionsSelected"
		/>
		<div class="container-fluid p-0 mb-40">
			<div class="d-flex flex-column">
				<aq-fraud-check-actions
					v-if="isFraudCheck"
					:claim-id="claimId"
					:is-reassessment="sharedData.isReassessment"
					:has-claim-unsaved-changes="isPaymentDirty"
					:fraud-check-reasons="sharedData.fraudCheckReasons"
					@finish="onFraudChekActionFinish"
				/>
				<div class="row no-gutters">
					<div class="col">
						<PaymentRecipients
							ref="recipients"
							@dirty="isPaymentDirty = true"
							@amount-changed="onAmountChanged"
							@open-payee="createNewPayee"
							:regular-payments="selectedPayeePayments"
							:total-remaining-initial="netAmount"
							:is-read-only-mode="isReadOnlyMode"
							:is-positive-net="isPositiveNet"
							:payment-history-items="regularHistoryItems"
							:locale="locale"
						/>
					</div>
				</div>
				<div
					class="row no-gutters"
					v-if="paymentTransactions.length > 0"
				>
					<div class="col">
						<aq-sandwich
							ref="sandwich"
							header="Transaction History"
							active-class=""
							header-class="pl-25"
							class="mb-50 mr-25"
							is-active-on-start
						>
							<template #content>
								<PaymentTransactions
									:transactions="paymentTransactions"
									:payment-types="allPaymentMethods"
									:locale="locale"
									:recipients="recipients"
									:claim-status="sharedData.claimStatus"
									:billing-sync-enabled="sharedData.billingSyncEnabled"
									@finish="onFinishPayment"
								/>
							</template>
						</aq-sandwich>
					</div>
				</div>
				<div
					class="row no-gutters"
					v-if="paymentHistoryItems.length > 0"
				>
					<div class="col">
						<PaymentHistory
							:payment-history-items="paymentHistoryItems"
							:payment-types="allPaymentMethods"
							:locale="locale"
						/>
					</div>
				</div>
				<div
					class="row no-gutters"
					v-if="$can.EditExGratia"
				>
					<div class="col">
						<PaymentExGratia
							:ex-gratia-payments="exGratiaPayments"
							:payment-methods="paymentMethods"
							:is-readonly="isReadOnlyMode"
							:locale="locale"
							@show-ex-gratia-form="onShowExGratiaForm"
							@edit-ex-gratia="onOpenExGratiaForEdit"
							@delete-ex-gratia="onDeleteExGratia"
						/>
					</div>
				</div>
				<div class="row no-gutters">
					<div class="col">
						<PaymentBreakdown
							:claim-items="claimItems"
							:locale="locale"
						/>
					</div>
				</div>
			</div>
			<claim-navigation-bar
				:shared-data="sharedData"
				:status="status"
				:multicondition="multicondition"
				:is-read-only-mode="!$can.AddPayment || isReadOnlyMode"
				is-last-step
				@cancel="onCancel"
				@back="onBack"
				@next="onFinishClaim"
				@manual-reject="$modal.show('reject-claim')"
				@fraud="$modal.show('fraud-check')"
				@escalate-claim="onClaimEscalateSelect"
				@remove-escalation="onRemoveEscalation"
			>
				<template #success>
					<div class="text-center">
						<i class="mr-10 fas fa-check text-white" /> You have completed all of the required fields. Please proceed to the next screen.
					</div>
				</template>
				<template #danger>
					<div class="text-center">
						<i class="mr-10 fas fa-times-circle text-white" /> Claim breakdown total does not match the “Total Claim Amount”
					</div>
				</template>
			</claim-navigation-bar>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { mapMutations, mapState } from 'vuex';
import ClaimService from '@commonServices/claimService';
import ClaimPayment from '@commonServices/models/ClaimPayment';
import ErrorCode from '@commonServices/models/ErrorCode';
import { pageStatusEnum } from '@commonServices/models/PageStatusEnum';
import { isKnownError, hasFlag } from '@commonServices/utils/general';
import PaymentExGratia from '@commonView/ClaimPage/ClaimPayment/PaymentExGratia';
import ExGratiaPaymentModal from '@commonView/ClaimPage/ClaimPayment/ExGratiaPaymentModal';
import PaymentTransactions from '@commonView/ClaimPage/ClaimPayment/PaymentTransactions';
import EligibleConditionsModal from '@commonView/ClaimPage/Shared/EligibleConditionsModal';
import PaymentRecipients from '@commonView/ClaimPage/ClaimPayment/PaymentRecipients';
import PaymentBreakdown from '@commonView/ClaimPage/ClaimPayment/PaymentBreakdown';
import PaymentHistory from '@commonView/ClaimPage/ClaimPayment/PaymentHistory';
import { toastActions } from '@commonServices/settings/toastSettings';
import { outcomeState, claimNumberStatus, saveClaimAsDraftAllowedStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import { PaymentType } from '@commonServices/utils/payeeOptions';
import { ProductFeatures } from '@commonServices/models/ProductFeatures';
import { PolicyTermStatus } from '@commonServices/models/PolicyTermStatus';

const toastOptions = {
	icon: 'exclamation-triangle',
	duration: 5000,
	className: ['toast-alert'],
	action: toastActions.close,
};

export default {
	components: {
		PaymentExGratia,
		ExGratiaPaymentModal,
		PaymentTransactions,
		EligibleConditionsModal,
		PaymentRecipients,
		PaymentBreakdown,
		PaymentHistory,
	},
	setup () {
		return {
			v$: useVuelidate(),
		};
	},
	inject: ['goPrevious', 'exitClaim', 'mountClaimPage', 'leavePage', 'onClaimEscalate'],
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		brandId: {
			type: Number,
			required: true,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isLoaded: false,
			isAmountValid: false,
			claimId: 0,
			selectedPayeePayments: [],
			netAmount: 0,
			requireExGratiaPayment: false,
			paymentMethods: [],
			allPaymentMethods: [],
			recipient: undefined,
			claimItems: [],
			isPaymentDirty: false,
			paymentHistoryItems: [],
			paymentTransactions: [],
			exGratiaPayments: [],
			recipients: [],
			isPositiveNet: false,
			rejectionData: {
				isManuallyRejected: false,
				rejectionReasonId: 0,
				comment: null,
			},
			fraudCheck: false,
			fraudCheckReasons: [],
			fraudCheckComment: null,
			editExGratiaIndex: -1,
			editExGratiaPayment: null,
			selectedReassessmentOutcome: null,
			excludedEligibleConditionIds: [],
			escalationReasonId: null,
		};
	},
	async mounted () {
		await this.mountClaimPage();
		this.mountPage();
	},
	computed: {
		...mapState(['currentUser', 'claimPayees', 'appSettings']),
		status () {
			return this.isAmountValid ? pageStatusEnum.Success : pageStatusEnum.Danger;
		},
		regularHistoryItems () {
			return this.paymentHistoryItems.filter(item => item.paymentType === PaymentType.Claim);
		},
		isFraudCheck () {
			return this.sharedData.claimStatus === claimNumberStatus.FraudCheck;
		},
		isReadOnlyMode () {
			return (!this.$can.ManagePaymentAccount
				&& !this.$can.AddPayment)
				|| this.sharedData.isReadOnlyMode;
		},
		isAllowedToSaveClaimAsDraft () {
			return saveClaimAsDraftAllowedStatuses.includes(this.sharedData.claimStatus);
		},
		exGratiaRecipients () {
			if (this.appSettings.disableSplitPayments) {
				return this.selectedPayeePayments.map(p => p.recipient);
			} else {
				return this.recipients;
			}
		},
	},
	methods: {
		...mapMutations(['setClaimStepState', 'setClaimPayees']),
		mountPage () {
			this.claimId = this.sharedData.claimId;
			Promise.all([ClaimService.getPaymentRecipients(this.claimId),
				ClaimService.getPaymentDetails(this.claimId),
				ClaimService.getPaymentMethods(this.brandId),
				ClaimService.getAllPaymentMethods(this.locale),
				ClaimService.getPaymentTransactions(this.claimId),
				ClaimService.getClaimPaymentSummary(this.claimId)])
				.then((result) => {
					const [recipients, paymentDetails, paymentMethods, allPaymentMethods, paymentTransactions, claimItems] = result;

					this.setClaimPayees(paymentDetails.claimPayees);

					const payments = [];
					for (const item of recipients) {
						let selectedAccount = null;
						let paymentAmount = 0;

						if (item.claimPayment) {
							paymentAmount = item.claimPayment.grossAmount || 0;
							selectedAccount = item.accounts.find(x => x.id === item.claimPayment.paymentAccountId);
							if (recipients.length === 1 && this.sharedData.isContinuationClaim && paymentAmount === 0) {
								paymentAmount = paymentDetails.netClaimAmount;
							}
							const regularPayment = this.buildPayment(item, paymentAmount, selectedAccount, PaymentType.Claim, item.claimPayment);
							payments.push(regularPayment);
						}
						if (!item.claimPayment && !item.exGratiaClaimPayment) {
							const regularPayment = this.buildPayment(item, paymentAmount, selectedAccount, PaymentType.Claim, item.claimPayment);
							payments.push(regularPayment);
						}

						if (item.exGratiaClaimPayment) {
							paymentAmount = item.exGratiaClaimPayment.grossAmount || 0;
							selectedAccount = item.accounts.find(x => x.id === item.exGratiaClaimPayment.paymentAccountId);
							const exGratiaPayment = this.buildPayment(item, paymentAmount, selectedAccount, PaymentType.ExGratia, item.exGratiaClaimPayment);
							payments.push(exGratiaPayment);
						}
					}

					const allPayeesPayments = payments.filter(item => item.isEditable && item.paymentType === PaymentType.Claim);
					this.selectedPayeePayments = allPayeesPayments.filter(p => this.claimPayees.includes(p.recipient.recipientType));

					if (!this.isReadOnlyMode) {
						this.selectedPayeePayments.forEach((element) => {
							if (!element.selectedPayment?.isActive) {
								element.selectedPayment = null;
							}
						});
					}

					this.recipients = allPayeesPayments.map(p => p.recipient);
					this.paymentHistoryItems = payments.filter(item => !item.isEditable && item.selectedPayment);
					this.exGratiaPayments = payments.filter(p => p.isEditable && p.paymentType === PaymentType.ExGratia);
					if (this.appSettings.disableSplitPayments) {
						// Remove ex gratia payments so data will be saved without payments to non selected payee.
						this.exGratiaPayments = this.exGratiaPayments.filter(p => this.claimPayees.includes(p.recipient.recipientType));
					}

					this.netAmount = paymentDetails.netClaimAmount;
					this.requireExGratiaPayment = paymentDetails.requireExGratiaPayment;
					this.isPositiveNet = Math.sign(this.netAmount) >= 0;
					this.paymentMethods = paymentMethods;
					this.allPaymentMethods = allPaymentMethods;
					this.paymentTransactions = paymentTransactions;
					this.claimItems = claimItems;
				});
			this.isLoaded = true;
		},
		buildPayment (recipient, paymentAmount, selectedAccount, paymentType, claimPayment) {
			return {
				recipient: {
					recipientId: recipient.id,
					recipientType: recipient.recipientType,
					recipientName: recipient.name,
					accounts: recipient.accounts.map((account) => ({ ...account })),
				},
				paymentAmount,
				selectedPayment: selectedAccount,
				isEditable: recipient.isEditable,
				uniqId: `${recipient.recipientType}${recipient.id}`,
				paymentType,
				comment: claimPayment ? claimPayment.comment : null,
				createdBy: claimPayment ? claimPayment.createdBy : null,
			};
		},
		createNewPayee (recipient) {
			this.recipient = recipient;
			this.$modal.show('new-payee');
		},
		onAmountChanged (isAmountValid) {
			this.isAmountValid = isAmountValid;
		},
		onAddPayee (payment, uniqId) {
			const recipient = this.selectedPayeePayments.find((item) => item.uniqId === uniqId);

			const account = {
				accountNumber: payment.accountNumber,
				bankName: payment.bankName,
				id: payment.id,
				payee: payment.payee,
				paymentMethod: payment.paymentMethod,
				sortCode: payment.sortCode,
				isActive: payment.isActive,
			};

			recipient.recipient.accounts.push(account);
			recipient.selectedPayment = account;
			this.$refs.recipients.autoSetSplitAmount(recipient);
			this.$modal.hide('new-payee');
		},
		async onBack () {
			await this.goPrevious();
		},
		onCancel () {
			this.leavePage();
		},
		onReject (value) {
			this.rejectionData.isManuallyRejected = true;
			this.rejectionData.rejectionReasonId = value.reasonId;
			this.rejectionData.comment = value.comment;
			this.exitClaim();
		},
		onFraudCheck (reasons, comment) {
			this.fraudCheck = true;
			this.fraudCheckReasons = reasons;
			this.fraudCheckComment = comment;
			this.exitClaim();
		},
		showOutcomesModal () {
			return this.sharedData.isReassessment && !this.rejectionData.isManuallyRejected;
		},
		async onFinishClaim () {
			if (!this.$refs.recipients.validate()) {
				return;
			}
			if (this.isReadOnlyMode) {
				this.leavePage();
			} else if (this.status === pageStatusEnum.Success) {
				if (this.requireExGratiaPayment && this.exGratiaPayments.length === 0)	{
					this.$toasted.show('Ex-gratia amount must be greater than Zero.', toastOptions);
					return;
				}
				const payments = this.getSelectedPayments();
				try {
					if (this.showOutcomesModal()) {
						const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(outcomeState.Paid);
						if (userChoice === 'no' || userChoice === 'cross') {
							return;
						}
					}

					const claimBasicData = this.sharedData.claim.basicData;
					const claimPolicyTerms = this.sharedData.petPolicyTerms.filter(policyTerm => policyTerm.policyTermStatus !== PolicyTermStatus.CancelledToInception
						&& ((policyTerm.policyTermStartDate <= claimBasicData.treatmentStart && policyTerm.policyTermEndDate >= claimBasicData.treatmentStart)
						|| (policyTerm.policyTermStartDate <= claimBasicData.treatmentEnd && policyTerm.policyTermEndDate >= claimBasicData.treatmentEnd)
						|| (policyTerm.policyTermStartDate >= claimBasicData.treatmentStart && policyTerm.policyTermEndDate <= claimBasicData.treatmentEnd)));
					const showEligibleConditionModal = claimPolicyTerms.every(policyTerm => {
						return !hasFlag(policyTerm.product.productFeatures, ProductFeatures.DisableEligibleCondition);
					});

					if (showEligibleConditionModal && this.appSettings.autoEligibleConditions && this.$can.AllowFinalizeClaims
						&& this.currentUser.approvalLimit >= this.appSettings.autoEligibleConditionMinimumApprovalLimit) {
						const userChoice = await this.$refs.saveEligibleConditionsModal.show();
						if (userChoice === 'no' || userChoice === 'cross') {
							this.excludedEligibleConditionIds = [];
							return;
						}
					}

					await ClaimService.finishClaim(
						this.claimId,
						payments,
						false,
						this.selectedReassessmentOutcome?.id,
						this.excludedEligibleConditionIds,
					);
					this.isPaymentDirty = false;
					this.leavePage();
				} catch (e) {
					if (isKnownError(e, ErrorCode.ClaimCannotMovedToStatus)) {
						await ClaimService.finishClaim(this.claimId, payments, true);
						this.isPaymentDirty = false;
						this.leavePage();
					} else {
						throw e;
					}
				}
			}
		},
		async beforeLeaveHandler (isNextStep, newStepName) {
			if (this.isReadOnlyMode) return Promise.resolve();
			const payments = this.getSelectedPayments();
			if (this.rejectionData.isManuallyRejected) {
				const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(outcomeState.Rejected);
				if (userChoice === 'no' || userChoice === 'cross') {
					return Promise.reject(new Error('User canceled selecting reassessment outcome'));
				}
			}
			await this.v$.$validate();
			if (this.rejectionData.isManuallyRejected || this.fraudCheck || this.$refs.recipients.isRecipientsValidForDraft || this.escalationReasonId) {
				return ClaimService.finishClaim(this.claimId, payments, true, this.selectedReassessmentOutcome?.id, [], this.rejectionData, this.fraudCheck, this.fraudCheckReasons, this.fraudCheckComment);
			}
			await this.$refs.saveChangesAlert.show();
			return Promise.reject(new Error('invalid data'));
		},
		getSelectedPayments () {
			return [
				...this.selectedPayeePayments.filter(x => x.selectedPayment && x.selectedPayment.isActive)
					.map(x => new ClaimPayment(x.selectedPayment.id, x.paymentAmount, PaymentType.Claim)),
				...this.exGratiaPayments.filter(exp => exp.selectedPayment && exp.selectedPayment.isActive)
					.map(exp => new ClaimPayment(exp.selectedPayment.id, exp.paymentAmount, PaymentType.ExGratia, exp.comment)),
			];
		},
		async saveDraft () {
			if (this.isReadOnlyMode
				|| !this.isPaymentDirty
				|| this.rejectionData.isManuallyRejected
				|| this.fraudCheck
				|| this.escalationReasonId) {
				return Promise.resolve();
			} else {
				const userAction = await this.$refs.saveChangesDialog.show();
				if (userAction === 'no') return Promise.resolve();
				if (userAction === 'yes') {
					if (this.$refs.recipients.isRecipientsValidForDraft) {
						const payments = this.getSelectedPayments();
						return ClaimService.finishClaim(this.claimId, payments, true);
					}
					await this.$refs.saveChangesAlert.show();
					return Promise.reject(new Error('invalid data'));
				}
				return Promise.reject(new Error('user click cross'));
			}
		},
		onAddExGratia ({ recipient, selectedPayment, comment, paymentAmount }) {
			const exGratiaPayment = {
				recipient,
				paymentAmount,
				selectedPayment,
				isEditable: true,
				uniqId: `${recipient.recipientType}${recipient.recipientId}`,
				comment,
				paymentType: PaymentType.ExGratia,
				createdBy: this.currentUser,
			};
			this.exGratiaPayments.push(exGratiaPayment);
		},
		onOpenExGratiaForEdit (exGratiaPayment) {
			this.editExGratiaIndex = this.exGratiaPayments.findIndex(exRec => exRec.uniqId === exGratiaPayment.uniqId);
			this.editExGratiaPayment = Object.assign({}, exGratiaPayment);
			this.onShowExGratiaForm();
		},
		onEditExGratia ({ recipient, paymentAmount, selectedPayment, comment }) {
			this.exGratiaPayments[this.editExGratiaIndex].recipient = recipient;
			this.exGratiaPayments[this.editExGratiaIndex].paymentAmount = paymentAmount;
			this.exGratiaPayments[this.editExGratiaIndex].selectedPayment = selectedPayment;
			this.exGratiaPayments[this.editExGratiaIndex].comment = comment;
			this.exGratiaPayments[this.editExGratiaIndex].createdBy = this.currentUser;
			this.editExGratiaIndex = -1;
			this.editExGratiaPayment = null;
		},
		onDeleteExGratia (exGratiaPayment) {
			const deleteIndex = this.exGratiaPayments.findIndex(exPayment => exPayment === exGratiaPayment);
			this.exGratiaPayments.splice(deleteIndex, 1);
		},
		onShowExGratiaForm () {
			this.$modal.show('ex-gratia');
		},
		onCancelExGratia () {
			this.editExGratiaIndex = -1;
			this.editExGratiaPayment = null;
			this.$modal.hide('ex-gratia');
		},
		async onFraudChekActionFinish (leaveClaimPage) {
			await this.mountClaimPage();
			if (leaveClaimPage) {
				this.$router.back();
			}
		},
		async onFinishPayment () {
			this.mountPage();
		},
		updateClaimStepState () {
			const claim = {
				recipients: this.selectedPayeePayments.filter(payment => payment.selectedPayment),
				exGratiaPayments: this.exGratiaPayments.filter(payment => payment.selectedPayment),
				total: this.netAmount,
			};
			this.setClaimStepState(claim);
		},
		onEligibleConditionsSelected (eligibleConditions) {
			this.excludedEligibleConditionIds = eligibleConditions
				.filter(condition => !condition.isSelected)
				.map(condition => condition.conditionId);
		},
		async onClaimEscalateSelect () {
			this.escalationReasonId = await this.onClaimEscalate(this.isPaymentDirty);

			if (this.escalationReasonId) {
				try {
					await this.exitClaim();
					await ClaimService.escalate(this.claimId, this.escalationReasonId);
				} catch {
				}
				this.escalationReasonId = null;
			}
		},
		async onRemoveEscalation () {
			await this.exitClaim();
			await ClaimService.removeEscalation(this.claimId);
		},
	},
};
</script>

<style lang="scss">
.bar {
  background-color: var(--fastTrackBarColor);
  height: 8px;
}
</style>
