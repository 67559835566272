<template>
	<div class="edit-pet-container">
		<aq-confirmation-modal
			:ref="dateOfDeathDialogReference"
			:name="dateOfDeathDialogReference"
			title="Date of Death"
			description="do not show"
			yes-label="Proceed"
			no-label="Revert"
			v-if="pet.dateOfDeath"
		>
			<template>
				<div class="p-22 text-center section-content">
					<div class="pet-alert-logo">
						<aq-pet-avatar
							class="large mx-auto"
							:pet="pet"
							show-alert
						/>
					</div>
					<div>
						The date of death previously recorded is:
					</div>
					<strong
						class="text-highlight"
						data-qa="enterBasic_dod_modal_prev_date"
					>{{ shortDateOrNoDate(previousDateOfDeath) }}</strong>
					<div>
						Do you wish to proceed updating the current Date of Death to:
					</div>
					<strong
						class="text-highlight"
						data-qa="enterBasic_dod_modal_to_date"
					>{{ shortDateOrNoDate(selectedDateOfDeath) }}</strong> or revert back to <strong
						class="text-highlight"
						data-qa="enterBasic_dod_modal_revert_date"
					>{{ shortDateOrNoDate(previousDateOfDeath) }}</strong>
				</div>
			</template>
		</aq-confirmation-modal>
		<aq-pet-avatar
			:pet="pet"
			class="right-align cursor-inherit"
			:tooltip="false"
		/>
		<VDropdown
			v-if="!!pet.dateOfDeath && $can.UpdatePetDateOfDeath"
			theme="info-dropdown"
			class="edit-pet"
			popper-class="pet-date-of-death-popover"
		>
			<i class="fas fa-pencil-alt cursor-pointer" />
			<template #popper="{ hide }">
				<div
					class="flex-grow-1"
					style="width: 190px;"
				>
					<aq-form-input
						class="aq-popover"
						data-qa="petAvatar_input_dateOfDeath"
						label="Date of Death"
						type="date"
						v-model="selectedDateOfDeath"
						@input="hide(); onDateOfDeathChange($event)"
						is-valid
						:disabled="false"
					/>
					<aq-form-input-error
						class="pb-4"
						:validator="v$.selectedDateOfDeath"
					/>
				</div>
			</template>
		</VDropdown>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import PetService from '@commonServices/petService';
import { shortDate } from '@clientCommon/services/utils/filters';
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { minValue, maxValue, withMessage } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	props: {
		pet: {
			type: Object,
			required: true,
		},
		petAvatarClass: {
			type: [String, Array],
			required: false,
			default: () => ([]),
		},
	},
	data () {
		return {
			selectedDateOfDeath: this.pet.dateOfDeath,
			previousDateOfDeath: this.pet.dateOfDeath,
		};
	},
	validations () {
		return {
			selectedDateOfDeath: {
				minValue: withMessage(ValidationMessages.dateCannotBeBefore('Date of Birth'), minValue(this.pet.birthDate)),
				maxValue: withMessage(ValidationMessages.dateInFuture, maxValue(this.dateForFutureValidation)),
			},
		};
	},
	computed: {
		...mapGetters(['dateForFutureValidation']),
		dateOfDeathDialogReference () {
			return `dateOfDeathDialog_${this.pet.id}`;
		},
	},
	mounted () {
		this.previousDateOfDeath = this.pet.dateOfDeath;
		this.selectedDateOfDeath = this.pet.dateOfDeath;
	},
	methods: {
		async onDateOfDeathChange (value) {
			this.v$.selectedDateOfDeath.$touch();
			if (this.v$.selectedDateOfDeath.$error) {
				return;
			}

			if (await this.$refs[this.dateOfDeathDialogReference].show() === 'yes') {
				try {
					await PetService.updatePetDateOfDeath(this.pet.id, value);
					this.pet.dateOfDeath = value;
				} catch (error) {
					this.selectedDateOfDeath = this.previousDateOfDeath;
				}
			} else {
				this.selectedDateOfDeath = this.previousDateOfDeath;
			}
		},
		shortDateOrNoDate (dt) {
			return shortDate(dt) || 'No Date';
		},
	},
};
</script>
<style lang="scss" scoped>
.edit-pet-container {
  position: relative;

  .edit-pet {
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
  }

  .fas.fa-pencil-alt {
    font-size: 18px;
    color: $primary;
    transform: scale(1.3);
  }
}
</style>

<style lang="scss">
.pet-date-of-death-popover {
  .v-popper__inner {
    overflow: visible;
  }
}
</style>
