<template>
	<aq-table-popover
		v-if="anyDeductions"
		class="d-flex"
		:disabled="!showPopup"
		:open="showPopup"
		:boundaries-element-selector="boundariesElementSelector"
		@hide="showPopup = false"
		popover-class="claim-deductions-details"
	>
		<template v-if="systemDeductions.length">
			<div class="section-title">
				System Deductions
			</div>
			<div class="table-wrapper">
				<table>
					<colgroup>
						<col>
						<col class="amount-column">
					</colgroup>
					<tbody>
						<tr
							v-for="(systemDeduction, index) in systemDeductions"
							:key="index"
						>
							<td>
								{{ systemDeduction.name }}
							</td>
							<td>
								{{ systemDeduction.amount | currency(locale) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
		<template v-if="userDeductions.length">
			<div class="section-title">
				User Deductions
			</div>
			<div class="table-wrapper">
				<table>
					<colgroup>
						<col>
						<col class="amount-column">
					</colgroup>
					<tbody>
						<tr
							v-for="(userDeduction, index) in userDeductions"
							:key="index"
						>
							<td>
								<aq-tooltip-on-truncate
									:tooltip-text="userDeduction.name"
									offset="12"
								>
									{{ userDeduction.name }}
								</aq-tooltip-on-truncate>
							</td>
							<td>
								{{ userDeduction.amount | currency(locale) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
		<template #title>
			<div
				class="claim-link"
				@click="onAmountClick"
			>
				{{ paid | currency(locale) }}
			</div>
		</template>
	</aq-table-popover>
	<div v-else>
		{{ paid | currency(locale) }}
	</div>
</template>

<script>
import AqTablePopover from '@commonWidgets/AqTablePopover';
import { getDeductionDescription, deductionTypeEnum } from '@commonServices/models/DeductionTypeEnum';
import groupBy from 'lodash.groupby';

const systemDeductionTypes = Object.values(deductionTypeEnum);

export default {
	name: 'ClaimDeductionsDetails',
	components: {
		AqTablePopover,
	},
	props: {
		paid: {
			type: Number,
			required: true,
		},
		claimItems: {
			type: Array,
			required: false,
			default: () => [],
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		boundariesElementSelector: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			showPopup: false,
		};
	},
	methods: {
		getDeductionDescription (deductionType) {
			return getDeductionDescription(deductionType, this.locale);
		},
		onAmountClick (e) {
			e.stopPropagation();
			this.showPopup = this.anyDeductions && true;
		},
	},
	computed: {
		systemDeductions () {
			const mergedSystemDeductions = this.claimItems.filter(ci => !ci.rebalanceExcess).reduce((acc, claimItem) => {
				systemDeductionTypes.forEach(type => {
					const currentDeductionValue = acc[type] || 0;
					const nextDeductionValue = claimItem.systemDeductions[type] || 0;
					acc[type] = currentDeductionValue + nextDeductionValue;
				});
				return acc;
			}, {});

			return Object.entries(mergedSystemDeductions).map(([key, value]) => ({ name: getDeductionDescription(key, this.locale), amount: value })).filter(item => item.amount);
		},
		userDeductions () {
			const allUserDeductions = this.claimItems.filter(ci => !ci.rebalanceExcess).map(ci => ci.userDeductions).flat();
			return Object.entries(groupBy(allUserDeductions, 'description'))
				.map(([key, values]) => ({ name: key, amount: values.reduce((sum, next) => sum + next.amount, 0) }))
				.filter(item => item.amount);
		},
		anyDeductions () {
			return this.systemDeductions.length + this.userDeductions.length > 0;
		},
	},
};
</script>

<style lang="scss">
.claim-deductions-details.table-popover  {
  .v-popper__inner {
    width: 340px;
  }

  .section-title {
    background: var(--headerBg);
    color: var(--sectionTextColour);
    display: flex;
    padding-left: 20px;
    line-height: 34px;
  }

  .table-wrapper {
    padding: 5px;
  }

  table {
    .amount-column {
      width: 90px;
    }

    td {
      font-size: 13px;
      border: none !important;

      &:first-child {
        padding-left: 15px;
      }
    }

    tr {
      height: 25px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--tableBorderColor);
      }
    }
  }

  .tooltip-arrow {
    border-color: var(--headerBg);
  }
}

</style>

<style lang="scss" scoped>
::v-deep .claim-link {
  color: var(--bodyColour);

  &:hover {
    color: $primary-d-20;
  }
}
</style>
