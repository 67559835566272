<template>
	<div>
		<aq-list-navigation-control
			:list="letters"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				:data="letters"
				fit
				empty-text="There is no data to display"
				cell-class-name="text-left"
				header-cell-class-name="text-left"
				:row-class-name="getRowClassName"
				class="pending-letters"
				data-qa="pendingLettersView_table_letters"
			>
				<el-table-column width="80">
					<!-- passing scope here but not using it because without that props in table header are not passed  -->
					<template #header="scope">
						<aq-checkbox
							class="round medium thin ml-3"
							:value="getSelectAllState(scope)"
							@input="onSelectAllChanged"
							:disabled="selectionDisabled"
						/>
					</template>
					<template #default="scope">
						<aq-checkbox
							class="round large thin"
							:value="getSelectedState(scope.row.id)"
							@input="onSelectionChanged(scope.row.id, $event)"
							:disabled="selectionDisabled"
						/>
					</template>
				</el-table-column>
				<el-table-column
					label="Policy Ref"
				>
					<template #default="scope">
						<truncated-text-cell :value="scope.row.policyReference" />
					</template>
				</el-table-column>
				<el-table-column
					label="Letter"
				>
					<template #default="scope">
						<truncated-text-cell :value="scope.row.letterType" />
					</template>
				</el-table-column>
				<el-table-column
					label="Vet Business Name"
					min-width="150"
				>
					<template #default="scope">
						<truncated-text-cell :value="scope.row.vetName" />
					</template>
				</el-table-column>
				<el-table-column
					label="Claim Status"
					min-width="100"
				>
					<template #default="scope">
						<truncated-text-cell :value="scope.row.claimStatus" />
					</template>
				</el-table-column>
				<el-table-column
					:label="toRecipientLabel()"
					min-width="100"
				>
					<template #default="scope">
						<truncated-text-cell :value="scope.row.customerFirstName + ' ' + scope.row.customerLastName" />
					</template>
				</el-table-column>
				<el-table-column
					label="Generated By"
					min-width="100"
				>
					<template #default="scope">
						<truncated-text-cell :value="scope.row.generatedBy" />
					</template>
				</el-table-column>
				<el-table-column label="Generated">
					<template #default="scope">
						<truncated-text-cell :value="scope.row.generatedDateTime" />
					</template>
				</el-table-column>
				<el-table-column width="70">
					<template #default="scope">
						<div
							class="card-icon small"
							v-if="scope.row.zippedFileName"
						>
							<i
								class="fas fa-file-archive text-warning"
								v-tooltip="scope.row.zippedFileName"
							/>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="70">
					<template #default="scope">
						<router-link
							:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
							class="d-inline-block"
						>
							<aq-icon-avatar
								class="small mr-auto cursor-pointer"
								:value="[scope.row.customerFirstName, scope.row.customerLastName]"
							/>
						</router-link>
					</template>
				</el-table-column>
				<el-table-column width="70">
					<template #default="scope">
						<router-link
							:to="{ name: 'pet', params: { customerId: scope.row.customerId, petId: scope.row.petId } }"
							class="d-inline-block"
						>
							<aq-pet-avatar
								class="small cursor-pointer"
								:pet="{
									name: scope.row.petName,
									type: scope.row.petType,
									gender: scope.row.petGender,
									dateOfDeath: scope.row.petDateOfDeath
								}"
							/>
						</router-link>
					</template>
				</el-table-column>
				<el-table-column width="70">
					<template #default="scope">
						<router-link
							v-if="scope.row.claimId"
							:to="{ name: 'claim-page', params: { customerId: scope.row.customerId, claimId: scope.row.claimId } }"
							class="card-icon small d-inline-block"
						>
							<i
								class="fas fa-file-invoice"
								v-tooltip="formatClaimId(scope.row.claimId)"
							/>
						</router-link>
					</template>
				</el-table-column>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import { UiLocaleSettings } from '@commonServices/settings/localeSettings';
import { mapState } from 'vuex';
import { toRecipientLabel } from '@commonServices/utils/converter';
import RecipientType from '@commonServices/models/RecipientType';
import { claimIdFormat } from '@commonServices/utils/filters';

export default {
	mixins: [rowHighlightMixin],
	props: {
		letters: {
			type: Array,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
		selection: {
			type: Object,
			required: true,
		},
		selectionDisabled: {
			type: Boolean,
			default: false,
		},
	},
	updated () {
		this.emitSelectionChanged(this.selection);
	},
	computed: {
		...mapState(['appSettings']),
	},
	methods: {
		getSelectedState (id) {
			return this.selection.ids.length === 0 ? this.selection.all
				: this.selection.ids.includes(id) ? !this.selection.all : this.selection.all;
		},
		onSelectionChanged (id) {
			const selectedIds = [...this.selection.ids];
			const index = selectedIds.indexOf(id);
			if (index > -1) {
				selectedIds.splice(index, 1);
			} else {
				selectedIds.push(id);
			}
			this.emitSelectionChanged({ all: this.selection.all, ids: selectedIds });
		},
		onSelectAllChanged (newValue) {
			this.emitSelectionChanged({ all: newValue, ids: [] });
		},
		getSelectAllState () {
			return this.selection.all && this.selection.ids.length === 0;
		},
		getRowClassName ({ row }) {
			return [
				this.currentItem === row && 'border-highlighted',
				!this.getSelectedState(row.id) && 'disabled',
			].filter(Boolean);
		},
		emitSelectionChanged (selection) {
			this.$emit('selectionChanged', selection);
		},
		toRecipientLabel () {
			return toRecipientLabel(RecipientType.PolicyHolder, UiLocaleSettings, this.appSettings);
		},
		formatClaimId (claimId) {
			return claimId ? claimIdFormat(claimId) : '';
		},
	},
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-table__row {
    transition: none;

    &.disabled {
      color: var(--disabledText);

      .text-warning {
        color: var(--disabledText) !important;
      }

      .card-icon {
        color: var(--disabledText);

        img {
          filter: invert(43%) sepia(2%) saturate(3%) hue-rotate(314deg) brightness(93%) contrast(78%);
        }
      }
    }
  }

  .card-icon.small {
    &:hover {
      color: var(--cardIconColourHover);
    }
  }
</style>
