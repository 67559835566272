<template>
	<aq-panel-wrapper>
		<div class="panel-container panel-container--left">
			<aq-confirmation-modal
				ref="deleteQueueModal"
				name="delete-queue-modal"
				title="Confirm Delete Queue"
				:description="`You are about to delete '${selectedQueue.title}' queue. Do you wish to continue?`"
				yes-label="Delete"
				no-label="Cancel"
				data-qa="tasks-panel_modal_delete-queue"
			/>
			<aq-modal name="save-queue-modal">
				<save-filters-modal
					label="Queue"
					title="Save queue"
					:value="selectedQueue.title"
					description="Please edit the Queue name"
					:is-name-uniq="isQueueNameUniq"
					@save="onRenameQueue"
					@touch="isQueueNameUniq = true"
					:reserved-names="$options.reservedQueueNames"
				/>
			</aq-modal>
			<aq-confirmation-modal
				ref="deleteTeamModal"
				name="delete-team-modal"
				title="Confirm Delete Team"
				description="By deleting this Team, all associated users and queues will be unallocated."
				question="Do you wish to proceed?"
				yes-label="Proceed"
				no-label="Cancel"
				data-qa="tasks-panel_modal_delete-team"
			/>
			<div class="panel panel--left d-flex flex-column">
				<div class="section-header">
					<div class="section-header__title">
						Tasks
					</div>
					<button
						class="btn-row btn ml-auto"
						data-qa="tasksPanel_button_close"
						@click="onClose"
					>
						Close
					</button>
				</div>
				<aq-tab-selector
					:items="$options.tabItems"
					:active-tab="activeTab"
					@select-tab="onSelectTab"
					v-if="activeTab"
					data-qa="tasksPanel_select_queueType"
					class="flex-grow-1 d-flex flex-column"
				>
					<teams
						v-if="activeTab.value === 'teams'"
						:teams="teams"
						:selected-id="selectedTeamId"
						class="section-content flex-grow-1 d-flex flex-column px-15"
						@create-team="onCreateTeam"
						@team-action="onSelectTeamAction"
					/>
					<div
						v-else
						class="section-content flex-grow-1 d-flex flex-column"
					>
						<div
							v-if="!queuesList"
							class="content-wrapper"
						>
							<aq-card
								v-for="(pot, index) of taskPots"
								:key="index"
								:name="pot.title"
								:description="pot.description"
								:action="$options.potAction"
								data-qa="tasksPanel_card_task"
								type="folder"
								class="mb-10"
							>
								<template #action-button>
									<div
										class="align-self-center ml-10"
										v-tooltip.bottom="pot.actionTooltip"
									>
										<button
											@click="openPot(pot)"
											:class="['btn mt-16', pot.actionAvailable ? 'btn-primary' : 'btn-secondary' ]"
											:disabled="!pot.actionAvailable"
											data-qa="aqCard_button_select"
										>
											{{ $options.potAction.label }}
										</button>
									</div>
								</template>
							</aq-card>
						</div>
						<div
							v-else
							class="content-wrapper"
						>
							<div class="d-flex align-items-center mb-10">
								<button
									class="btn btn-row px-20 mr-10"
									data-qa="tasksPanel_button_back"
									v-if="queuesList"
									@click="queuesList = null"
								>
									<i class="fas fa-chevron-left" />
								</button>
								<span> {{ selectedPotTitle }} </span>
							</div>
							<aq-card
								v-for="queue of queuesList"
								:key="queue.id"
								:name="queue.title"
								:description="queue.description"
								type="queue"
								:action="queue.actions"
								@select-action="onSelectQueueAction($event, queue)"
								@click-name="onSelectQueueAction('view', queue )"
								data-qa="tasksPanel_card_task"
								class="mb-10 queue-card clickable-name"
							>
								<template
									v-if="queue.isPrioritized"
									#card-icon
								>
									<div class="card-icon mr-20 medium align-self-center position-relative">
										<i class="fa fa-inbox" />
										<component
											class="auto-assign-queue-logo"
											:is="autoAssignQueueLogo"
											v-tooltip="'Auto Assign'"
										/>
									</div>
								</template>
								<template #content>
									<div
										class="bottom mt-2 py-10 px-20 d-flex justify-content-between"
										v-if="queue.createdBy"
									>
										<div class="text-subtitle fs-13 d-flex align-items-center">
											<span v-tooltip="lifetimeInfoTooltip(queue)">
												{{ lifetimeInfo(queue) }}
											</span>
										</div>
										<aq-icon-avatar
											class="small"
											:value="[queue.createdBy.firstName, queue.createdBy.lastName]"
										/>
									</div>
									<div
										class="bottom mt-2 py-10 px-20"
										v-else
									>
										<div class="text-subtitle fs-13">
											System Generated
										</div>
									</div>
								</template>
							</aq-card>
						</div>
					</div>
				</aq-tab-selector>
			</div>
		</div>
		<team-profile
			v-if="openTeamProfile"
			:id="selectedTeamId"
			@cancel="onCloseTeamProfile"
			@save-team="onSaveTeam"
		/>
	</aq-panel-wrapper>
</template>

<script>
import WorkflowService from '@commonServices/workflowService';
import { pluralize } from '@commonServices/utils/filters';
import CommonService from '@commonServices/commonService';
import eventBus from '@commonServices/eventBus';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';
import ErrorCode from '@commonServices/models/ErrorCode';
import Teams from '@commonLayout/teams/Teams';
import { lifetimeInfo, lifetimeInfoToolTip } from '@commonServices/utils/dateUtils';
import TeamProfile from '@commonLayout/teams/TeamProfile';
import teamsService, { TeamsActions } from '@commonServices/teamsService';
import { UnfilteredTasksQueueName, EverytingElseQueueName } from '@commonServices/utils/constants';
import { isKnownError } from '@commonServices/utils/general';
import SaveFiltersModal from '@commonView/Shared/SaveFiltersModal';
import { AutoAssignQueue } from '@assets/icons';

const QueuesActions = {
	view: { name: 'View', value: 'view', iconClass: 'fa-eye text-primary' },
	rename: { name: 'Rename', value: 'rename', iconClass: 'fa-edit text-primary' },
	delete: { name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' },
};

const reservedQueueNames = [EverytingElseQueueName.toLowerCase(), UnfilteredTasksQueueName.toLowerCase()];

const TaskPanelItems = [
	{
		pot: WorkQueueType.DraftClaims,
		title: 'Draft Claims',
		description: 'Draft',
	},
	{
		pot: WorkQueueType.Chasing,
		title: 'Claims Missing Information',
		description: 'To Chase',
	},
	{
		pot: WorkQueueType.FraudCheck,
		title: 'Claims to Fraud Check',
		description: 'Flagged',
	},
	{
		pot: WorkQueueType.PaymentApproval,
		title: 'Claim Payments to Approve',
		description: 'Settled',
	},
	{
		pot: WorkQueueType.RejectionApproval,
		title: 'Claim Rejection Pending',
		description: 'Rejection Pending',
	},
	{
		pot: WorkQueueType.ReassessmentPending,
		title: 'Claims to Reassess',
		description: 'Reassessment Pending',
	},
];

export default {
	name: 'TasksPanel',
	components: {
		TeamProfile,
		Teams,
		SaveFiltersModal,
	},
	data () {
		return {
			activeTab: undefined,
			taskPots: [],
			claimStatistics: [],
			queuesList: null,
			selectedPotTitle: null,
			selectedQueue: {},
			currentPot: null,
			openTeamProfile: false,
			teams: [],
			selectedTeamId: null,
			isQueueNameUniq: true,
		};
	},
	reservedQueueNames,
	tabItems: [],
	potAction: { label: 'Open', value: 'open' },
	async mounted () {
		this.$options.tabItems = [{
			value: 'queues',
			label: 'Queues',
		},
		...this.$can.ViewTeams
			? [{
				value: 'teams',
				label: 'Teams',
			}]
			: [],
		];
		this.activeTab = this.$options.tabItems[0];
		this.claimStatistics = await WorkflowService.getClaimsStatistics();
		this.taskPots = this.toTaskPots();
	},
	computed: {
		autoAssignQueueLogo () {
			return AutoAssignQueue;
		},
	},
	methods: {
		getClaimsTotal (pot) {
			const stat = this.claimStatistics.find((statistic) => statistic.pot === pot);
			if (stat && stat.count) {
				return stat.count;
			}
			return 0;
		},
		getPotDescription (pot, description) {
			const count = this.getClaimsTotal(pot);
			return `${count} ${description} ${pluralize('Claim', count)}`;
		},
		toTaskPots () {
			return TaskPanelItems.map((item) => {
				const potActionAvailable = CommonService.checkTasksPagePermission(this.$can, { pot: item.pot });
				return {
					title: item.title,
					description: this.getPotDescription(item.pot, item.description),
					pot: item.pot,
					actionAvailable: potActionAvailable,
					actionTooltip: potActionAvailable ? null : 'Role permissions prevent access',
				};
			});
		},
		onClose () {
			this.$emit('close');
		},
		async onSelectTab (value) {
			this.activeTab = this.$options.tabItems.find(item => item.value === value);
			if (this.activeTab.value === 'teams') {
				this.teams = await teamsService.getTeams();
			}
		},
		async onSelectQueueAction (action, queue) {
			switch (action) {
			case QueuesActions.view.value: {
				const routerPushData = {
					name: 'tasks', params: { pot: queue.pot },
				};
				if (queue.id) {
					routerPushData.params.queueId = queue.id;
					routerPushData.params.queueName = queue.title;
				}
				this.$router.push(routerPushData);
				this.onClose();
				break;
			}
			case QueuesActions.rename.value: {
				this.selectedQueue = queue;
				this.$modal.show('save-queue-modal');
				break;
			}
			case QueuesActions.delete.value: {
				this.selectedQueue = queue;
				const userChoise = await this.$refs.deleteQueueModal.show();
				if (userChoise === 'yes') {
					await WorkflowService.deleteQueue(queue.id);
					await this.loadData();
					eventBus.$emit('task_queue-deleted', { queueId: queue.id });
				}
				break;
			}
			default: break;
			}
		},
		async openPot ({ pot, title }) {
			this.selectedPotTitle = title;
			this.currentPot = pot;
			await this.loadData();
		},
		async onRenameQueue (queueName) {
			try {
				await WorkflowService.updateQueueDetails(this.selectedQueue.id, queueName, [ErrorCode.DuplicationErrorCode]);
				eventBus.$emit('task_queue-renamed', { queueId: this.selectedQueue.id, queueName });
				this.$modal.hide('save-queue-modal');
				await this.loadData();
			} catch (error) {
				if (isKnownError(error, ErrorCode.DuplicationErrorCode)) {
					this.isQueueNameUniq = false;
				}
			}
		},
		async loadData () {
			const options = await WorkflowService.getQueues(this.currentPot);
			this.queuesList = [
				{
					id: 0,
					actions: [QueuesActions.view],
					pot: this.currentPot,
					title: UnfilteredTasksQueueName,
					details: 'System Generated',
					description: 'Queue',
				}];
			if (options.userOptions.filter(option => !option.readOnly).length > 0) {
				const queues = options.userOptions.filter(uo => uo.name !== UnfilteredTasksQueueName) //
					.map(option => {
						const actions = [QueuesActions.view];
						if (!option.readOnly) {
							if (this.$can.CreateQueue) {
								actions.push(QueuesActions.rename);
							}

							if (this.$can.DeleteQueue) {
								actions.push(QueuesActions.delete);
							}
						}

						return {
							id: option.id,
							actions,
							pot: this.currentPot,
							title: option.name,
							createdBy: option.createdBy,
							createdDateTime: option.createdDateTime,
							updatedBy: option.updatedBy,
							updatedDateTime: option.updatedDateTime,
							description: 'Queue',
							isPrioritized: option.isPrioritized,
						};
					});
				this.queuesList.push(...queues);
			}
		},
		lifetimeInfo (queue) {
			return lifetimeInfo(queue);
		},
		lifetimeInfoTooltip (queue) {
			return lifetimeInfoToolTip(queue);
		},
		async onCreateTeam () {
			this.selectedTeamId = null;
			this.openTeamProfile = false;
			await this.$nextTick();
			this.openTeamProfile = true;
		},
		onCloseTeamProfile () {
			this.selectedTeamId = null;
			this.openTeamProfile = false;
		},
		async onSaveTeam () {
			this.onCloseTeamProfile();
			this.teams = await teamsService.getTeams();
		},
		async onSelectTeamAction ({ action, team }) {
			switch (action) {
			case TeamsActions.view.value: {
				this.openTeamProfile = false;
				await this.$nextTick();
				this.selectedTeamId = team.id;
				this.openTeamProfile = true;
				break;
			}
			case TeamsActions.delete.value: {
				const userChoise = await this.$refs.deleteTeamModal.show();
				if (userChoise === 'yes') {
					await teamsService.deleteTeam(team.id);
					this.teams = await teamsService.getTeams();
					this.onCloseTeamProfile();
				}
				break;
			}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .panel-container {
    max-width: $tasksPanelWidth;
    animation: slide 0.5s forwards;
  }

  .content-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
    padding-right: 10px;
    padding-left: 5px;
  }

  ::v-deep .queue-card {
    & > div {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    &.clickable-name {
      .name-info span {
        cursor: pointer;
      }
    }
  }

  .auto-assign-queue-logo {
    position: absolute;
    top: -5px;
    left: 35px;
    outline: none;
    fill: var(--cardIconColour);
  }
</style>
