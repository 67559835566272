<template>
	<div>
		<div class="header-wrapper">
			<h1>Search</h1>
			<p class="pt-8">
				It’s hard to find a record without an eye-catching filter available
			</p>
		</div>
		<div>
			<div class="section-header pl-45 mr-25">
				<div class="section-header__title">
					Search Results
				</div>
			</div>
			<div
				class="section-content mx-25"
				v-shortkey="$shortkeys.proceed"
				@shortkey="onOpenDetails"
			>
				<div class="container-fluid">
					<aq-list-navigation-control
						:list="customers"
						:current-item="currentItem"
						@current-changed="onSelect"
					>
						<template #default="slotProps">
							<el-table
								:data="slotProps.list"
								style="width: 100%;"
								empty-text="The table is empty"
								fit
								@row-click="onSelect"
								:row-class-name="getRowClassName"
							>
								<el-table-column label="Brand">
									<template #default="scope">
										<truncated-text-cell
											:value="scope.row.brand"
										/>
									</template>
								</el-table-column>
								<el-table-column label="First Name">
									<template #default="scope">
										<truncated-text-cell
											:value="scope.row.firstName"
										/>
									</template>
								</el-table-column>
								<el-table-column label="Last Name">
									<template #default="scope">
										<truncated-text-cell
											:value="scope.row.lastName"
										/>
									</template>
								</el-table-column>
								<el-table-column label="Pets Name">
									<template #default="scope">
										<truncated-text-cell
											:value="getPetNamesCombined(scope.row.pets)"
										/>
									</template>
								</el-table-column>
								<el-table-column label="Email">
									<template #default="scope">
										<truncated-text-cell :value="scope.row.email" />
									</template>
								</el-table-column>
								<el-table-column
									prop="phoneNumber"
									label="Phone"
								/>
								<el-table-column
									width="150"
									prop="postalCode"
									label="Post Code"
								/>
								<el-table-column
									width="140"
									v-if="$can.ViewCustomerAndPet"
								>
									<template #default="scope">
										<button
											class="btn btn-row"
											@click="onGoToDetails(scope.row.id)"
										>
											View
										</button>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</aq-list-navigation-control>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';
import { unique } from '@commonServices/utils/general';

export default {
	name: 'AqSearchResult',
	mixins: [rowHighlightMixin],
	props: {
		customers: {
			type: Array,
			required: true,
		},
	},
	data () {
		return {};
	},
	mounted () {
		this.currentItem = this.customers[0];
	},
	methods: {
		onGoToDetails (id) {
			this.$router.push({ name: 'customer', params: { customerId: id } });
		},
		onOpenDetails () {
			this.onGoToDetails(this.currentItem.id);
		},
		getPetNamesCombined (petPolicyData) {
			const petNames = petPolicyData.map(entry => entry.name);
			return unique(petNames).join(', ');
		},
	},
};
</script>
