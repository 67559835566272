<template>
	<div
		v-if="latestAction"
		class="d-flex align-items-center popover"
	>
		<div class="col-1 pl-10 pt-4">
			<i :class="latestAction.icon" />
		</div>
		<div class="text-truncate font-weight-bold col ml-10 mr-5 pr-0">
			{{ latestAction.label }}
		</div>
		<div class="col-3 px-0 d-flex justify-content-end">
			<aq-details-popover
				v-if="latestAction.title && latestAction.items.length"
				class="px-15"
				:width="430"
				placement="top-end"
				:distance="-37"
			>
				<template #trigger="triggerProps">
					<slot :opened="triggerProps.popupOpened">
						<aq-more-less-toggler :opened="triggerProps.popupOpened" />
					</slot>
				</template>
				<template #title>
					<slot>
						<div class="d-flex align-items-center">
							<div class="mr-10">
								<slot name="popoverIcon" />
							</div>
							<div class="font-weight-bold">
								{{ latestAction.title }}
							</div>
						</div>
					</slot>
				</template>
				<template #customText>
					<slot>
						<div v-if="latestAction.items.length">
							<ul :class="[latestAction.items.length === 1 ? 'list-unstyled': 'ml-15']">
								<li
									v-for="item of latestAction.items"
									:key="item.value"
								>
									<div>
										<p>{{ item.value }}</p>
										<p>{{ item.comment }}</p>
									</div>
								</li>
							</ul>
							<br>
						</div>
					</slot>
				</template>
			</aq-details-popover>
		</div>
	</div>
</template>

<script>
import AqDetailsPopover from '@commonView/Shared/AqDetailsPopover';

export default {
	name: 'AqTaskDetailsPopover',
	components: {
		AqDetailsPopover,
	},
	props: {
		interaction: {
			type: Object,
			required: false,
			default: null,
		},
		note: {
			type: Object,
			required: false,
			default: null,
		},
	},
	computed: {
		latestAction () {
			const interaction = this.interaction;
			const note = this.note;

			if (!interaction && !note) return null;
			if (!interaction) return this.getDashboardNote(note);
			if (!note) return this.getTaskInteraction(interaction);

			return interaction.actionDateTime > note.lastActionDateTime
				? this.getTaskInteraction(interaction) : this.getDashboardNote(note);
		},
	},
	methods: {
		getDashboardNote (note) {
			return {
				title: note.title,
				items: [{ value: note.details }],
				label: note.details,
				icon: 'fa fa-comments fs-26',
			};
		},
		getTaskInteraction (interaction) {
			const title = interaction.value;
			const items = interaction.items ?? [];

			return {
				title,
				items,
				label: items?.length ? `${title}: ${items.map(i => i.value).join(', ')}` : title,
				icon: 'aqv-dashboard-comment fs-26',
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.popover {
  background: var(--footerBg);
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
</style>
