<template>
	<div
		v-if="source.empty"
		class="interaction"
	/>
	<div
		v-else
		class="interaction"
		:class="{ 'live': source.live, 'dead': source.dead }"
		@click="onClick"
	>
		<aq-card
			:name="source.value"
			:description="source.description"
			:user="source.user"
			description-class="fs-12 text-truncate-ml-3"
			:arrow-class="source.arrowDirectionIcon"
			:type="source.iconType"
			:icon-tooltip="iconTooltip"
			@click-name="downloadFile"
			@icon-click="onIconClick"
			:icon-svg-component="interactionIconComponent"
			:icon-class="source.externalDocUrl || isFileExist ? 'cursor-pointer' : ''"
			:class="source.externalDocUrl || (isLoadable && isFileExist) ? 'clickable-name' : ''"
		>
			<template #subName>
				<p class="fs-12 my-3">
					Created {{ source.dateTime | shortDateTime }}
				</p>
			</template>
			<template
				#content
				v-if="source.pet"
			>
				<div class="bottom mt-2 py-10 px-20 d-flex align-items-center">
					<div
						class="flex-grow-1 text-truncate text-subtitle fs-12"
						v-tooltip.top-start="ailmentLabel"
					>
						{{ ailmentLabel }}
					</div>
					<div
						v-if="source.details"
						class="flex-shrink-0 card-icon small text-danger cursor-pointer"
						@click="onSeeMoreDetails"
					>
						<i class="fas fa-list" />
					</div>
					<router-link
						v-if="source.claimId"
						:to="{ name: 'claim-page', params: { customerId: source.pet.customerId, claimId: source.claimId } }"
						exact-active-class="current-route"
					>
						<div class="flex-shrink-0 card-icon small">
							<i
								class="fas fa-file-invoice"
								v-tooltip="claimIdFormat(source.claimId)"
							/>
						</div>
					</router-link>
					<linked-claims
						v-if="source.linkedClaims.length"
						class="mr-10"
						:linked-claims="source.linkedClaims"
					/>
					<router-link
						v-if="source.pet"
						:to="{ name: 'pet', params: { customerId: source.pet.customerId, petId: source.pet.id } }"
						exact-active-class="current-route"
					>
						<aq-pet-avatar
							:pet="source.pet"
							class="small flex-shrink-0 cursor-inherit"
						/>
					</router-link>
				</div>
			</template>
			<template #description>
				<aq-tooltip-paragraph
					v-if="source.description"
					description-class="fs-12 text-truncate-ml-3"
					:description="source.description"
				/>
				<aq-tooltip-paragraph
					v-if="source.vetName"
					description-class="fs-12 text-truncate-ml-3"
					:description="source.vetName"
				/>
			</template>
		</aq-card>
	</div>
</template>

<script>
import FileService from '@commonServices/fileService';
import { interactionType } from '@commonServices/models/InteractionTypeEnum';
import { claimIdFormat } from '@commonServices/utils/filters';
import { ReassessedClaimOpen, ReassessedClaimClosed, FileIconInboundEmail, ClaimBotRequestIcon } from '@assets/icons';
import { getFileExtension, MimeTypes } from '@commonServices/utils/general';
import LinkedClaims from '@commonView/Shared/LinkedClaims';

export default {
	name: 'AqInteraction',
	components: {
		LinkedClaims,
	},
	props: {
		source: {
			type: Object,
			required: true,
		},
		onShowDetails: {
			type: Function,
			required: true,
		},
		onSelect: {
			type: Function,
			required: true,
		},
		onPreviewInteraction: {
			type: Function,
			required: true,
		},
	},
	data () {
		return {
			value: undefined,
			fileName: undefined,
			removed: false,
		};
	},
	computed: {
		interactionIconComponent () {
			switch (this.source.actionType) {
			case interactionType.ClaimReassessment:
				return ReassessedClaimOpen;
			case interactionType.ClaimReassessmentCancelled:
				return ReassessedClaimClosed;
			case interactionType.InboundEmailDocument:
				return FileIconInboundEmail;
			case interactionType.ClaimBotRequest:
			case interactionType.ClaimBotResponse:
			case interactionType.ClaimBotResponseApproval:
			case interactionType.ClaimBotResponseDenial:
			case interactionType.ClaimBotResponseFailed:
				return ClaimBotRequestIcon;
			default:
				return null;
			}
		},
		iconTooltip () {
			return this.source.fileId ? 'Click to preview interaction content' : '';
		},
		ailmentLabel () {
			if (!this.source.cause) {
				return this.source.claimId ? 'No ailment' : '';
			}
			return `${this.source.cause} for ${this.source.pet.name}`;
		},
		isLoadable () {
			return this.source.actionType !== interactionType.ChangedClaimStatus
					&& this.source.actionType !== interactionType.ClaimReassessment;
		},
		isFileExist () {
			return !!this.source.fileId;
		},
	},
	methods: {
		onSeeMoreDetails () {
			this.onShowDetails(this.source);
		},
		async downloadFile () {
			if (this.source.externalDocUrl) {
				window.open(this.source.externalDocUrl, '_blank');
			} else if (this.isLoadable && this.isFileExist) {
				await FileService.downloadFile(this.source.fileId, this.source.fileName, MimeTypes[getFileExtension(this.source.fileName)]);
			}
		},
		onClick () {
			this.onSelect(this.source);
		},
		onIconClick () {
			if (this.source.externalDocUrl) {
				window.open(this.source.externalDocUrl, '_blank');
			} else {
				this.onPreviewInteraction(this.source);
			}
		},
		claimIdFormat,
	},
};
</script>

<style lang="scss" scoped>
.interaction {
  position: relative;
  height: 130px;

  &.live {
    animation: slidein 0.5s ease;
  }

  &.dead {
    animation: slideout 0.5s ease;
  }

  @keyframes slidein {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes slideout {
    from {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0);
      opacity: 0;
    }
  }

  ::v-deep .card {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > div {
      padding: 5px 13px !important;
    }

    & > div:first-child {
      flex-grow: 1;
    }

    .card-main-content {
      padding-top: 0;
    }

    p {
      line-height: 1.2;
    }

    .icon-avatar {
      background: rgba($primary, 0.2);
    }

    &.clickable-name {
      .name-info span {
        cursor: pointer;
      }
    }
  }

  .card-icon {
    i {
      font-size: 14px;
    }
  }

  .card-icon.small {
    background: rgba($primary, 0.2);
  }

  .attribute {
    &.document {
      cursor: pointer;
    }

    .info {
      flex: 1;

      .name {
        font-size: 13px;
        font-weight: bold;
      }

      p {
        font-size: 11px;
        line-height: 15px;
      }
    }

    &:hover {
      .info .name,
      .type {
        color: $primary;
        transition: 0.3s ease;
      }
    }

    i {
      font-size: 19px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  .bottom {
    gap: 10px;
  }

  ::v-deep .linked-icon {
    i {
      font-size: 14px;
    }
  }
}

.current-route {
  cursor: default;
}

</style>
