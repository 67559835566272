<template>
	<aq-panel-wrapper>
		<div class="panel-container panel-container--left">
			<aq-confirmation-modal
				ref="deleteFilterModal"
				name="delete-filterset-modal"
				title="Delete Filter"
				:description="`You are about to delete '${filterForDeletion.name}' filter. Do you wish to continue?`"
				yes-label="Delete"
				no-label="Cancel"
				data-qa="letters-panel_modal_delete-filterset"
			/>
			<div class="panel panel--left d-flex flex-column">
				<div>
					<div class="section-header">
						<div class="section-header__title">
							Letter Queue
						</div>
						<button
							class="btn btn-row ml-auto"
							data-qa="lettersPanel_button_close"
							@click="onClose"
						>
							Close
						</button>
					</div>
				</div>
				<aq-tab-selector
					:items="$options.tabItems"
					:active-tab="activeTab"
					@select-tab="onSelectTab"
					data-qa="lettersPanel_select_letterType"
					class="flex-grow-1 d-flex flex-column"
				>
					<div class="section-content flex-grow-1 d-flex flex-column">
						<div class="content-wrapper">
							<div v-if="activeTab.value === 'systemLetters'">
								<aq-card
									name="All Pending Letters"
									:description="pendingDescription"
									type="letters"
									:action="$options.letterAction"
									btn-icon-class="fa-folder-open"
									@select-action="openPage('letters')"
									class="mb-10 letter-card"
								/>
								<aq-card
									name="Zipped Documents"
									:description="zippedDescription"
									type="zipped"
									:action="$options.letterAction"
									btn-icon-class="fa-folder-open"
									@select-action="openPage('zippedLetters')"
									class="mb-10 letter-card"
								/>
							</div>
							<template v-else>
								<aq-card
									v-for="userFilter of userFilters"
									:key="userFilter.id"
									:name="userFilter.name"
									type="letters"
									:action="menuOptionAction"
									@select-action="onUserTabSelectAction($event,userFilter)"
									data-qa="lettersPanel_card_user"
									class="mb-10 letter-card"
								>
									<template #content>
										<div class="bottom mt-2 py-10 px-20 d-flex justify-content-between">
											<div class="text-subtitle fs-13 d-flex align-items-center">
												<span v-tooltip="lifetimeInfoTooltip(userFilter)">
													{{ lifetimeInfo(userFilter) }}
												</span>
											</div>
											<aq-icon-avatar
												class="small"
												:value="[userFilter.createdBy.firstName, userFilter.createdBy.lastName]"
											/>
										</div>
									</template>
								</aq-card>
							</template>
						</div>
					</div>
				</aq-tab-selector>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
import LettersService from '@commonServices/lettersService';
import { pluralize, shortDateTime } from '@commonServices/utils/filters';
import eventBus from '@commonServices/eventBus';

const userFilterActions = {
	view: { name: 'View', value: 'view', iconClass: 'fa-eye text-primary' },
	delete: { name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' },
};
export default {
	data () {
		return {
			activeTab: this.$options.tabItems[0],
			pendingCount: 0,
			zippedCount: 0,
			userFilters: [],
			filterForDeletion: {},
		};
	},
	tabItems: [
		{
			value: 'systemLetters',
			label: 'System',
		},
		{
			value: 'userLetters',
			label: 'User',
		},
	],
	letterAction: { label: 'Open', value: 'open' },
	async mounted () {
		const [pending, zipped, filterSet] = await Promise.all([LettersService.getPendingLettersCount(), LettersService.getZippedLettersCount(), LettersService.getMenuOptions()]);
		this.pendingCount = pending;
		this.zippedCount = zipped;
		this.userFilters = filterSet.userOptions;

		this.menuOptionAction = [userFilterActions.view];
		if (this.$can.ManageLetters) {
			this.menuOptionAction.push(userFilterActions.delete);
		}
	},
	computed: {
		pendingDescription () {
			return `${this.pendingCount} ${pluralize('Letter', this.pendingCount)}`;
		},
		zippedDescription () {
			return `${this.zippedCount} Zipped ${pluralize('File', this.zippedCount)} `;
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
		},
		onSelectTab (value) {
			this.activeTab = this.$options.tabItems.find(item => item.value === value);
		},
		async onUserTabSelectAction (action, userFilter) {
			switch (action) {
			case userFilterActions.view.value: {
				this.openPage('letters', { filterSetId: userFilter.id });
				break;
			}
			case userFilterActions.delete.value: {
				this.filterForDeletion = userFilter;
				const userChoise = await this.$refs.deleteFilterModal.show();
				if (userChoise === 'yes') {
					await LettersService.deleteUserFilterSet(userFilter.id);
					this.userFilters = this.userFilters.filter(uf => uf.id !== userFilter.id);
					eventBus.$emit('letters-user-filter-deleted', { userFilterId: userFilter.id });
				}

				break;
			}
			default: break;
			}
		},
		openPage (routeName, params) {
			this.$router.push({ name: routeName, params });
			this.onClose();
		},
		lifetimeInfo (filterSet) {
			return filterSet.updatedBy ? `Last Updated ${shortDateTime(filterSet.updatedDateTime)}` : `Created ${shortDateTime(filterSet.createdDateTime)}`;
		},
		lifetimeInfoTooltip (filterSet) {
			return filterSet.updatedBy ? `Created ${shortDateTime(filterSet.createdDateTime)}` : '';
		},
	},
};
</script>

<style lang="scss" scoped>
  .content-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
    padding-right: 10px;
  }
</style>
