<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Generate Notification"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-12 pb-12 text-center">
								<i class="aqv-bell fs-32" />
							</div>
						</div>
						<div class="row no-gutters">
							<div class="col-12 pb-12">
								<aq-form-input
									type="textarea"
									v-model="v$.message.$model"
									:disabled="!canGenerateNotification"
									:number-visible-lines="10"
									:is-valid="!v$.message.$error"
								/>
								<aq-form-input-error
									class="error mt-8"
									:validator="v$.message"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onSend"
						:disabled="v$.$invalid || !canGenerateNotification"
						type="button"
						class="btn btn-success ml-auto"
					>
						<div v-tooltip="canGenerateNotification ? '' : 'Hospital Software SLA Notification has already been generated'">
							Send
						</div>
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import { useVuelidate } from '@vuelidate/core';
import { ValidationMessages } from '@commonServices/utils/constants';
import { required, maxLength, withMessage } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		canGenerateNotification: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	data () {
		return {
			message: 'This claim will take more than 5 minutes to process. If you need to update the payment information for this claim, please click the button below.',
		};
	},
	validations: {
		message: {
			required: withMessage(ValidationMessages.required, required),
			maxLength: withMessage(ValidationMessages.maxLength2000, maxLength(2000)),
		},
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		async onSend () {
			await ClaimService.generateHospitalNotification(this.claimId, this.message);
			this.$modal.hide(this.$parent.name);
			this.$emit('hospital-notification-generated');
		},
	},
};
</script>
