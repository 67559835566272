<template>
	<div v-if="$can.ViewCustomerAndPet">
		<div
			class="pt-5"
			v-if="customer"
		>
			<AqCardModeHeader :title="'Customer Details'" />
			<div
				class="row customer-info-wrapper"
				:class="{'special-highlight-card': requireSpecialLicensing(customer.address.regionCounty)}"
			>
				<bordered-button-wrapper
					v-if="customer"
					:btn-label="customerBtnLabel"
					class="col-xl-4 col-lg-6"
					@btn-click="onHandleEditCustomer"
				>
					<template #content>
						<customer-card-info :user="customer" />
					</template>
				</bordered-button-wrapper>
				<bordered-button-wrapper
					v-if="registeredVet"
					:btn-label="customerBtnLabel"
					class="col-xl-4 col-lg-6"
				>
					<template #content>
						<registered-vet-header :registered-vet="registeredVet" />
						<registered-vet-content
							v-if="isExpandCardMode"
							:registered-vet="registeredVet"
						/>
					</template>
				</bordered-button-wrapper>
			</div>
		</div>
		<div class="container-fluid">
			<div
				v-shortkey="{
					create: $shortkeys.createClaim,
					view: $shortkeys.proceed,
				}"
				@shortkey="onCreateOrViewClaim"
			>
				<aq-list-navigation-control
					:list="pets"
					:current-item="currentItem"
					@current-changed="onSelect"
					class="pet-layout row"
				>
					<template #default="slotProps">
						<div
							v-for="(pet, index) of slotProps.list"
							:key="index"
							:class="{ 'col-xl-6': slotProps.list.length < 3 }"
							class="col-xl-4 col-lg-6"
						>
							<pet-card
								:pet="pet"
								:class="[
									currentItem === pet ? 'border border-warning' : 'unselected-pet-card cursor-pointer',
									'mt-30 border-invisible',
								]"
								:locale="locale"
								:selected-policy-number="selectedPolicyNumber"
								@view-click="onViewPet(index)"
								@click.native="onSelect(pet)"
							/>
						</div>
					</template>
				</aq-list-navigation-control>
			</div>
		</div>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import CustomerService from '@commonServices/customerService';
import { mapActions, mapState, mapGetters } from 'vuex';
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import BorderedButtonWrapper from '@commonView/CustomerPage/PetsLayout/BorderedButtonWrapper';
import { paServiceInstance } from '@commonServices/plugins/policyAdminNavigateService';
import RegisteredVetHeader from '@commonView/Shared/RegisteredVet/RegisteredVetHeader.vue';
import RegisteredVetContent from '@commonView/Shared/RegisteredVet/RegisteredVetContent.vue';
import AqCardModeHeader from '@commonWidgets/AqCardModeHeader';

export default {
	components: {
		BorderedButtonWrapper,
		RegisteredVetHeader,
		RegisteredVetContent,
		AqCardModeHeader,
	},
	mixins: [rowHighlightMixin],
	data () {
		return {
			pets: [],
			customer: null,
			customerId: undefined,
			registeredVet: null,
			selectedPolicyNumber: null,
		};
	},
	async mounted () {
		this.customerId = parseInt(this.$route.params.customerId);
		this.customer = await CustomerService.getCustomerInformation(
			this.customerId,
		);

		this.pets = this.customer.pets;
		this.changeInteractionContext({
			customer: this.customer,
			pet: null,
			claim: null,
		});

		const selectedPet = this.pets.find(pet => pet.id === parseInt(this.$route.params.petId));
		if (selectedPet) {
			this.onSelect(selectedPet);
			this.selectedPolicyNumber = this.$route.params.policyNumber;
		}
	},
	computed: {
		...mapState(['shortKeyEnabled', 'appSettings']),
		...mapGetters(['requireSpecialLicensing', 'isExpandCardMode']),
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		customerBtnLabel () {
			return (this.$can.PolicyAdminUpdateCustomer && ClaimsConfig.PA_ENABLED && 'Edit Customer');
		},
	},
	methods: {
		...mapActions(['changeInteractionContext']),
		async onViewPet (index) {
			const pet = this.pets[index] || null;
			this.goToPetPage(pet ? pet.id : null);
		},
		onCreateClaim () {
			ClaimService.createClaim(this.currentItem.id).then((data) =>
				this.$router.push({
					name: 'claim-page',
					params: { customerId: this.customerId, claimId: data },
				}),
			);
		},
		goToPetPage (petId) {
			this.$router.push({
				name: 'pet',
				params: { customerId: this.customerId, petId },
			});
		},
		onSelect (pet) {
			this.currentItem = pet;
			this.registeredVet = pet.registeredVets.find(rv => !rv.isHistorical);
			this.changeInteractionContext({
				pet: this.currentItem,
				claim: null,
			});
		},
		async onCreateOrViewClaim (event) {
			const index = this.pets.findIndex((item) => item === this.currentItem);
			if (index === -1) {
				return;
			}
			if (event.srcKey === 'view') {
				await this.onViewPet(index);
			} else if (event.srcKey === 'create') {
				this.onCreateClaim();
			}
		},
		onHandleEditCustomer () {
			paServiceInstance.navigateCustomerPage(this.customerId);
		},
	},
};
</script>

<style lang="scss" scoped>
.border-invisible {
  border: 1px solid transparent;
}

.customer-info-wrapper {
  background: var(--contentBg);
  padding: 15px 15px 30px;
  display: flex;
  flex-basis: auto;
}

::v-deep .care-flag {
  margin-right: 2.4px !important;
}

::v-deep .unselected-pet-card .product-tab {
  .product-tab-icon {
    cursor: pointer;
  }
}
</style>
