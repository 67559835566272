<template>
	<VDropdown
		:placement="placement"
		:popper-class="['table-popover', popoverClass, !showArrow && 'tooltip-arrow-hidden']"
		:disabled="disabled"
		:shown="open"
		@hide="$emit('hide')"
		:boundary="getBoundariesElement()"
	>
		<div class="d-flex align-items-center">
			<slot name="title" />
		</div>
		<template #popper>
			<slot />
			<div
				class="d-flex close-button justify-content-center align-items-center"
				aria-label="Close"
				v-close-popper
			>
				<i class="fas fa-times text-bold" />
			</div>
		</template>
	</VDropdown>
</template>

<script>

export default {
	name: 'AqTablePopover',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		boundariesElementSelector: {
			type: String,
			required: false,
			default: '#app',
		},
		showArrow: {
			type: Boolean,
			default: true,
		},
		placement: {
			type: String,
			default: 'top',
		},
		popoverClass: {
			type: String,
			required: false,
			default: null,
		},
	},
	methods: {
		getBoundariesElement () {
			return this.boundariesElementSelector ? document.querySelector(this.boundariesElementSelector) : null;
		},
	},
};
</script>

<style lang="scss">
.table-popover {
  box-shadow: 0 3px 10px #0008;

  .v-popper__inner {
    overflow: visible;
    background-color: var(--contentBg);
    border-radius: 0;
    border: 3px solid var(--headerBg);
  }

  .v-popper__arrow-outer,
  .v-popper__arrow-inner {
    border-color: var(--headerBg);
  }

  table {
    background: var(--bodyBg);
    border: none;
    width: 100%;
    table-layout: fixed;

    th {
      background: var(--headerBg);
      color: var(--userIcon);
      padding: 5px 10px;
    }

    th:not(:last-child) {
      border-right: 1px solid var(--userIcon);
    }

    tbody {
      td {
        padding: 0 10px;
        white-space: nowrap;
      }

      td.td-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 0;
      }

      tr {
        height: 12px;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid var(--userIcon);
      }

      td:not(:last-child) {
        border-right: 1px solid var(--userIcon);
      }
    }
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -7px;
    background: var(--userIcon) 0% 0% no-repeat padding-box;
    border: 1px solid var(--cardIconSVG);
    color: var(--logoBg);
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
}
</style>
