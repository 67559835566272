<template>
	<VDropdown
		:placement="placement"
		class="details-popover-component"
		:popper-class="['details-popover', 'tooltip-arrow-hidden', popoverClass]"
		@hide="popupOpened = false"
		@show="popupOpened=true"
		:distance="distance"
	>
		<slot
			name="trigger"
			:popup-opened="popupOpened"
		>
			<div class="details-popover-target link text-primary fs-13">
				Show more
			</div>
		</slot>
		<template #popper>
			<div :style="{ width: width + 'px' }">
				<div class="header">
					<div class="header-title">
						<slot name="title">
							Comment
						</slot>
					</div>
					<div class="header-actions">
						<slot name="actions">
							<button
								v-close-popper
								class="btn btn-primary"
							>
								Close
							</button>
						</slot>
					</div>
				</div>
				<div class="details-popover-content content-wrapper scrollbar">
					<slot name="customText">
						<div>
							{{ flattenedText }}
						</div>
					</slot>
				</div>
			</div>
		</template>
	</VDropdown>
</template>

<script>

export default {
	name: 'AqDetailsPopover',
	props: {
		data: {
			type: String,
			required: false,
			default: '',
		},
		width: {
			type: Number,
			required: false,
			default: 300,
		},
		placement: {
			type: String,
			required: false,
			default: 'auto',
		},
		popoverClass: {
			type: String,
			required: false,
			default: null,
		},
		distance: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			popupOpened: false,
		};
	},
	computed: {
		flattenedText () {
			return this.data?.replaceAll('\\n', '\n').replaceAll('\\r', '\r');
		},
	},
};
</script>

<style lang="scss" scoped>
.details-popover-component {
  flex-shrink: 0;
}
</style>

<style lang="scss">

.details-popover {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;

  .v-popper__inner {
    border-radius: 2px;
    padding: 6px;
    background: var(--contentBg);
    border: 2px solid var(--modalActive);
    color: var(--bodyColour);
  }

  .header {
    background: var(--modalHeaderBg);
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      min-width: 0;
    }

    .header-actions {
      margin-left: 5px;
    }
  }

  .details-popover-content {
    max-height: 150px;
    overflow: hidden auto;
    margin: 6px;

    &.scrollbar {
      padding-right: 5px;
    }
  }
}
</style>
