<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-12 pb-12">
								Please select an export type.
							</div>
							<div class="col-12 pb-12">
								<aq-select
									label="Select Type"
									:options="exportTypes"
									option-label="description"
									v-model="v$.selectedExportType.$model"
								>
									<template #default="{ option }">
										<div class="px-10">
											<div class="select-option-header text-truncate">
												{{ option.description }}
											</div>
										</div>
									</template>
								</aq-select>
								<aq-form-input-error
									class="error mt-8"
									:validator="v$.selectedExportType"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onExport"
						:disabled="v$.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Export
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { exportTypes } from '@commonServices/models/ExportType';
import { required, withMessage } from '@clientCommon/services/utils/validators';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	data () {
		return {
			selectedExportType: null,
			exportTypes,
		};
	},
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	validations: {
		selectedExportType: {
			required: withMessage('The export type should be selected', required),
		},
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onExport () {
			this.$emit('export', { type: this.selectedExportType });
		},
	},
};
</script>
