<template>
	<div class="claim-from-scan h-100 pr-5 d-flex flex-column">
		<aq-confirmation-modal
			ref="populateDialog"
			name="populate-dialog"
			title="Overwrite existing data?"
			paragraph="Please confirm if you want to proceed"
			:description="populateDialogDescription"
			yes-label="Proceed"
			no-label="Cancel"
		/>
		<template v-if="scannedResult">
			<PolicyInformationScan
				v-if="isPolicyInformationSelected"
				ref="policy"
				:is-readonly="isScanReadonly"
				:customer-name-data="scannedResult.customerName"
				:pet-name-data="scannedResult.petName"
				:recognized-data="scannedResult"
				:is-active-on-start="true"
				@changed="isPolicyInformationPassed = false"
				@passed="onPolicyInformationPassed"
				@focused="$emit('highlight-doc', $event)"
			/>
			<multi-condition-selector
				v-if="scannedResult.isMultiCondition"
				@conditionOneClicked="onSelectConditionOneClicked"
				@conditionTwoClicked="onSelectConditionTwoClicked"
				:linked-claim-created="linkedClaimCreated"
				:condition-data="{ claimId:conditionData.claimId, ailment:conditionData.ailment.value }"
				:second-condition-data="secondConditionData ? { claimId:secondConditionData.claimId, ailment:secondConditionData.ailment.value } : undefined"
			/>
			<claim-payee-scan
				v-if="isPayeeSectionAvailable"
				ref="payeeInformation"
				:header="'Payee Information'"
				:recognized-data="scannedResult"
				:is-readonly="isReadonlyConditionData(conditionData)"
				:locale="locale"
				@changed="isPayeeInformationPassed = false"
				@passed="onPayeePassed"
				@focused="$emit('highlight-doc', $event)"
			/>
			<claim-basic-scan
				v-if="isConditionOneSelected"
				ref="basic"
				:header="conditionOneHeader"
				:recognized-data="conditionData"
				:is-readonly="isReadonlyConditionData(conditionData)"
				:locale="locale"
				@changed="isBasicPassed = false"
				@passed="onBasicPassed"
				@focused="$emit('highlight-doc', $event)"
				@form-type-updated="onFormTypeUpdated"
			/>
			<claim-basic-scan
				v-if="isConditionTwoSelected"
				ref="secondCondition"
				:header="'Condition Two'"
				:recognized-data="secondConditionData"
				:is-readonly="isReadonlyConditionData(secondConditionData)"
				:is-active-on-start="isActiveConditionData(secondConditionData)"
				:locale="locale"
				@changed="isSecondConditionPassed = false"
				@passed="onSecondConditionPassed"
				@focused="$emit('highlight-doc', $event)"
				@form-type-updated="onFormTypeUpdated"
			/>
			<claim-vet-scan
				v-if="isVetSectionAvailable"
				ref="vet"
				:is-readonly="isScanReadonly"
				:veterinary-practice-name="scannedResult.veterinaryPracticeName"
				@changed="isVetPassed = false"
				@passed="onVetPassed"
				@focused="$emit('highlight-doc', $event)"
			/>
			<div
				v-if="isAllSectionsPassed && !isReadOnlyMode"
				class="populate-data text-center mt-40"
			>
				<div><i class="fas fa-thumbs-up text-success--lighten fs-32" /></div>
				<p class="mt-20 fs-14">
					You have successfully validated the extracted form data. Would you like to populate your claim with this data?
				</p>
				<button
					v-if="$can.PopulateClaimForm"
					class="btn btn-primary mt-20 btn-radius--less d-block mx-auto"
					@click="onPopulateClaim"
				>
					Populate Claim
				</button>
			</div>
		</template>
	</div>
</template>

<script>
import eventBus from '@commonServices/eventBus';
import cloneDeep from 'lodash.clonedeep';
import ClaimService from '@commonServices/claimService';
import ScanService from '@commonServices/scanService';
import { mapState } from 'vuex';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError } from '@commonServices/utils/general';
import { emptyDocumentScanResult } from '@commonServices/utils/documentScanConverter';
import PolicyInformationScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/PolicyInformationScan';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';

export default {
	inject: ['hideDocumentPanel'],
	components: {
		PolicyInformationScan,
	},
	data () {
		return {
			isBasicPassed: false,
			isPayeeInformationPassed: false,
			isSecondConditionPassed: false,
			isPolicyInformationPassed: false,
			isVetPassed: false,
			isPolicyInformationSelected: true,
			isConditionOneSelected: true,
			isConditionTwoSelected: false,
			scannedResult: undefined,
			conditionData: undefined,
			secondConditionData: undefined,
			linkedClaimCreated: false,
			finalResult: {
				payeeInformation: null,
				conditionData: null,
				secondConditionData: null,
				policy: null,
				vet: null,
				fileId: null,
			},
			selectedFormType: null,
			populateDialogDescription: 'This will overwrite any existing claim data that has already been inputted. Are you sure you want to proceed?',
		};
	},
	props: {
		file: {
			type: Object,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		claimId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	async mounted () {
		if (this.file.externalDocUrl == null) {
			try {
				this.scannedResult = await ScanService.scanDocument(this.file.id);
			} catch (error) {
				if (isKnownError(error, ErrorCode.FormNotRecognised)) {
					this.scannedResult = emptyDocumentScanResult(this.claimId);
				}
			}
		}

		this.conditionData = this.scannedResult.condition;
		if (this.scannedResult.isMultiCondition) {
			this.secondConditionData = this.scannedResult.secondCondition;
			this.isConditionOneSelected = this.conditionData.claimId !== 0;
			this.isConditionTwoSelected = this.secondConditionData.claimId !== 0;
			this.linkedClaimCreated = this.conditionData.claimId !== 0 && this.secondConditionData.claimId !== 0;
		}
		this.onFormTypeUpdated(this.conditionData.claimFormType.value);
	},
	computed: {
		...mapState(['isAnyBasicFieldsPopulated']),
		isAllSectionsPassed () {
			return this.isDataPassed() && this.isVetPassed;
		},
		isScanReadonly () {
			return !this.$can.EditClaimFormRecognition;
		},
		isVetSectionAvailable () {
			return this.selectedFormType !== ClaimFormType.OtherLosses
				&& (this.isConditionOneSelected
				|| this.isConditionTwoSelected
				|| !this.scannedResult.isMultiCondition
				|| this.linkedClaimCreated);
		},
		isPayeeSectionAvailable () {
			return this.selectedFormType !== ClaimFormType.OtherLosses;
		},
		conditionOneHeader () {
			return !this.scannedResult.isMultiCondition && !this.linkedClaimCreated ? 'Basic Details' : 'Condition One';
		},
	},
	methods: {
		async onPopulateClaim () {
			const linkedData = await ClaimService.getLinkedClaimData(this.claimId);
			if (linkedData
				&& linkedData.linkedClaimForm
				&& linkedData.initialClaim.linkedClaimFormId
				&& linkedData.initialClaim.linkedClaimFormId !== this.file.id) {
				this.populateDialogDescription = `${linkedData.linkedClaimForm.claimId} ${linkedData.linkedClaimForm.ailment ? linkedData.linkedClaimForm.ailment : 'no ailment'} will be un-linked from `;
				this.populateDialogDescription += `${linkedData.initialClaim.claimId} ${linkedData.initialClaim.ailment ? linkedData.initialClaim.ailment : 'no ailment'} and will overwrite any existing claim data that has already been inputted. Are you sure you want to proceed?`;

				const userChoice = await this.$refs.populateDialog.show();
				if (userChoice === 'yes') {
					await ClaimService.unlinkClaim(this.claimId);
					this.onEmitBus();
				}
			} else if (this.isAnyBasicFieldsPopulated) {
				this.populateDialogDescription = 'This will overwrite any existing claim data that has already been inputted. Are you sure you want to proceed?';
				const userChoice = await this.$refs.populateDialog.show();
				if (userChoice === 'yes') {
					this.onEmitBus();
				}
			} else {
				this.onEmitBus();
			}
		},
		isActiveConditionData (conditionData) {
			return conditionData.claimId === this.claimId;
		},
		isReadonlyConditionData (conditionData) {
			return this.isScanReadonly || (conditionData.claimId !== this.claimId && conditionData.claimId !== 0);
		},
		onEmitBus () {
			const result = cloneDeep(this.finalResult);
			result.fileId = this.file.id;
			if (!result.conditionData && result.secondConditionData) {
				result.conditionData = result.secondConditionData;
				result.secondConditionData = null;
			}
			eventBus.$emit('populate-basic', result);
			this.hideDocumentPanel();
		},
		onPolicyInformationPassed (policyInformationData) {
			this.isBasicPassed = false;
			this.isPolicyInformationPassed = true;
			this.finalResult.policy = policyInformationData;
			if (this.isPayeeSectionAvailable) {
				this.$refs.payeeInformation.expand();
			} else {
				this.$refs.basic.expand();
			}
		},
		onPayeePassed (data) {
			this.isPayeeInformationPassed = true;
			this.finalResult.payeeInformation = data;
			this.$refs.basic.expand();
		},
		onBasicPassed (conditionData) {
			this.isSecondConditionPassed = false;
			if (this.isVetSectionAvailable) {
				this.isVetPassed = false;
			}
			this.isBasicPassed = true;
			this.finalResult.conditionData = conditionData;
			if (this.scannedResult.isMultiCondition
				&& this.isConditionTwoSelected
				&& !this.isReadonlyConditionData(this.secondConditionData)) {
				this.$refs.secondCondition.expand();
			} else if (this.isVetSectionAvailable) {
				this.$refs.vet.expand();
			}
		},
		onSecondConditionPassed (secondConditionData) {
			this.isPolicyInformationPassed = false;
			this.isSecondConditionPassed = true;
			this.finalResult.secondConditionData = secondConditionData;
			this.$refs.vet.expand();
		},
		onVetPassed (vetData) {
			this.isVetPassed = true;
			this.finalResult.vet = vetData;

			if (!this.isDataPassed()) {
				if (this.scannedResult.isMultiCondition) {
					if (this.isConditionOneSelected && !this.isReadonlyConditionData(this.conditionData) && !this.isBasicPassed) {
						this.$refs.basic.expand();
					} else if (this.isConditionTwoSelected && !this.isReadonlyConditionData(this.secondConditionData) && !this.isSecondConditionPassed) {
						this.$refs.secondCondition.expand();
					}
				} else {
					this.$refs.basic.expand();
				}
			}
		},
		onSelectConditionOneClicked (data) {
			this.isConditionOneSelected = data;
		},
		onSelectConditionTwoClicked (data) {
			this.isConditionTwoSelected = data;
		},
		isMultiConditionDataPassed () {
			if (this.isConditionOneSelected && !this.isConditionTwoSelected) {
				return this.isBasicPassed;
			} else if (!this.isConditionOneSelected && this.isConditionTwoSelected) {
				return this.isSecondConditionPassed;
			}
			return (this.isBasicPassed || this.isReadonlyConditionData(this.conditionData))
				&& (this.isSecondConditionPassed || this.isReadonlyConditionData(this.secondConditionData));
		},
		isDataPassed () {
			if (this.scannedResult.isMultiCondition) {
				return this.isMultiConditionDataPassed();
			} else {
				return this.isBasicPassed;
			}
		},
		onFormTypeUpdated (formType) {
			this.selectedFormType = formType;
			if (this.selectedFormType === ClaimFormType.OtherLosses) {
				this.isVetPassed = true;
				this.isPayeeInformationPassed = true;
				this.finalResult.payeeInformation = {
					payPolicyHolder: { accuracy: 1, selected: true },
					payVeterinaryHospital: { accuracy: 1, selected: false },
				};
			}
		},
	},
	async beforeDestroy () {
		if (this.isBasicPassed) {
			for (const prop in this.finalResult.conditionData) {
				if (this.finalResult.conditionData[prop]) {
					if (this.scannedResult.condition[prop].value !== this.finalResult.conditionData[prop]) {
						this.scannedResult.condition[prop].confidence = 1;
					}
					this.scannedResult.condition[prop].value = this.finalResult.conditionData[prop];
				}
			}

			if (this.finalResult.conditionData.ailment) {
				this.scannedResult.condition.ailment.id = this.finalResult.conditionData.ailment.id;
				this.scannedResult.condition.ailment.value = this.finalResult.conditionData.ailment.firstCause;
			}
		}

		if (this.scannedResult.isMultiCondition) {
			if (this.isSecondConditionPassed) {
				for (const prop in this.finalResult.secondConditionData) {
					if (this.finalResult.secondConditionData[prop]) {
						if (this.scannedResult.secondCondition[prop].value !== this.finalResult.secondConditionData[prop]) {
							this.scannedResult.secondCondition[prop].confidence = 1;
						}
						this.scannedResult.secondCondition[prop].value = this.finalResult.secondConditionData[prop];
					}
				}

				if (this.finalResult.secondConditionData.ailment) {
					this.scannedResult.secondCondition.ailment.id = this.finalResult.secondConditionData.ailment.id;
					this.scannedResult.secondCondition.ailment.value = this.finalResult.secondConditionData.ailment.firstCause;
				}
			}

			if (!this.isConditionOneSelected) {
				this.scannedResult.condition.claimId = 0;
				if (this.isConditionTwoSelected) {
					this.scannedResult.secondCondition.claimId = this.claimId;
				}
			}

			if (!this.isConditionTwoSelected) {
				this.scannedResult.secondCondition.claimId = 0;
				if (this.isConditionOneSelected) {
					this.scannedResult.condition.claimId = this.claimId;
				}
			}

			if (this.isConditionOneSelected
				&& this.isConditionTwoSelected
				&& this.scannedResult.condition.claimId === 0) {
				this.scannedResult.condition.claimId = this.claimId;
			}
		}

		if (this.finalResult.payeeInformation) {
			this.scannedResult.payPolicyHolder = this.finalResult.payeeInformation.payPolicyHolder;
			this.scannedResult.payVeterinaryHospital = this.finalResult.payeeInformation.payVeterinaryHospital;
		}

		if (this.finalResult.vet) {
			this.scannedResult.veterinaryPracticeName.id = this.finalResult.vet.id;
			this.scannedResult.veterinaryPracticeName.value = this.finalResult.vet.practiceName;
		}
		await ScanService.saveDocumentScan(this.file.id, this.scannedResult);
	},
};
</script>

<style lang="scss" scoped>
.claim-from-scan {
  overflow-y: auto;
}

.btn-radius--less {
  border-radius: 2px;
}

.text-success--lighten {
  color: $success-l-15;
}
</style>
