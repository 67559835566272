<template>
	<div class="container-fluid">
		<modal
			ref="vetModal"
			name="new-vet"
			height="auto"
			width="100%"
			classes="v-aq-modal"
		>
			<new-vet-modal
				:vet="vetSearchTerm"
				@added-vet="onAddVet"
				:locale="locale"
			/>
		</modal>
		<div class="row">
			<div class="col">
				<aq-select
					data-qa="enterBasic_input_vet"
					v-if="!isReadOnlyMode"
					:options="vets"
					label="Search for a vet"
					option-label="practiceName"
					:async-find="updateVetSearchTerm"
					:loading="isVetLoading"
					@input="onVetSelected"
					:value="selectedVets"
					multiple
					track-by="id"
					no-result-message="No Vet found"
					hide-tags
					:clear-on-select="false"
					:is-sorted="false"
				>
					<template #selectedOption="selectedScope">
						<span
							class="multiselect__single"
							v-if="selectedScope.props.values.length && !selectedScope.props.isOpen"
						>
							{{ vetSearchTerm }}
						</span>
					</template>
					<template #default="{ option }">
						<div class="px-10">
							<div class="d-flex align-items-center">
								<div
									v-if="option.isRegisteredVet"
									class="col registered-vet-icon"
								>
									<RegisteredVet />
								</div>
								<div class="col">
									<div class="select-option-header text-break text-truncate">
										{{ option.practiceName }}
									</div>
									<div class="py-6 select-option-info text-truncate">
										<span>
											{{ toFullAddress(option) }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #before-list>
						<div
							class="p-20 create-new"
							v-if="vetSearchTerm && $can.CreateNewVet && appSettings.allowUIVetCreation"
							@click="$modal.show('new-vet')"
						>
							<button
								type="button"
								class="btn small btn-primary mr-10"
							>
								<i class="fas fa-plus text-white" />
							</button>
							<span>
								Create <span class="text-primary">
									"{{ vetSearchTerm }}"
								</span> as a new vet
							</span>
						</div>
					</template>
					<template #after-list>
						<div
							v-if="vets.length"
							class="text-right p-10"
						>
							<span class="text-primary">
								{{ vets.length }} {{ 'match' | pluralize(vets.length ) }}
							</span>
						</div>
					</template>
				</aq-select>
				<aq-form-input-error
					class="error mt-4"
					:validator="validator"
				/>
				<div>
					<div
						class="list-item--bg mt-8 py-12 px-20 position-relative d-flex justify-content-between  control-container"
						:class="{'disabled': isReadOnlyMode}"
						v-for="item of selectedVets"
						:key="item.id"
					>
						<div>
							<div>{{ item.practiceName }}</div>
							<div class="mt-6 text-subtitle fs-12">
								<span>
									{{ toFullAddress(item) }}
								</span>
								<div class="vet-details">
									<VDropdown
										class="ml-15"
										popper-class="vet-details-popup"
										placement="top"
									>
										<PracticeLogo class="vet-details-practice-icon" />
										<template #popper="{ hide }">
											<div>
												<div class="d-flex justify-content-between">
													<div class="d-flex align-items-center">
														<PracticeLogo class="mr-3 vet-details-practice-icon" />
														<h4 class="text-center">
															Practice Info
														</h4>
													</div>
													<div
														class="d-flex vet-details-close justify-content-center"
														aria-label="Close"
														@click="hide"
													>
														<span class="mb-2 align-self-center">x</span>
													</div>
												</div>
												<hr>
											</div>
											<div class="vet-details-content">
												<div>
													<div>
														Email:
													</div>
													<p class="break-word">
														{{ item.email }}
													</p>
												</div>

												<div class="mt-10">
													<div>
														Tel:
													</div>
													<p>
														{{ item.phoneNumber }}
													</p>
												</div>
											</div>
										</template>
									</VDropdown>
								</div>
								<button
									v-if="!isReadOnlyMode"
									type="button"
									class="btn btn-row remove-vet"
									:disabled="!item.isEditable"
									@click="onRemoveVet(item)"
								>
									<div
										v-if="!item.isEditable"
										class="vet-tooltip"
										v-tooltip="{ content: item.isEditable ? '' : 'This vet can not be removed as it has been already paid to' }"
									/>
									Delete
								</button>
							</div>
						</div>
						<div class="locked">
							<i class="fas fa-lock" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import { toFullAddress } from '@commonServices/utils/converter';
import ClaimService from '@commonServices/claimService';
import { PracticeLogo, RegisteredVet } from '@assets/icons';
import { multipleSortComparer, sortComparer, uniqueBy } from '@commonServices/utils/general';
import { mapState } from 'vuex';
import SortingDirection from '@clientCommon/services/models/SortingDirection';

export default {
	name: 'ClaimVets',
	components: {
		PracticeLogo,
		RegisteredVet,
	},
	props: {
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		selectedVets: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		validator: {
			type: Object,
			required: true,
		},
		registeredVets: {
			type: Array,
			required: true,
		},
	},
	data () {
		return {
			vets: [],
			isVetLoading: false,
			vetSearchTerm: undefined,
		};
	},
	mounted () {
		this.vets.push(...this.sortedRegisteredVets);
	},
	computed: {
		...mapState(['appSettings']),
		sortedRegisteredVets () {
			const sortComparer = multipleSortComparer(['isHistorical'], ['assignmentDate', SortingDirection.Descending]);
			return uniqueBy(this.registeredVets.filter(vet => vet.approved || vet.active).sort(sortComparer), 'id');
		},
	},
	methods: {
		toFullAddress,
		updateVetSearchTerm (term) {
			if (term.length > 0 || !this.$refs.vetModal.visible) {
				this.vetSearchTerm = term;
				this.searchVetsAsync();
			}
		},
		searchVetsAsync: debounce(function () {
			if (this.vetSearchTerm.length >= 3) {
				this.isVetLoading = true;
				ClaimService.getVets(this.vetSearchTerm).then(vets => {
					const registeredVetIds = this.registeredVets
						.filter(vet => !vet.isHistorical)
						.map(registeredVet => registeredVet.id);
					const vetsNotRegistered = vets.filter(vet => !registeredVetIds.includes(vet.id) && (vet.approved || vet.active));
					this.vets = [
						...this.sortedRegisteredVets.filter(vet => !vet.isHistorical),
						...vetsNotRegistered.sort(sortComparer('practiceName')),
					];
					this.isVetLoading = false;
				});
				return;
			}
			this.vets = this.sortedRegisteredVets;
		}, 500),
		onRemoveVet (item) {
			const index = this.selectedVets.indexOf(item);
			this.selectedVets.splice(index, 1);
			this.notifyVetsChange();
		},
		onAddVet (newValue) {
			newValue.isEditable = true;
			this.selectedVets.push(newValue);
			this.clearVetSearch();
			this.notifyVetsChange();
		},
		onVetSelected (value) {
			const newValues = value.filter((item) => !this.selectedVets.find((vet) => vet.id === item.id));
			this.selectedVets.push(...newValues);
			this.notifyVetsChange();
		},
		notifyVetsChange () {
			this.$emit('vets-changed', this.selectedVets);
		},
		clearVetSearch () {
			this.vetSearchTerm = null;
			this.vets = [];
		},
	},
};
</script>

<style lang="scss" scoped>
.remove-vet {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  .vet-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vet-details {
  cursor: pointer;
  position: absolute;
  right: 140px;
  top: 50%;
  transform: translateY(-50%);
}

.registered-vet-icon {
  fill: var(--sectionTextColour);
  max-width: 30px;
  max-height: 30px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

hr {
  border-top: 1px solid var(--cardIconSVG);
}
</style>

<style lang="scss">
.popover-wrapper {
  position: absolute;
  top: -25px;
  left: -15px;

  .tooltip-arrow {
    left: 3px !important;
  }
}

.vet-details-popup {
  .v-popper__inner {
    background: var(--contentBg);
    color: var(--bodyColour);
    width: 240px;
    text-align: left;
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0 3px 6px #00000029;
    border: 2px solid var(--cardIconSVG);
    overflow: visible;

    .vet-details-close {
      cursor: pointer;
      background: var(--btnLink) 0% 0% no-repeat padding-box;
      border: 1px solid var(--cardIconSVG);
      color: var(--controlBg);
      margin: -18px;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      font-size: 10px;
    }

    .vet-details-content {
      margin: 15px 0;
    }
  }

  .v-popper__arrow-outer,
  .v-popper__arrow-inner {
    border-color: var(--cardIconSVG);
  }
}

.vet-details-practice-icon {
  fill: var(--cardIconColour);
}

</style>
