<template>
	<div
		class="control-container p-0 min-height-0"
		:class="{ 'disabled': disabled }"
	>
		<div :class="[hasErrors ? 'bg-danger-darken': '', 'row no-gutters attribute']">
			<div :class="[labelClass, isItemTotal ? 'col-7 font-weight-bold' : 'col-5', hasErrors ? 'text-white' : '', 'py-20 px-20']">
				{{ label }}
			</div>
			<div :class="[{ 'focused': isFocused }, isItemTotal ? 'col-5' : 'col-7', 'py-20 scan-form-input d-flex']">
				<template v-if="type==='money'">
					<aq-money-input
						:value="value"
						:input-class="inputClass"
						@input="updateValue"
						@focus="onFocus"
						:disabled="disabled"
						class="w-100"
						:class="{ 'text-danger': hasErrors, 'text-warning': hasWarnings }"
					/>
				</template>
				<template v-else>
					<aq-input
						:type="type"
						:value="value"
						:input-class="inputClass"
						@input="updateValue"
						@focus="onFocus"
						:disabled="disabled"
						class="w-100"
						:class="{ 'text-danger': hasErrors, 'text-warning': hasWarnings }"
					/>
				</template>
				<div
					class="locked"
					v-if="disabled"
				>
					<i class="fas fa-lock" />
				</div>
			</div>
		</div>
		<div
			class="mt-2 position-absolute"
			v-if="!isValid"
		>
			<div
				class="text-danger fs-12"
				v-for="error in errorMessages"
				:key="error.$uid"
			>
				{{ error.$message }}
			</div>
			<div
				class="text-warning fs-12"
				v-for="warning in warningMessages"
				:key="warning.$uid"
			>
				{{ warning.$message }}
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AqScanFormInput',
	props: {
		label: {
			type: String,
			required: true,
		},
		value: {
			type: [String, Number, Date],
			required: false,
		},
		isValid: {
			type: Boolean,
			required: false,
			default: true,
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		labelClass: {
			type: String,
			required: false,
			default: 'aq-scan-label',
		},
		inputClass: {
			type: String,
			required: false,
			default: 'aq-scan-input',
		},
		errorMessages: {
			type: Array,
			required: false,
		},
		warningMessages: {
			type: Array,
			required: false,
		},
		isItemTotal: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isFocused: false,
		};
	},
	computed: {
		hasErrors () {
			return !this.isValid && !!this.errorMessages?.length;
		},
		hasWarnings () {
			return !this.isValid && !!this.warningMessages?.length && !this.errorMessages?.length;
		},
	},
	methods: {
		onFocus (isFocused) {
			this.isFocused = isFocused;
			if (this.isFocused) {
				this.$emit('focused');
			}
		},
		updateValue (value) {
			this.$emit('input', value);
		},
	},
};
</script>
