<template>
	<div>
		<aq-alert-modal
			ref="proceedClaimPaymentAlert"
			name="proceed-claim-payment-alert"
			title="Claim payment approval"
			description="You are not able to approve this claim payment."
			data-qa="taskPage_modal_proceedClaimPaymentAlert"
		/>
		<aq-modal name="reject-task">
			<reject-claim-modal @rejected-claim="onRejectClaim" />
		</aq-modal>
		<aq-modal name="payees-data-modal">
			<payees-data-modal
				title="Payees"
				:data="selectedData"
				:locale="locale"
				@close="onCloseModal"
			/>
		</aq-modal>
		<aq-modal name="flags-data-modal">
			<flags-data-modal
				title="Fraud Flags"
				:data="selectedData"
				@close="onCloseModal"
			/>
		</aq-modal>
		<aq-modal name="missing-data-modal">
			<missing-data-modal
				title="Missing Items"
				:data="selectedData"
				@close="onCloseModal"
			/>
		</aq-modal>
		<aq-alert-modal
			ref="commentModal"
			name="comment-modal"
			title="Comments"
			button-label="Close"
			class-name="primary"
			:description="comment"
		/>
		<aq-alert-modal
			ref="reloadDataAlert"
			name="reload-data-alert"
			title="Task already completed"
			button-label="Reload"
			class-name="primary"
			description="This task has been completed by another user. Please click on Reload to refresh the task list"
		/>
		<aq-modal name="user-assignment">
			<task-assignment-modal
				:user-options="userOptions"
				:prioritized-queue="prioritizedQueue"
				@assign-user="onUserAssigned"
			/>
		</aq-modal>
		<reassessment-outcome-modal
			data-qa="tasksPage_modal_reassessmentOutcomeModal"
			name="reassessment-outcome-modal"
			ref="saveReassessmentOutcomeModal"
			:selected-reassessment-outcome="selectedReassessmentOutcome"
			:claim-id="currentClaimId"
			@input="selectedReassessmentOutcome = $event"
			v-if="currentClaimId"
		/>
		<aq-confirmation-modal
			ref="confirmChaseModal"
			name="confirm-chase-modal"
			title="Chase Confirmation"
			description="Please confirm you wish to begin the missing information chase process"
			yes-label="Proceed"
			no-label="Cancel"
			data-qa="tasks-page_modal_confirm-chase"
		/>
		<tasks-page-filters
			v-if="filters"
			:queue-id="queueId"
			:queue-name="queueName"
			:pot="pot"
			:filter="filter"
			:filters="filters"
			:hide-filters="elseQueue"
			:sorting="viewSorting"
			:disabled-export-message="disabledExportMessage"
			@apply-filters="onApplyFilters"
			:prioritized-queue="prioritizedQueue"
			@select-tasks-auto-assignment="prioritizedQueue = !prioritizedQueue"
			:min-opt-in="minOptIn"
			@select-min-opt-in="onSelectMinOptIn"
			@export-queue="onExportQueue"
			:total-tasks-count="paging.rowCount"
			:search-terms="searchTerms"
		/>
		<aq-search-term-container
			class="px-25 pt-5 pb-10"
			v-if="searchTerms.length"
			:search-terms="searchTerms"
			:filter="filter"
			@remove-search-term="onRemoveSearchTerm"
		/>
		<div class="pt-25 mx-25">
			<div class="section-header pl-45">
				<div class="section-header__title">
					{{ tableData.title }}
				</div>
				<div
					class="d-flex ml-auto align-items-center"
					v-if="$can.AssignTaskToUser"
				>
					<aq-checkbox
						v-model="enableMultiClaimsAssignment"
						class="round large thin"
						@input="onEnableMultiClaimsAssignmentChanged()"
					/>
					<AssignUserLogo
						class="assign-user-logo mx-10"
						:class="enableMultiClaimsAssignment ? '' : 'disabled'"
					/>
					<a
						class="user-assignment-link"
						:class="[{ 'disabled' : !enableMultiClaimsAssignment },
							enableMultiClaimsAssignment && claimsToAssign.length !== 0 ? 'cursor-pointer' : 'cursor-default' ]"
						@click.stop="onAssignClick(claimsToAssign)"
					>Assign to User</a>
				</div>
			</div>
		</div>
		<task-table
			:data="tasks"
			:pot="pot"
			:show-extra-payment-details="tableData.showExtraPaymentDetails"
			:is-requested-date="tableData.isRequestedDate"
			:show-requested-details="tableData.showRequestedDetails"
			:show-settled-data="tableData.showSettledData"
			:show-missing-info-completed="tableData.showMissingInfoCompleted"
			:show-reassessment-date="tableData.showReassessmentDate"
			:multi-claims-assignment-enabled="enableMultiClaimsAssignment"
			:claims-to-assign="claimsToAssign"
			:paging="paging"
			@page-changed="onPageChanged"
			@row-action="onRowAction"
			@user-assign="onAssignClick"
			@select-claim-to-assign="onSelectClaimToAssign"
		>
			<!-- Draft -->
			<template
				v-if="isTableOpen(workQueueType.DraftClaims)"
				#column-six
			>
				<el-table-column label="Brand">
					<template #default="scope">
						<aq-brand-image-avatar
							:brand-name="scope.row.brandName"
							:brand-id="scope.row.brandId"
							class="cursor-inherit"
						/>
					</template>
				</el-table-column>
				<el-table-column label="Product">
					<template #default="scope">
						<truncated-text-cell
							v-if="scope.row.products"
							:value="scope.row.products"
						/>
						<truncated-text-cell
							v-else
							class="text-gray"
							value="No Products"
						/>
					</template>
				</el-table-column>
			</template>
			<template #column-fifteen>
				<template v-if="isTableOpen(workQueueType.DraftClaims)">
					<el-table-column label="Claim Type">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.claimType" />
						</template>
					</el-table-column>
					<el-table-column label="Form Type">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.formType" />
						</template>
					</el-table-column>
					<el-table-column
						label="Days Open"
						width="85"
					>
						<template #default="scope">
							<div>{{ scope.row.daysOpen }}</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Created By"
						width="90"
					>
						<template #default="scope">
							<aq-icon-avatar
								class="small cursor-inherit"
								:value="[
									scope.row.createdBy.firstName,
									scope.row.createdBy.lastName,
								]"
							/>
						</template>
					</el-table-column>
					<el-table-column
						label="Created Date"
						width="120"
					>
						<template #default="scope">
							<div>{{ scope.row.dateCreated | shortDate }}</div>
						</template>
					</el-table-column>
				</template>

				<!-- FraudCheck -->
				<template v-if="isTableOpen(workQueueType.FraudCheck)">
					<el-table-column
						label="Fraud Flags"
						width="65"
					>
						<template #default="scope">
							<div
								class="link text-danger-20"
								data-qa="taskTable_button_fraudFlags"
								@click="onSelectFlags(scope.row.fraudCheckReasons)"
							>
								<strong>{{ scope.row.fraudCheckReasons.length }}</strong>
								{{ 'Flag' | pluralize(scope.row.fraudCheckReasons.length) }}
							</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Form Type"
						width="120"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.formType" />
						</template>
					</el-table-column>
				</template>
				<!-- FraudCheck -->
				<template v-if="isTableOpen(workQueueType.FraudCheck)">
					<el-table-column
						label="Comments"
						width="110"
					>
						<template #default="scope">
							<template v-if="scope.row.fraudComment">
								<div
									class="link text-primary"
									data-qa="taskTable_button_fraudComments"
									@click="showComment(scope.row.fraudComment)"
								>
									<strong>Comments</strong>
								</div>
							</template>
							<template v-else>
								<div class="text-subtitle">
									No Comments
								</div>
							</template>
						</template>
					</el-table-column>
				</template>
				<!-- RejectionPending -->
				<template v-if="isTableOpen(workQueueType.RejectionApproval)">
					<el-table-column label="Reason">
						<template #default="scope">
							<truncated-text-cell
								v-if="scope.row.rejectionReason"
								:value="scope.row.rejectionReason"
								class="text-danger-20"
							/>
							<div
								v-else
								class="text-gray"
							>
								No Reason
							</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Comments"
						width="110"
					>
						<template #default="scope">
							<template v-if="scope.row.comments">
								<div
									class="link text-primary"
									data-qa="taskTable_button_rejectionComments"
									@click="showComment(scope.row.comments)"
								>
									<strong>Comments</strong>
								</div>
							</template>
							<template v-else>
								<div class="text-subtitle">
									No Comments
								</div>
							</template>
						</template>
					</el-table-column>
					<el-table-column
						label="Form Type"
						width="110"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.formType" />
						</template>
					</el-table-column>
				</template>
				<!-- MissingInfo -->
				<template v-if="isTableOpen(workQueueType.Chasing)">
					<el-table-column
						label="Missing Information"
						width="85"
					>
						<template #default="scope">
							<div
								v-if="scope.row.missingInformation.length > 0"
								class="link text-primary"
								data-qa="taskTable_button_missingInfo"
								@click="onSelectMissingInfo(scope.row.missingInformation)"
							>
								<strong>{{ scope.row.missingInformation.length }}</strong>
								{{ 'Item' | pluralize(scope.row.missingInformation.length) }}
							</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Chased"
						width="100"
					>
						<template #default="scope">
							<div v-if="scope.row.chased">
								{{ scope.row.chased | shortDate }}
							</div>
							<div
								v-else
								class="text-gray"
							>
								Not Chased
							</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Chase Count"
						width="55"
						prop="chaseCount"
					/>
					<el-table-column
						label="Form Type"
						width="120"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.formType" />
						</template>
					</el-table-column>
				</template>
			</template>
			<template
				v-if="!isTableOpen(workQueueType.RejectionApproval)"
				#payees
			>
				<el-table-column
					label="Payee(s)"
					v-if="tableData.showExtraPaymentDetails"
					width="100"
				>
					<template #default="scope">
						<template v-if="scope.row.payees && scope.row.payees.length > 0">
							<div
								class="link text-primary"
								@click="onSelectPayees(scope.row)"
							>
								<strong>{{ scope.row.payees.length }}</strong> {{ 'Payee'| pluralize(scope.row.payees.length) }}
							</div>
						</template>
						<template v-else>
							<div class="text-subtitle">
								No Payees
							</div>
						</template>
					</template>
				</el-table-column>
			</template>
			<template
				v-if="isTableOpen(workQueueType.PaymentApproval)"
				#adjuster-comment
			>
				<el-table-column
					label="Adjuster Comment"
					width="100"
				>
					<template #default="scope">
						<template v-if="scope.row.adjusterComment">
							<div
								class="link text-primary"
								data-qa="taskTable_button_adjusterComment"
								@click="showComment(scope.row.adjusterComment)"
							>
								<strong>Comment</strong>
							</div>
						</template>
						<template v-else>
							<truncated-text-cell
								class="text-subtitle"
								value="No Comment"
							/>
						</template>
					</template>
				</el-table-column>
			</template>
			<template #reasons>
				<template v-if="isTableOpen(workQueueType.PaymentApproval)">
					<el-table-column
						label="Approval Reason"
						width="110"
					>
						<template #default="scope">
							<truncated-text-cell
								v-if="scope.row.settledReason"
								:value="scope.row.settledReason"
							/>
							<truncated-text-cell
								v-else
								class="text-gray"
								value="Unknown Reason"
							/>
						</template>
					</el-table-column>
				</template>
				<template v-if="isTableOpen(workQueueType.RejectionApproval)">
					<el-table-column label="Rejection">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.rejectionStatus" />
						</template>
					</el-table-column>
				</template>
			</template>
		</task-table>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import eventBus from '@commonServices/eventBus';
import WorkflowService from '@commonServices/workflowService';
import UsersService from '@commonServices/usersService';
import Paging from '@commonServices/models/Paging';
import TasksPageFilters from '@commonView/TasksPage/TasksPageFilters';
import { AssignUserLogo } from '@assets/icons';
import {
	toWorkQueueApiFilters,
	toWorkQueueViewFilter,
	getFilterGroupByWorkQueueType,
} from '@commonServices/utils/workflowConverter';
import AqSearchTermContainer from '@commonView/Shared/AqSearchTermContainer';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';
import TaskAssignmentModal from '@commonView/TasksPage/TaskAssignmentModal';
import taskRowActionsHandlerMixin from '@mixins/taskRowActionsHandlerMixin';
import { toApiSorting, toSortingTerms, toViewSorting } from '@commonServices/utils/reportsConverter';
import { EverytingElseQueueName } from '@commonServices/utils/constants';
import { getDisabledExportMessage }	from '@commonServices/models/ExportType';
import fileService from '@commonServices//fileService';

const tablePageSize = 10;

const WorkQueueTitleDisplayMap = {
	[WorkQueueType.PaymentApproval]: 'Approve Claim Payments',
	[WorkQueueType.FraudCheck]: 'Fraud Check Claims',
	[WorkQueueType.RejectionApproval]: 'Pending Rejection Claims',
	[WorkQueueType.Chasing]: 'Missing Information Requests',
	[WorkQueueType.ReassessmentPending]: 'Claims to Reassess',
	[WorkQueueType.DraftClaims]: 'Draft Claims',
};

export default {
	components: {
		TasksPageFilters,
		AssignUserLogo,
		AqSearchTermContainer,
		TaskAssignmentModal,
	},
	mixins: [taskRowActionsHandlerMixin],
	data () {
		return {
			tasks: [],
			currentClaimId: null,
			selectedData: null,
			enableMultiClaimsAssignment: false,
			claimsToAssign: [],
			paging: new Paging(tablePageSize),
			workQueueType: WorkQueueType,
			filter: null,
			filters: null,
			queueId: null,
			queueName: '',
			elseQueue: false,
			searchTerms: [],
			currentItemsForAssignment: [],
			users: [],
			apiSorting: [],
			viewSorting: [],
			comment: '',
			prioritizedQueue: false,
			selectedReassessmentOutcome: null,
			minOptIn: 0,
		};
	},
	created () {
		this.pot = parseInt(this.$route.params.pot);
	},
	async mounted () {
		this.resetInteractionContext();
		eventBus.$on('task_queue-deleted', this.onQueueDeleted);
		eventBus.$on('task_queue-renamed', this.onRenameQueue);
		eventBus.$on('notes-updated', this.loadData);
		await this.mountPage();
	},
	computed: {
		...mapState(['currentUser', 'appSettings']),
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		tableData () {
			return {
				title: `${WorkQueueTitleDisplayMap[this.pot]}${
					this.queueName ? ` - '${this.queueName}' Queue` : ''
				}`,
				showExtraPaymentDetails: [
					WorkQueueType.PaymentApproval,
					WorkQueueType.FraudCheck,
					WorkQueueType.RejectionApproval,
				].includes(this.pot),
				isRequestedDate: this.pot === WorkQueueType.Chasing,
				showRequestedDetails: this.pot !== WorkQueueType.DraftClaims,
				showSettledData: this.pot === WorkQueueType.PaymentApproval,
				showMissingInfoCompleted: this.pot === WorkQueueType.DraftClaims
					&& this.tasks.some(task => task.missingInfoCompleted),
				showReassessmentDate: this.pot === WorkQueueType.DraftClaims,
			};
		},
		userOptions () {
			const permissions = {
				assignToMe: this.$can.AssignTaskToMe,
				assignToUser: this.$can.AssignTaskToUser,
				unassign: this.$can.UnAssignTask
					&& this.currentItemsForAssignment.length > 0
					&& (this.currentItemsForAssignment.some(a => a.assignedTo != null)),
			};

			return UsersService.getAssignmentUserOptions(this.users, this.currentUser, permissions);
		},
		disabledExportMessage () {
			return getDisabledExportMessage(this.paging.rowCount, this.appSettings.dataRowLimit);
		},
	},
	methods: {
		...mapActions(['resetInteractionContext']),
		async mountPage () {
			this.queueId = parseInt(this.$route.params.queueId);

			let queueModel = {};
			if (this.queueId) {
				// getting user filters
				queueModel = await WorkflowService.getQueue(this.queueId);
				this.queueName = queueModel.name;
				this.elseQueue = this.queueName === EverytingElseQueueName;
				this.prioritizedQueue = queueModel.isPrioritized;
				this.minOptIn = queueModel.minOptIn;
			}
			this.filter = toWorkQueueViewFilter(this.pot, queueModel.filters);
			this.users = await UsersService.getUsers();
			this.users.sort((a, b) => (a.description > b.description ? 1 : -1));
			this.viewSorting = toViewSorting(getFilterGroupByWorkQueueType(this.pot), queueModel.sorting);
			await this.loadData();
		},
		async onPageChanged (pageNumber) {
			this.paging.pageNumber = pageNumber;
			await this.loadData();
		},
		async onApplyFilters () {
			this.paging.pageNumber = 1;
			await this.loadData();
		},
		async loadData () {
			const { populatedFilters, searchTerms } = toWorkQueueApiFilters(this.filter);
			const sortingTerms = toSortingTerms(this.viewSorting);
			this.filters = populatedFilters;
			this.searchTerms = [...searchTerms, ...sortingTerms];
			this.apiSorting = toApiSorting(this.viewSorting);
			const loadPotData = this.elseQueue
				? WorkflowService.getRestWorkQueueData
				: WorkflowService.getWorkQueueData;
			const { items, ...paging } = await loadPotData(
				this.pot,
				this.paging,
				this.filters,
				this.apiSorting,
			);
			this.paging.rowCount = paging.rowCount;
			this.paging.pageCount = paging.pageCount;
			this.tasks = items;
		},
		onQueueDeleted ({ queueId }) {
			if (this.queueId === queueId) {
				this.$router.replace({ name: 'tasks', params: { pot: this.pot } });
			}
		},
		onRenameQueue ({ queueId, queueName }) {
			if (this.queueId === queueId) {
				this.queueName = queueName;
			}
		},
		async reloadData () {
			await this.$refs.reloadDataAlert.show();
			await this.mountPage();
		},
		async showComment (comment) {
			if (!comment) return;
			this.comment = comment || '';
			await this.$refs.commentModal.show();
		},
		isTableOpen (pot) {
			return this.pot === pot;
		},
		onAssignClick (tasksToAssign) {
			if (
				this.$can.AssignTaskToMe
				|| this.$can.AssignTaskToUser
				|| this.$can.UnAssignTask
			) {
				this.currentItemsForAssignment = this.tasks.filter(task => tasksToAssign.some((t) => t === task.claimId));
				if (this.currentItemsForAssignment.length !== 0) {
					this.$modal.show('user-assignment');
				}
			}
		},
		async onUserAssigned (user) {
			this.currentItemsForAssignment = this.currentItemsForAssignment.filter(item => !((user && user.id) === (item.assignedTo && item.assignedTo.id)));

			const claimIds = this.currentItemsForAssignment.map((c) => c.claimId);

			if (this.currentItemsForAssignment.length === 0) { return; }
			await (user ? WorkflowService.assignTask : WorkflowService.unAssignTask)(
				this.pot,
				claimIds,
				user,
				this.queueId,
			);
			this.enableMultiClaimsAssignment = false;
			this.claimsToAssign = [];
			this.currentItemsForAssignment.forEach((item) => (item.assignedTo = user));
		},
		onSelectClaimToAssign (id) {
			const index = this.claimsToAssign.indexOf(id);
			if (index > -1) {
				this.claimsToAssign.splice(index, 1);
			} else {
				this.claimsToAssign.push(id);
			}
		},
		onEnableMultiClaimsAssignmentChanged () {
			if (!this.enableMultiClaimsAssignment) {
				this.claimsToAssign = [];
			}
		},
		onSelectMinOptIn (minOptIn) {
			this.minOptIn = minOptIn;
		},
		onRemoveSearchTerm () {
			this.loadData();
		},
		onExportQueue ({ type }) {
			const exportModel = {
				workQueueType: this.pot,
				filters: this.filters,
				sorting: this.apiSorting,
				exportType: type.value,
			};
			const fileName = this.generateFileName(type);
			WorkflowService.generateExportDocument(exportModel, fileName);
		},
		generateFileName (exportType) {
			// if user queue gets queue name, otherwise system queue name
			const fileName = this.queueId ? this.queueName : WorkQueueTitleDisplayMap[this.pot];
			return fileService.generateFileName(fileName, exportType);
		},
	},
	beforeDestroy () {
		eventBus.$off('task_queue-deleted', this.onQueueDeleted);
		eventBus.$off('task_queue-renamed', this.onRenameQueue);
		eventBus.$off('notes-updated', this.loadData);
	},
};
</script>

<style lang="scss" scoped>
.filters {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.assign-user-logo {
  fill: var(--cardIconSVG);
}

.assign-user-logo.disabled {
  fill: var(--disabledText);
}

.user-assignment-link {
  text-decoration: underline;
  color: var(--cardIconSVG);
}

.user-assignment-link.disabled {
  color: var(--disabledText);
}

</style>
