<template>
	<div class="position-relative select-filter">
		<aq-select
			:options="options"
			:label="label"
			:option-label="optionLabel"
			:loading="loading"
			:async-find="asyncFind"
			:value="value"
			@input="$emit('input', $event)"
			:track-by="trackBy"
			:no-result-message="noResultMessage"
			:multiple="multiple"
			:is-sorted="isSorted"
		/>
		<div
			v-if="excludable"
			class="badge-container"
			:class="{ 'excluded': excluded }"
			v-tooltip="{
				content: tooltipContent,
				placement:'top',
				'popper-class': tooltipClasses,
			}"
		>
			<div
				class="badge"
				@click="toggleFilterMode"
			>
				<i class="fa-solid" />
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'AqSelectFilter',
	props: {
		label: {
			type: String,
			required: false,
			default: null,
		},
		value: {
			type: [Array, Object, String],
			required: false,
			default: null,
		},
		options: {
			type: Array,
			required: false,
			default: () => [],
		},
		optionLabel: {
			type: String,
			required: false,
			default: 'description',
		},
		asyncFind: {
			type: Function,
			required: false,
			default: null,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		trackBy: {
			type: String,
			required: false,
			default: 'id',
		},
		multiple: {
			type: Boolean,
			required: false,
			default: false,
		},
		excluded: {
			type: Boolean,
			required: false,
			default: false,
		},
		isSorted: {
			type: Boolean,
			required: false,
			default: true,
		},
		noResultMessage: {
			type: String,
			required: false,
			default: 'No Elements found',
		},
	},
	methods: {
		toggleFilterMode () {
			this.$emit('toggle-mode', !this.excluded);
		},
	},
	computed: {
		excludable () {
			return this.excluded !== null;
		},
		tooltipContent () {
			return this.excluded ? 'Include Filter' : 'Exclude Filter';
		},
		tooltipClasses () {
			return this.excluded ? 'filter-tooltip-excluded' : 'filter-tooltip-included';
		},
	},
};
</script>

<style lang="scss" scoped>
.select-filter {
  .badge-container {
    overflow: hidden;
    width: 43px;
    height: 37px;
    position: absolute;
    top: 0;
    right: 0;

    .badge {
      background-color: var(--filterBadgeOne);
      width: 56px;
      height: 56px;
      position: absolute;
      right: -24px;
      top: -32px;
      transform: rotate(38deg);

      i {
        font-size: 17px;
        color: #87B27F;
        transform: rotate(-38deg);
        position: absolute;
        top: 36px;
        left: 24px;

        &::before {
          content: "\f055";
        }
      }

      &:hover {
        background-color: var(--filterBadgeTwo);
        cursor: pointer;

        i {
          color: #DC6E6C;

          &::before {
            content: "\f056";
          }
        }
      }
    }

    &.excluded {
      .badge {
        background-color: var(--filterBadgeTwo);

        i {
          color: #DC6E6C;

          &::before {
            content: "\f056";
          }
        }

        &:hover {
          background-color: var(--filterBadgeOne);

          i {
            color: #87B27F;

            &::before {
              content: "\f055";
            }
          }
        }

      }
    }
  }

  ::v-deep .multiselect {
    .multiselect__select {
      z-index: 2;
      height: 30px;
      width: 28px;
      right: 8px;
      top: 13px;
    }
  }
}
</style>

<style lang="scss">
.v-popper--theme-tooltip.filter-tooltip-included {
  .v-popper__inner {
    background-color: #DC6E6C;
  }

  .v-popper__arrow-outer,
  .v-popper__arrow-inner  {
    border-color: #DC6E6C;
  }
}

.v-popper--theme-tooltip.filter-tooltip-excluded {
  .v-popper__inner {
    background-color: #87B27F;
  }

  .v-popper__arrow-outer,
  .v-popper__arrow-inner{
    border-color: #87B27F;
  }
}
</style>
