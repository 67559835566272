import { render, staticRenderFns } from "./VetSelectionList.vue?vue&type=template&id=35aa1c81&scoped=true"
import script from "./VetSelectionList.vue?vue&type=script&lang=js"
export * from "./VetSelectionList.vue?vue&type=script&lang=js"
import style0 from "./VetSelectionList.vue?vue&type=style&index=0&id=35aa1c81&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35aa1c81",
  null
  
)

export default component.exports