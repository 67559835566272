import { render, staticRenderFns } from "./ClaimBaseBar.vue?vue&type=template&id=7470243c&scoped=true"
import script from "./ClaimBaseBar.vue?vue&type=script&lang=js"
export * from "./ClaimBaseBar.vue?vue&type=script&lang=js"
import style0 from "./ClaimBaseBar.vue?vue&type=style&index=0&id=7470243c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7470243c",
  null
  
)

export default component.exports