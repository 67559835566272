<template>
	<div class="container-fluid">
		<aq-list-navigation-control
			:list="items"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				:data="items"
				cell-class-name="text-left"
				empty-text="There is no data to display "
				data-qa="userStats_table_reports"
			>
				<el-table-column
					type="index"
					:index="getIndex"
					width="65"
				/>
				<el-table-column
					label="User"
					prop="user.description"
				/>
				<el-table-column
					label="Brand"
					prop="brandName"
				/>
				<el-table-column
					prop="teamName"
					label="Team"
				/>
				<el-table-column
					prop="claimsCreated"
					label="Claims Created"
					width="170"
				/>
				<el-table-column
					prop="claimsClosed"
					label="Claims Closed"
					width="170"
				/>
				<!-- <el-table-column
					label="User Active"
					width="170"
				>
					<template #default="scope">
						<boolean-cell :value="scope.row.userActive" />
					</template>
				</el-table-column> -->
				<el-table-column
					label="Time Logged In"
					width="170"
				>
					<template #default="scope">
						{{ secondsToHHmm(scope.row.logInTimeSec) }}
					</template>
				</el-table-column>
				<el-table-column
					label="Time Opted In"
					width="170"
				>
					<template #default="scope">
						{{ secondsToHHmm(scope.row.optInDurationSec) }}
					</template>
				</el-table-column>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';
import { secondsToHHmm } from '@clientCommon/services/utils/dateUtils';

export default {
	mixins: [rowHighlightMixin],
	props: {
		items: {
			type: Array,
			required: true,
		},
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
	},
	methods: {
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
		secondsToHHmm,
	},
};
</script>
