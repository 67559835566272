<template>
	<div>
		<aq-select
			:options="options"
			:label="numberFilter.label"
			class="w-100"
			:max-height="330"
			:value="numberFilter.type"
			@input="update('type', $event)"
			:is-sorted="false"
		/>
		<div
			v-if="showInputFields"
			:class="itemContainerClass"
		>
			<aq-form-input
				:label="isRange ? 'From' : label"
				:type="type"
				:value="numberFilter.from"
				@input="update('from', $event)"
			/>
			<aq-form-input-error
				class="error mt-4"
				:validator="v$.numberFilter.from"
			/>
		</div>
		<div
			v-if="isRange"
			:class="itemContainerClass"
		>
			<aq-form-input
				label="To"
				:type="type"
				:value="numberFilter.to"
				@input="update('to', $event)"
			/>
			<aq-form-input-error
				class="error mt-4"
				:validator="v$.numberFilter.to"
			/>
		</div>
	</div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { requiredIf, moreThen, withMessage } from '@commonServices/utils/validators';
import { NumberFilerTypes, AllNumberFilterTypes } from '@commonServices/utils/general';
import { ValidationMessages } from '@commonServices/utils/constants';

export default {
	setup () {
		return {
			v$: useVuelidate({ $scope: false }),
		};
	},
	data () {
		return {
			numberFilter: this.value,
		};
	},
	props: {
		options: {
			type: Array,
			required: false,
			default () { return AllNumberFilterTypes; },
		},
		value: {
			type: Object, // {type, label, from, to }
			default () { return {}; },
		},
		itemContainerClass: {
			type: String,
			default: 'mt-20',
		},
		type: {
			type: String,
			required: false,
			default: 'money',
		},
		label: {
			type: String,
			required: false,
			default: 'Amount',
		},
	},
	validations () {
		return {
			numberFilter: {
				from: {
					requiredIf: withMessage(ValidationMessages.required, requiredIf(() => this.showInputFields)),
				},
				to: {
					requiredIf: withMessage(ValidationMessages.required, requiredIf(() => this.isRange)),
					minValue: withMessage('Value should be greater than from value', moreThen(this.numberFilter.from)),
				},
			},
		};
	},
	computed: {
		showInputFields () {
			return AllNumberFilterTypes.includes(this.numberFilter.type);
		},
		isRange () {
			return this.numberFilter.type === NumberFilerTypes.Between;
		},
	},
	methods: {
		update (key, value) {
			this.numberFilter = {
				...this.numberFilter,
				[key]: value,
				...key === 'type' && value !== this.numberFilter.type && { from: null, to: null },
			};

			this.v$.numberFilter.$touch();
			if (!this.v$.numberFilter.$invalid) {
				this.$emit('input', this.numberFilter);
			}
		},
	},
};
</script>
