<template>
	<div class="d-inline-flex mr-5">
		<VTooltip
			:triggers="[]"
			:shown="!switchIsOpen && tooltipIsOpen"
			:delay="{ show: 200, hide: 300 }"
			:placement="disabled ? 'top' : 'bottom'"
		>
			<aq-list-items-popover
				label="Switch Line Type"
				:items="LineTypeOptions"
				:item-label="(item) => item.value"
				:selected-item="selectedLineType"
				track-by="key"
				:is-open="switchIsOpen"
				@input="onLineTypeSelected"
				@auto-hide="setSwitchOpen(false)"
			>
				<div
					:class="[
						disabled ? 'cursor-default' : 'cursor-pointer',
						invalid ? 'original' : 'line-item-type-icon',
						{'excluded': excluded}
					]"
					class="circle"
					@mouseover="setTooltipOpen(true)"
					@mouseleave="setTooltipOpen(false)"
				>
					<i
						class="icon-size-28"
						:class="getIcon()"
						@click="setSwitchOpen(true)"
					/>
				</div>
			</aq-list-items-popover>
			<template #popper>
				<div
					class="d-inline-flex"
					@mouseover="setTooltipOpen(true)"
					@mouseleave="setTooltipOpen(false)"
				>
					Line type:
					<div
						:class="disabled ? 'cursor-default' : 'cursor-pointer text-underline'"
						class="ml-3 cursor-pointer"
						@click="setSwitchOpen(true)"
					>
						{{ selectedLineType.value }}
					</div>
				</div>
			</template>
		</VTooltip>
	</div>
</template>

<script>

import AqListItemsPopover from '@commonWidgets/AqListItemsPopover';
import { InvoiceLineType, LineTypeOptions } from '@commonServices/models/InvoiceLineType';

export default {
	name: 'InvoiceItemTypeIcon',
	components: { AqListItemsPopover },
	data () {
		return {
			switchIsOpen: false,
			tooltipIsOpen: false,
			LineTypeOptions,
		};
	},
	props: {
		selectedTypeId: {
			type: Number,
			required: false,
			default: null,
		},
		invalid: {
			type: Boolean,
			required: false,
			default: false,
		},
		excluded: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		selectedLineType () {
			return this.LineTypeOptions.find(option => option.key === this.selectedTypeId) ?? this.LineTypeOptions[0];
		},
	},
	methods: {
		onLineTypeSelected (item) {
			this.$emit('line-type-selected', item.key);
			this.setSwitchOpen(false);
		},
		setSwitchOpen (isOpen) {
			this.switchIsOpen = !this.disabled && isOpen;
		},
		setTooltipOpen (isOpen) {
			this.tooltipIsOpen = isOpen;
		},
		getIcon () {
			switch (this.selectedTypeId) {
			case InvoiceLineType.Treatment: return 'aqv-line-treatment';
			case InvoiceLineType.SalesTax: return 'aqv-line-tax';
			case InvoiceLineType.Payment: return 'aqv-line-payment';
			case InvoiceLineType.Discount: return 'aqv-line-discount';
			};
		},
	},
};
</script>

<style lang="scss" scoped>
  .icon-size-28 {
    font-size: 19px;
    line-height: 28px;
  }

  .line-item-type-icon {
    background: var(--tabIconBg);

    i {
      color: var(--cardIconColour);
    }

    &:hover {
      background: var(--cardIconColour);

      i {
        color: $body-color;
      }
    }

    &.excluded {
      i {
        color: var(--cardIconSecondaryColour);
      }

      &:hover {
        background: var(--cardIconSecondaryColour);

        i {
          color: $body-color;
        }
      }
    }
  }
</style>
