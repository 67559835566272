<template>
	<VDropdown
		:triggers="[]"
		class="dropdown"
		:class="{ 'no-label': !label, 'split-toggle': splitToggle }"
		popper-class="dropdown-popper tooltip-arrow-hidden"
		:shown="shown"
		@show="onToggle(true)"
		@hide="onToggle(false)"
		:distance="0"
		:boundary="boundariesSelector"
		:overflow-padding="10"
		prevent-overflow
	>
		<template v-if="splitToggle">
			<button
				data-qa="aqDropdown_button_main"
				class="btn main-button"
				:disabled="disabled"
				:class="btnClass"
				type="button"
				v-shortkey="mainActionShortkey"
				@shortkey="$emit('main-action')"
				@click="$emit('main-action')"
			>
				{{ label }}
			</button>
			<button
				data-qa="aqDropdown_button_toggle"
				class="btn small dropdown-toggle"
				:class="btnClass"
				:disabled="isReadOnlyMode || options.length === 0"
				type="button"
				@click="shown = !shown"
			/>
		</template>
		<button
			v-else
			data-qa="aqDropdown_button_proceed"
			class="btn dropdown-toggle"
			:disabled="disabled"
			:class="btnClass"
			type="button"
			@click="shown = !shown"
		>
			{{ label }}
		</button>

		<template #popper="{ hide }">
			<div
				v-shortkey="$shortkeys.proceed"
				@shortkey="onHandleDropdownOptionSelection"
			>
				<aq-list-navigation-control
					:list="options"
					:current-item="currentItem"
					@current-changed="onSelect"
					:nav-controls="{ down: $shortkeys.arrDown, up: $shortkeys.arrUp }"
				>
					<template v-for="(option, index) in options">
						<div
							:key="index"
							@click="hide(); onSelectOption(option)"
							class="dropdown-item"
							:class="[{ 'disabled': option.disabled}, { 'bg-primary': option === currentItem}]"
							v-tooltip="option.tooltip"
							:data-qa="'aqDropdown_button_' + camelConverter(option.value)"
						>
							<div>{{ option.name }}</div>
							<div
								class="pl-20 flex-grow-1 text-right"
								v-if="option.iconClass"
							>
								<i
									class="fas"
									:class="option.iconClass"
								/>
							</div>
						</div>
					</template>
				</aq-list-navigation-control>
			</div>
		</template>
	</VDropdown>
</template>

<script>
import { camelCase } from '@commonServices/utils/filters';
import rowHighlightMixin from '@mixins/rowHighlightMixin';

export default {
	name: 'AqDropdown',
	mixins: [rowHighlightMixin],
	props: {
		label: {
			type: String,
			required: false,
		},
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		btnClass: {
			type: String,
			required: false,
		},
		splitToggle: {
			type: Boolean,
			required: false,
		},
		height: {
			type: Number,
			required: false,
			default: 33,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		isShortkeyContext: {
			type: Boolean,
			required: false,
		},
		boundariesSelector: {
			type: String,
			required: false,
			default: null,
		},
		keepTogether: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data () {
		return {
			shown: false,
		};
	},
	computed: {
		toggleDdlShortCut () {
			if (this.isReadOnlyMode || this.options.length === 0 || !this.isShortkeyContext) {
				return null;
			}
			return this.shown ? { closeDropdown: this.$shortkeys.close } : { openDropdown: this.$shortkeys.openDdl };
		},
		mainActionShortkey () {
			return this.disabled ? null : this.$shortkeys.proceed;
		},
	},
	methods: {
		onSelectOption (option) {
			if (option.disabled) {
				return;
			}
			this.$emit('select', option.value);
		},
		camelConverter (name) {
			return camelCase(name);
		},
		onToggle (shown) {
			this.shown = shown;
			this.$emit('open', shown);
		},
		onHandleDropdownOptionSelection () {
			if (this.currentItem) {
				this.onSelectOption(this.currentItem);
			}
		},
		onHandleDropdownToggling (event) {
			switch (event.srcKey) {
			case 'openDropdown': {
				this.currentItem = this.options[0];
				break;
			}
			case 'closeDropdown': {
				this.currentItem = null;
				break;
			}
			}
		},
		getBoundariesElement () {
			return this.boundariesSelector ? document.querySelector(this.boundariesSelector) : null;
		},
	},
};
</script>

<style lang="scss">
.dropdown {
  .dropdown-toggle {
    &::after {
      content: "";
      color: $primary;
      vertical-align: 0.1em;
      border-top: 0.5em solid;
      border-right: 0.4em solid transparent;
      border-bottom: 0;
      border-left: 0.4em solid transparent;
      display: inline-block;
      margin-left: 0.255em;
    }
  }

  &.split-toggle {
    .main-button {
      border-radius: 2px 0 0 2px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.06);
      }
    }

    .dropdown-toggle {
      border-radius: 0 2px 2px 0;

      &::after {
        color: $body-color;
        margin: 0;
      }
    }
  }

  &.no-label .dropdown-toggle::after {
    margin-left: 0;
    transition: 0.2s ease;
  }

}

.dropdown-popper {
  .v-popper__inner {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    border-radius: 3px;
    border: 0;
    color: var(--bodyColour);
    background: var(--backgroundThree);
    box-shadow: $dark 0 0 6px 0;
  }

  .dropdown-item {
    display: flex;
    width: 100%;
    padding: 7px 20px;
    clear: both;
    white-space: nowrap;
    position: relative;
    flex-wrap: nowrap;
    transition: 0.2s ease;
    cursor: pointer;

    .fas {
      color: $primary;

      &.disabled {
        opacity: 0.4;
      }
    }

    &:hover {
      background: $primary;
      color: $body-color;

      .fas {
        color: $body-color !important;
      }
    }

    &:active {
      background: $primary-d-10;
    }

    &.disabled {
      opacity: 0.4;

      &:hover {
        background: unset;
        color: unset;

        .fas {
          color: $primary !important;
        }
      }

      &:active {
        background: unset;
      }
    }
  }
}

</style>
