<template>
	<div class="banner-section">
		<div
			v-if="isReadOnlyMode"
			class="font-weight-bold p-5"
		>
			Reassesment reason: {{ reassessmentReason }}
		</div>
		<div
			v-else
			class="d-flex align-items-center"
		>
			<VTooltip
				placement="top"
				popper-class="reassesment-description-popup"
				class="d-flex"
				:disabled="!reassessmentReason"
			>
				<div
					data-qa="claim-versions_icon_reassesment"
					class="card-icon small warning cursor-default"
				>
					<ReassessedClaimOpen />
				</div>
				<template #popper>
					<div class="font-weight-bold">
						Reassesment reason:
					</div>
					<div>
						{{ reassessmentReason }}
					</div>
				</template>
			</VTooltip>
			<div class="ml-10 font-weight-bold">
				You are currently reassessing Claim <strong>{{ claimId | claimIdFormat }}</strong>
			</div>
			<div
				@click="allowRestoring && $emit('revert-claim')"
				class="btn btn-warning ml-10"
				:class="[allowRestoring ? 'cursor-pointer': 'disabled']"
				v-tooltip="allowRestoring ? '' : failedRestoreReason"
			>
				Revert Claim
			</div>
		</div>
	</div>
</template>

<script>
import { ReassessedClaimOpen } from '@assets/icons';

export default {
	name: 'ReassessmentBanner',
	components: { ReassessedClaimOpen },
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		reassessmentReason: {
			type: String,
			required: false,
			default: null,
		},
		allowRestoring: {
			type: Boolean,
			require: false,
			default: false,
		},
		failedRestoreReason: {
			type: String,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
  .banner-section {
    border: 2px solid $warning;
    padding: 6px 10px;
    border-radius: 4px;
    background: var(--revertSection);
    box-shadow: 0 3px 10px var(--listItemsPopupShadow);
  }
</style>

<style lang="scss">
  .reassesment-description-popup {
    .v-popper__inner {
      border: 2px solid var(--selectedBorder);
      padding: 5px 10px;
      background: var(--attributeBg);
      color: var(--bodyColour);
    }

    .v-popper__arrow-outer {
      border-color: var(--selectedBorder);
    }
  }
</style>
