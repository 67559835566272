<template>
	<div class="container-fluid">
		<aq-modal
			name="vets-data-modal"
			data-qa="claimReportsBdx_modal_vetAddresses"
		>
			<vets-data-modal
				:title="'Vets Addresses'"
				:data="selectedData"
				@close="onCloseModal"
			/>
		</aq-modal>
		<aq-list-navigation-control
			:list="items"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				ref="table"
				:data="items"
				fit
				cell-class-name="text-left"
				empty-text="There is no data to display"
				data-qa="claimReportsBdx_table_reports"
				:key="currentColumnPage"
			>
				<paged-columns
					:current-column-page="currentColumnPage"
					:columns-per-page="columnsPerPage"
				>
					<el-table-column
						type="index"
						:index="getIndex"
						width="65"
					/>
					<el-table-column label="Brand">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.brandName" />
						</template>
					</el-table-column>
					<el-table-column
						prop="customerId"
						label="Customer Ref"
						width="100"
					/>
					<el-table-column label="Policy Ref">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.policyReference" />
						</template>
					</el-table-column>
					<el-table-column
						label="Claim Ref"
						width="100"
					>
						<template #default="scope">
							<div>{{ scope.row.claimId | claimIdFormat }}</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Customer Full Name"
						width="136"
					>
						<template #default="scope">
							<router-link
								:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
								class="d-inline-block"
							>
								<aq-icon-avatar
									class="small mr-auto cursor-pointer"
									:value="[scope.row.customerFirstName, scope.row.customerLastName]"
								/>
							</router-link>
						</template>
					</el-table-column>
					<el-table-column label="Customer Address">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.customerAddress" />
						</template>
					</el-table-column>
					<el-table-column
						prop="customerPostalCode"
						label="Customer Postal Code"
						width="145"
					/>
					<el-table-column
						prop="policyStatusDisplay"
						label="Policy Status"
						min-width="80"
					/>
					<el-table-column
						label="Product Name"
						min-width="80"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.productName" />
						</template>
					</el-table-column>
					<el-table-column
						prop="vetBusinessNames"
						label="Vet Business Name"
						min-width="120"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.vetBusinessNames" />
						</template>
					</el-table-column>

					<el-table-column
						label="Vet Address"
						width="150"
					>
						<template #default="scope">
							<div
								v-if="scope.row.vetAddresses.length > 0"
								class="link text-danger-20"
								@click="onSelectVetAddresses(scope.row.vetAddresses)"
							>
								<strong>{{ scope.row.vetAddresses.length }}</strong> {{ 'Address' | pluralize(scope.row.vetAddresses.length ) }}
							</div>
							<div v-else>
								No Vets
							</div>
						</template>
					</el-table-column>
					<el-table-column label="Vet Postal Code">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.vetPostalCodes" />
						</template>
					</el-table-column>
					<el-table-column
						prop="petType"
						label="Species"
						width="100"
					/>
					<el-table-column
						prop="petGender"
						label="Pet Gender"
						width="100"
					/>
					<el-table-column label="Breed">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.petBreed" />
						</template>
					</el-table-column>
					<el-table-column
						prop="petDateOfBirth"
						label="Pet Date Of Birth"
						width="120"
					/>
					<el-table-column
						prop="petAgeInception"
						label="Pet Age - Inception"
						width="130"
					/>
					<el-table-column
						prop="petAgeTreatmentStart"
						label="Pet Age - Treatment Start"
						width="160"
					/>
					<el-table-column
						prop="policyInceptionDate"
						label="Inception Date"
						width="120"
					/>
					<el-table-column
						prop="datePolicyStart"
						label="Date Policy Start"
						width="130"
					/>
					<el-table-column label="Ailment">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.ailments" />
						</template>
					</el-table-column>
					<el-table-column
						label="Body Part"
						width="100"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.bodyParts" />
						</template>
					</el-table-column>
					<el-table-column label="Condition">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.conditionTypes" />
						</template>
					</el-table-column>
					<el-table-column
						prop="dateOnSet"
						label="Date Onset"
						width="120"
					/>
					<el-table-column
						prop="dateTreatmentStart"
						label="Date Treatment Start"
						width="180"
					/>
					<el-table-column
						prop="dateTreatmentEnd"
						label="Date Treatment End"
						width="180"
					/>
					<el-table-column label="Section/Sub Section">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.sections" />
						</template>
					</el-table-column>
					<el-table-column
						prop="datePaid"
						label="Date Paid"
						width="120"
					/>
					<el-table-column
						label="Fast Track Reviewed"
						width="170"
					>
						<template #default="scope">
							<boolean-cell :value="scope.row.isFastTrackReviewed" />
						</template>
					</el-table-column>
					<el-table-column
						label="Fast Tracked Reason"
						width="170"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.fastTrackReason" />
						</template>
					</el-table-column>
					<el-table-column
						prop="claimSource"
						label="Claim Source"
						width="115"
					/>
					<el-table-column
						label="Claim Created By"
						width="140"
					>
						<template #default="scope">
							<aq-icon-avatar
								class="small mr-auto"
								:value="[scope.row.claimCreatedBy.firstName, scope.row.claimCreatedBy.lastName]"
							/>
						</template>
					</el-table-column>
					<el-table-column
						prop="claimCreatedDate"
						label="Claim Created Date"
						width="150"
					/>

					<el-table-column
						label="Assessed By"
						width="120"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.claimAssessedBy"
								class="small mr-auto"
								:value="[scope.row.claimAssessedBy.firstName, scope.row.claimAssessedBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>

					<el-table-column
						label="Approved By"
						width="120"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.claimApprovedBy"
								class="small mr-auto"
								:value="[scope.row.claimApprovedBy.firstName, scope.row.claimApprovedBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>
					<el-table-column label="Eligible">
						<template #default="scope">
							<truncated-text-cell
								v-if="scope.row.eligibleConditions"
								:value="scope.row.eligibleConditions"
							/>
							<div v-else>
								Not recorded
							</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="amountPaid"
						label="Amount Paid"
						width="100"
					/>
					<el-table-column
						prop="amountExGratia"
						label="Amount Ex-Gratia"
						width="120"
					/>
					<el-table-column
						prop="amountSubmitted"
						label="Amount Submitted"
						width="120"
					/>
					<el-table-column label="User Deductions">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.userDeductions" />
						</template>
					</el-table-column>
					<el-table-column
						prop="amountUserDeducted"
						label="Amount User Deducted"
						width="170"
					/>
					<el-table-column
						prop="amountNonInsurable"
						label="Amount Non Insurable"
						width="160"
					/>
					<el-table-column
						prop="amountExcess"
						:label="$options.UiLocaleSettings.amountExcessLabel"
						width="145"
					/>
					<el-table-column
						prop="amountCoInsurance"
						label="Amount Co Insurance"
						width="140"
					/>
					<el-table-column
						label="Rejection Overridden By"
						width="180"
					>
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.rejectionOverriddenBy"
								class="small mr-auto"
								:value="[scope.row.rejectionOverriddenBy.firstName, scope.row.rejectionOverriddenBy.lastName]"
							/>
							<div v-else>
								No User
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="claimStatus"
						label="Claim Status"
						width="200"
					/>
					<el-table-column label="Rejection Reason">
						<template #default="scope">
							<truncated-text-cell :value="scope.row.rejectionReason" />
						</template>
					</el-table-column>
					<el-table-column
						prop="claimFormType"
						label="Form Type"
						width="140"
					/>
					<el-table-column
						prop="daysOpenClosed"
						label="Days Open/Closed"
						width="140"
					/>
					<el-table-column
						prop="closedDate"
						label="Closed Date"
						width="180"
					/>
					<el-table-column
						prop="customerExtRef"
						label="Ext Customer Ref"
					/>
					<el-table-column
						prop="customerExtId"
						label="Ext Customer Id"
					/>
					<el-table-column
						prop="petExtRef"
						label="Ext Pet Ref"
					/>
					<el-table-column
						prop="petExtId"
						label="Ext Pet Id"
					/>
					<el-table-column
						prop="reassessmentDate"
						label="Date of Reassessment"
						min-width="80"
					/>
					<el-table-column label="Reassessment Created By">
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.reassessmentCreatedBy"
								class="small mr-auto"
								:value="[scope.row.reassessmentCreatedBy.firstName, scope.row.reassessmentCreatedBy.lastName]"
							/>
						</template>
					</el-table-column>
					<el-table-column
						prop="reassessmentReason"
						label="Reassessment Reason"
						min-width="80"
					>
						<template #default="scope">
							<truncated-text-cell :value="scope.row.reassessmentReason" />
						</template>
					</el-table-column>
					<el-table-column
						prop="reassessmentOutcome"
						label="Reassessment Outcome"
						min-width="80"
					>
						<template #default="scope">
							<truncated-text-cell
								v-if="scope.row.reassessmentOutcome"
								:value="scope.row.reassessmentOutcome.description"
							/>
						</template>
					</el-table-column>
					<el-table-column
						prop="reassessmentClosedDate"
						label="Date Reassessment Сlosed"
						min-width="80"
					/>
					<el-table-column label="Reassessment Closed By">
						<template #default="scope">
							<aq-icon-avatar
								v-if="scope.row.reassessmentClosedBy"
								class="small mr-auto"
								:value="[scope.row.reassessmentClosedBy.firstName, scope.row.reassessmentClosedBy.lastName]"
							/>
						</template>
					</el-table-column>
				</paged-columns>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';
import VetsDataModal from './VetsDataModal';
import { UiLocaleSettings } from '@commonServices/settings/localeSettings';

export default {
	components: {
		'vets-data-modal': VetsDataModal,
	},
	mixins: [rowHighlightMixin],
	props: {
		items: {
			type: Array,
			required: true,
		},
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
	},
	data () {
		return {
			selectedData: null,
		};
	},
	UiLocaleSettings,
	methods: {
		onSelectVetAddresses (vetAddresses) {
			this.selectedData = vetAddresses;
			this.$modal.show('vets-data-modal');
		},
		onCloseModal () {
			this.selectedData = null;
		},
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
	},
};
</script>
