<template>
	<div class="section-content flex-grow-1 p-0 mt-45">
		<aq-list-items-popover
			label="Select Condition"
			:items="graphConditions"
			:selected-item="selectedCondition"
			item-label="description"
			@input="onConditionChanged"
			class="conditions-selector"
		>
			<img
				alt="Conditions Selector Image"
				:src="require(`@/assets/icons/${themeIconSettings.ConditionsSelector}.svg`)"
			>
		</aq-list-items-popover>
		<div class="section-header mb-5 section-header-condition">
			<div class="section-header__title">
				Condition:
			</div>
			<div class="pl-5">
				<truncated-text-cell :value="conditionDescription" />
			</div>
		</div>
	</div>
</template>

<script>
import AqListItemsPopover from '@commonWidgets/AqListItemsPopover';
import { toClaimConditionDescription } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

export default {
	components: { AqListItemsPopover },
	props: {
		conditions: {
			type: Array,
			required: true,
		},
		claimConditionId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapState(['themeIconSettings']),
		graphConditions () {
			return this.conditions.map(condition => ({
				id: condition.id,
				description: toClaimConditionDescription(condition),
			}));
		},
		selectedCondition () {
			return this.graphConditions.find(condition => condition.id === this.claimConditionId);
		},
		conditionDescription () {
			return this.selectedCondition?.description;
		},
	},
	methods: {
		onConditionChanged (condition) {
			this.$emit('condition-changed', condition.id);
		},
	},
};

</script>

<style lang="scss" scoped>
  .conditions-selector {
    position: absolute;
    width: fit-content;
    left: 0;
    right: 0;
    top: -70px;
    margin: 0 auto;
  }

  .section-header-condition {
    min-height: 0;
  }
</style>
