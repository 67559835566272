<template>
	<div>
		<div v-if="validator.$error">
			<div
				class="text-danger fs-12"
				v-for="error of validator.$errors"
				:key="error.$uid"
			>
				{{ error.$message }}
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		validator: {
			type: Object,
			required: true,
		},
	},
};
</script>
