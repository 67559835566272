<template>
	<aq-panel-wrapper>
		<aq-alert-modal
			ref="duplicateItemsAlert"
			name="duplicate-items-alert"
			title="Invalid data"
			:description="duplicationErrorMessage"
		/>
		<div class="panel-container scrollbar">
			<div
				class="panel d-flex flex-column"
				v-if="editingCondition"
			>
				<ConditionForm
					:condition="selectedCondition"
					:diagnosis-condition-type="activeTab.value"
					@cancel="onCancel"
					@save="onSaveCondition"
				/>
			</div>

			<div
				class="panel d-flex flex-column"
				v-else
			>
				<div>
					<div class="section-header">
						<div class="section-header__title">
							Conditions Management
						</div>
					</div>
				</div>
				<aq-tab-selector
					:items="filteredTabItems"
					:active-tab="activeTab"
					@select-tab="onSelectTab"
					v-if="activeTab"
					class="flex-grow-1 d-flex flex-column"
				>
					<EligibleConditions
						v-if="isEligibleConditionActiveTab"
						:conditions="eligibleConditions"
						@edit-condition="onEditCondition"
						@cancel="onCancel"
						@duplication-error="onDuplicationError"
					/>
					<PreExistingConditions
						v-if="isPreExistingConditionActiveTab"
						:conditions="preExistingConditions"
						@edit-condition="onEditCondition"
						@cancel="onCancel"
						@duplication-error="onDuplicationError"
					/>
				</aq-tab-selector>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
import PetService from '@commonServices/petService';
import { mapState, mapGetters } from 'vuex';
import { ConditionManagementEnum, ConditionManagementEnumMap } from '@commonServices/models/ConditionManagementEnum';
import { toPreExistingConditionView, toEligibleConditionView } from '@commonServices/utils/converter';
import PreExistingConditions from '@commonView/Shared/ConditionsPanel/PreExistingConditions';
import EligibleConditions from '@commonView/Shared/ConditionsPanel/EligibleConditions';
import ConditionForm from '@commonView/Shared/ConditionsPanel/ConditionForm';

export default {
	components: {
		ConditionForm,
		PreExistingConditions,
		EligibleConditions,
	},
	data () {
		return {
			activeTab: undefined,
			preExistingConditions: [],
			eligibleConditions: [],
			editingCondition: false,
			selectedCondition: null,
			showMoreList: [],
			tabItems: [],
			duplicationErrorMessage: '',
			preExistingConditionTab: ConditionManagementEnumMap[ConditionManagementEnum.PreExisting],
			eligibleConditionTab: ConditionManagementEnumMap[ConditionManagementEnum.Eligible],
		};
	},
	props: {
		initialTab: {
			type: String,
			default: ConditionManagementEnumMap[ConditionManagementEnum.Eligible],
		},
	},
	computed: {
		...mapState(['currentUser', 'interactionContext']),
		...mapGetters(['getDraftData']),
		ConditionManagementEnum: () => ConditionManagementEnum,
		isEligibleConditionActiveTab () {
			return this.activeTab.value === this.eligibleConditionTab;
		},
		isPreExistingConditionActiveTab () {
			return this.activeTab.value === this.preExistingConditionTab;
		},
		filteredTabItems () {
			return this.tabItems.filter(tabItem => tabItem.hasViewPermission);
		},
		draftKey () {
			return `petId_${this.interactionContext.pet.id}`;
		},
		draftName () {
			return this.isPreExistingConditionActiveTab ? 'preExistingConditions' : 'eligibleConditions';
		},
	},
	async mounted () {
		const [preExistingConditions, eligibleConditions] = await Promise.all([
			this.$can.ViewPreExistingCondition ? PetService.getPreExistingConditions(this.interactionContext.pet.id) : [],
			this.$can.ViewEligibleCondition ? PetService.getEligibleConditions(this.interactionContext.pet.id) : []]);
		this.preExistingConditions = preExistingConditions;
		this.eligibleConditions = eligibleConditions;

		this.tabItems = [
			{
				value: this.eligibleConditionTab,
				label: 'Eligible',
				active: true,
				hasViewPermission: this.$can.ViewEligibleCondition,
			},
			{
				value: this.preExistingConditionTab,
				label: 'Pre-Existing',
				active: false,
				hasViewPermission: this.$can.ViewPreExistingCondition,
			},
		];
		this.onSelectTab(this.initialTab);
	},
	methods: {
		onSelectTab (value) {
			this.hideAllShowMore();
			this.activeTab = this.tabItems.find(item => item.value === value);
		},
		onEditCondition (condition) {
			if (condition.id !== null) {
				this.selectedCondition = condition;
			} else {
				this.selectedCondition = this.getDraftData(this.draftName, this.draftKey) ?? condition;
			}
			this.editingCondition = true;
		},
		onCancel () {
			this.selectedCondition = null;
			this.editingCondition = false;
		},
		async onSaveCondition (condition) {
			if (this.isPreExistingConditionActiveTab) {
				await this.savePreExistingCondition(condition);
			} else {
				await this.saveEligibleCondition(condition);
			}
			this.editingCondition = false;
			this.selectedCondition = null;
		},
		async savePreExistingCondition ({ id, condition, bodyPart, exclusionType, firstSignsDate, comments, relatedConditions, vet, documentCommentary, diagnosisOccurrences, pageNumber }) {
			const conditionData = {
				ailmentId: condition.id,
				bodyPartId: bodyPart.id,
				exclusionType,
				firstSignsDate,
				comments,
				relatedConditions: relatedConditions.map(relatedCondition => ({
					ailmentId: relatedCondition.condition.id,
					bodyPartId: relatedCondition.bodyPart.id,
				})),
				vetId: vet?.id,
				documentCommentary,
				diagnosisOccurrences: diagnosisOccurrences?.map(diagnosisOccurrence => ({
					vetRef: diagnosisOccurrence.vet?.ref,
					date: diagnosisOccurrence.date,
					pageNumber: diagnosisOccurrence.pageNumber,
				})),
				pageNumber,
			};

			if (id) {
				const data = await PetService.updatePreExistingCondition(id, conditionData);
				const updatedCondition = toPreExistingConditionView(data.value);
				const index = this.preExistingConditions.findIndex((item) => item.id === updatedCondition.id);
				this.$set(this.preExistingConditions, index, updatedCondition);
			} else {
				const data = await PetService.createPreExistingCondition(this.interactionContext.pet.id, conditionData);
				if (data) {
					const condition = toPreExistingConditionView(data.value);
					this.preExistingConditions.push(condition);
				}
			}
		},
		async saveEligibleCondition ({ id, condition, bodyPart, eligibleConditionType, assessmentDate, comments, relatedConditions, vet, documentCommentary }) {
			const conditionData = {
				ailmentId: condition.id,
				bodyPartId: bodyPart.id,
				eligibleConditionType,
				assessmentDate,
				comments,
				relatedConditions: relatedConditions.map(relatedCondition => ({
					ailmentId: relatedCondition.condition.id,
					bodyPartId: relatedCondition.bodyPart.id,
				})),
				vetId: vet?.id,
				documentCommentary,
			};

			if (id) {
				const data = await PetService.updateEligibleCondition(id, conditionData);
				const updatedCondition = toEligibleConditionView(data.value);
				const index = this.eligibleConditions.findIndex((item) => item.id === updatedCondition.id);
				this.$set(this.eligibleConditions, index, updatedCondition);
			} else {
				const data = await PetService.createEligibleCondition(this.interactionContext.pet.id, conditionData);
				if (data) {
					const condition = toEligibleConditionView(data.value);
					this.eligibleConditions.push(condition);
				}
			}
		},
		hideAllShowMore () {
			this.showMoreList = [];
		},
		async onDuplicationError (errorMessage) {
			this.duplicationErrorMessage = errorMessage;
			await this.$refs.duplicateItemsAlert.show();
		},
	},
};
</script>

<style lang="scss" scoped>
  ::v-deep .card {
    & > div {
      padding: 10px 15px !important;
    }

    p {
      line-height: 1.2;
    }
  }

  .panel-container {
    max-width: 500px;
    animation: slide 0.5s forwards;
  }

  hr {
    border: 1px solid var(--contentBg);
    opacity: 0.2;
  }

  .scrollbar {
    overflow-y: auto;
  }
</style>
