<template>
	<div>
		<div class="d-flex">
			<h3 class="fs-14 my-10">
				Payee Information:
			</h3>
			<aq-multi-checkbox
				:options="payeeOptions"
				:disabled="isReadOnlyMode"
				:value="selectedPayees"
				:multiple="!appSettings.disableSplitPayments"
				@input="onInputPayee"
			>
				<template #content="{ value, isSelected, select } ">
					<component
						:is="getPayeeIcon(value)"
						class="payee-icon mx-4"
						:class="[{ 'selected' : isSelected(value) },
							{ 'cursor-pointer': !isReadOnlyMode }]"
						@click="select(value)"
					/>
					<div
						class="mx-4"
						:class="[{ 'font-weight-bold' : isSelected(value) },
							{ 'cursor-pointer': !isReadOnlyMode }]"
						@click="select(value)"
					>
						{{ value.label }}
					</div>
				</template>
			</aq-multi-checkbox>
		</div>
		<aq-form-input-error
			class="error"
			:validator="validator"
		/>
	</div>
</template>

<script>

import { mapState } from 'vuex';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { PayeeType } from '@commonServices/utils/payeeOptions';
import { PolicyHolder, Vet } from '@assets/icons';

export default {
	name: 'PayeeCapture',
	props: {
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		validator: {
			type: Object,
			required: true,
		},
		uiLocaleSettings: {
			type: Object,
			required: true,
		},
		selectedPayees: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	computed: {
		...mapState(['appSettings']),
		payeeOptions () {
			return [{
				id: PayeeType.PolicyHolder,
				label: toRecipientLabel(PayeeType.PolicyHolder, this.uiLocaleSettings, this.appSettings),
			},
			{
				id: PayeeType.Vet,
				label: toRecipientLabel(PayeeType.Vet, this.uiLocaleSettings, this.appSettings),
			}];
		},
	},
	methods: {
		onInputPayee (value) {
			this.$emit('input', value);
		},
		getPayeeIcon (value) {
			return value.id === PayeeType.PolicyHolder ? PolicyHolder : Vet;
		},
	},
};
</script>

<style lang="scss" scoped>
  .payee-icon {
    fill: var(--bodyColour);

    &.selected {
      fill: $primary;
    }
  }
</style>
