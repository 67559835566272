<template>
	<div>
		<aq-care-flag
			v-if="careFlags.length > 0"
			:care-flags="careFlags"
		/>
		<item-heading
			class="gap-m"
			:heading="userHeading"
			heading-class="mb-1 user-name text-truncate"
			:paragraph="customerAddress"
			paragraph-class="text-truncate"
		>
			<component
				:is="disableCustomerLink ? 'span' : 'router-link'"
				:to="{ name: 'customer', params: { customerId: user.id } }"
				exact-active-class="current-route"
			>
				<aq-icon-avatar
					:value="[user.firstName, user.lastName]"
					class="customer-icon cursor-inherit"
				>
					<div class="aqv-customer" />
				</aq-icon-avatar>
				<i
					v-if="requireSpecialLicensing(currentContact.address.regionCounty)"
					class="fas fa-exclamation-triangle text-warning"
					v-tooltip="{
						content: 'Licensed permissions state',
						'popper-class': 'tooltip-orange'
					}"
				/>
			</component>
			<template #rightArea>
				<div class="right-area">
					<aq-brand-image-avatar
						:brand-name="user.brandName"
						:brand-id="user.brandId"
					/>
					<aq-list-items-popover
						v-if="contacts.length > 1"
						label="Switch contact"
						:items="contacts"
						:item-label="(item) => `${item.firstName} ${item.lastName}`"
						:selected-item="currentContact"
						@input="onContactSelected"
					>
						<ContactSwitcher class="contact-switcher" />
					</aq-list-items-popover>
				</div>
			</template>
			<template #besideHeadingArea>
				<div class="d-flex">
					<div
						v-if="currentContact.id !== user.id"
						class="named-contact-label"
					>
						(Named contact)
					</div>
				</div>
			</template>
		</item-heading>
		<div
			v-if="isExpandCardMode"
			class="attributes"
		>
			<toggle-card-info
				:attributes="attributes"
				:rows="2"
				:columns="3"
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ToggleCardInfo from '@commonView/CustomerPage/ToggleCardInfo';
import AqListItemsPopover from '@commonWidgets/AqListItemsPopover';
import eventBus from '@commonServices/eventBus';
import { toFullAddress, toShortAddress } from '@commonServices/utils/converter';
import customerService from '@commonServices/customerService';
import { getCareFlags } from '@commonServices/models/CareFlagEnum';
import { CommunicationPreferenceEnumMap } from '@commonServices/models/CommunicationPreferenceEnum';
import { ContactSwitcher } from '@assets/icons';

export default {
	components: {
		AqListItemsPopover,
		ToggleCardInfo,
		ContactSwitcher,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
		disableCustomerLink: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			contacts: [],
			selectedContactId: 0,
		};
	},
	mounted () {
		this.contacts = [this.user, ...this.user.secondaryContacts];
		eventBus.$on('customer-careFlags-updated', this.onCustomerCareFlagsUpdated);
	},
	beforeDestroy () {
		eventBus.$off('customer-careFlags-updated', this.onCustomerCareFlagsUpdated);
	},
	computed: {
		...mapState(['appSettings']),
		...mapGetters(['requireSpecialLicensing', 'isExpandCardMode']),
		customerAddress: function () {
			return this.isExpandCardMode ? toFullAddress(this.currentContact.address) : toShortAddress(this.currentContact.address);
		},
		attributes: function () {
			return [
				{ header: 'Email', text: this.currentContact.email || 'Not Supplied' },
				{ header: 'Phone Number', text: this.currentContact.phoneNumber || 'Not Supplied' },
				{ header: 'Cell Number', text: this.currentContact.mobileNumber || 'Not Supplied' },
				{ header: 'Code', text: this.currentContact.customerCode },
				{ header: 'Language Preference', text: this.currentContact.language },
				{ header: 'Comms Preference', text: CommunicationPreferenceEnumMap[this.currentContact.communicationPreference] },
				{ header: 'SMS Notification', text: this.currentContact.smsNotification ? 'SMS Opt In' : 'SMS Opt Out' },
			];
		},
		userHeading: function () {
			return customerService.toCustomerFullName(this.currentContact);
		},
		careFlags () {
			return getCareFlags(this.user.careFlag);
		},
		currentContact () {
			return this.contacts.find(contact => contact.id === this.selectedContactId) ?? this.user;
		},
	},
	methods: {
		onContactSelected (value) {
			this.selectedContactId = value.id;
		},
		onCustomerCareFlagsUpdated (careFlags) {
			this.user.careFlag = careFlags;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .user-name {
  font-size: 24px;
  letter-spacing: -0.04em;
  font-weight: bold;
}

.contact-small {
  font-size: 15px;
  width: 28px;
  height: 28px;
  margin-top: 10px;
  z-index: 1;
}

.contact-medium {
  font-size: 30px;
  width: 50px;
  height: 50px;
  margin: -14px -5px 0;

  &:first-child {
    margin: -14px 0 0;
  }
}

.customer-icon {
  min-width: 67px;
  font-size: 45px;
}

.named-contact-label {
  margin: 0 3px;
  padding-top: 5px;
  font-weight: bold;
  align-self: center;
}

.current-route {
  cursor: default;
}

.fa-exclamation-triangle {
  position: absolute;
  margin-left: 50px;
  top: 20px;
  font-size: 22px;
}

.right-area {
  display: flex;
  flex-grow: 2;
  gap: 14px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 4rem;

  .v-popover {
    display: flex;
  }

  ::v-deep .image-avatar, ::v-deep .icon-avatar, ::v-deep .logo-image {
    height: 35px;
    min-width: 35px;
    place-items: center;

    &:hover {
      transform: scale(1.1);
    }
  }

  .contact-switcher {
    cursor: pointer;
    height: 42px;
    width: 42px;

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
