<template>
	<aq-card
		:name="team.name"
		:description="team.description"
		data-qa="team_card_team"
		:action="actions"
		@select-action="$emit('team-action', $event)"
	>
		<template #card-icon>
			<div
				class="align-self-center position-relative"
			>
				<aq-icon-avatar
					class="medium mr-25"
					:value="teamInitials"
					data-qa="team_icon_team_name_initials"
				/>
			</div>
		</template>
		<template #content>
			<div
				class="bottom dark py-10 px-20 d-flex justify-content-between"
			>
				<div class="fs-13 d-flex align-items-center">
					<span
						v-tooltip="lifetimeInfoToolTip(team)"
						data-qa="team_span_life_time_info"
					>
						{{ lifetimeInfo(team) }}
					</span>
				</div>
				<aq-icon-avatar
					class="small"
					:value="[team.createdBy.firstName, team.createdBy.lastName]"
					data-qa="team_icon_created_by_initials"
				/>
			</div>
		</template>
		<template #list>
			<div class="pt-0 px-10 pb-10 team-users-wrapper">
				<div
					class="bottom p-10 d-flex flex-wrap"
				>
					<div
						v-for="member of team.users"
						:key="member.id"
						class="mt-3 mx-3"
					>
						<aq-icon-avatar
							class="small light"
							data-qa="team_icon_member_initials"
							:value="[member.firstName, member.lastName]"
						/>
					</div>
				</div>
			</div>
		</template>
	</aq-card>
</template>

<script>
import { lifetimeInfo, lifetimeInfoToolTip } from '@commonServices/utils/dateUtils';
import { teamInitials } from '@commonServices/utils/general';

export default {
	name: 'AqTeam',
	props: {
		team: {
			type: Object,
			required: true,
		},
		actions: {
			type: Array,
			required: true,
		},
	},
	methods: {
		lifetimeInfo (team) {
			return lifetimeInfo({ ...team, createdDateTime: team.createdDate, updatedDateTime: team.updatedDate });
		},
		lifetimeInfoToolTip (team) {
			return lifetimeInfoToolTip({ ...team, createdDateTime: team.createdDate });
		},
	},
	computed: {
		teamInitials () {
			return teamInitials(this.team);
		},
	},
};
</script>
<style lang="scss" scoped>
  .team-users-wrapper {
    background: var(--attributeBg);
  }
</style>
