<template>
	<div class="d-flex align-items-center">
		<aq-table-popover
			v-if="policyTerm.policyOffer"
			placement="left"
			popover-class="introducer-details-popover"
		>
			<table
				class="introducer-popover-table"
				aria-label="Introducer Info"
			>
				<tr>
					<th>Offer Type</th>
					<td>{{ policyTerm.policyOfferType }}</td>
				</tr>
				<tr>
					<th>Associate Name</th>
					<td>{{ policyTerm.policyAssociateName }}</td>
				</tr>
				<tr>
					<th>Promo Code</th>
					<td>{{ policyTerm.policyPromoCode }}</td>
				</tr>
			</table>
			<template #title>
				<div class="d-flex justify-content-center align-items-center cursor-pointer introducer-header-text">
					<i class="aqv-line-discount" />
					<span class="text-truncate text-underline">
						{{ policyTerm.policyOffer }}
					</span>
				</div>
			</template>
		</aq-table-popover>
	</div>
</template>

<script>
import AqTablePopover from '@commonWidgets/AqTablePopover';

export default {
	name: 'IntroducerInfo',
	components: {
		AqTablePopover,
	},
	props: {
		policyTerm: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.aqv-line-discount {
  transform: rotate(90deg);
}

.introducer-header-text {
  max-width: 120px;
}
</style>

<style lang="scss">
.introducer-details-popover {
  .introducer-popover-table {
    width: auto;

    td,
    th {
      height: 38px;
    }

    th {
      width: 150px;
      text-align: left;
      padding-left: 10px;
    }

    td {
      padding: 10px;
      width: 350px;
      background: var(--bodyBg);
    }

    tr:not(:last-child) {
      border-bottom: 1px solid var(--userIcon);
    }
  }
}
</style>
